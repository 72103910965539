import { Modal, Input, Button, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { errorToast, successToast } from "../helpers";

import { CloseCircleOutlined, PayCircleOutlined } from "@ant-design/icons";
import { usePostRemoveTokenMutation } from "../services/users";

type ModalTypes = {
  id: string;
};

const RemoveTokensModal = ({ id }: ModalTypes) => {
  const [tokenAmount, setTokenAmount] = useState<number>();
  const [visible, setVisible] = useState(false);
  const [removeTokens, removeTokensData] = usePostRemoveTokenMutation();

  const removeTokensHandling = async () => {
    if (tokenAmount === 0 || tokenAmount === undefined) {
      errorToast("Please input token amount");
    } else {
      let body = {
        tokens: tokenAmount || 0,
        userId: id || "",
      };

      try {
        const res = await removeTokens({ body }).unwrap();
        if (res?.statusCode === 200) {
          setVisible(false);
          successToast("Tokens removes successfully");
          setTokenAmount(undefined);
        }
      } catch (error: any) {
        setTokenAmount(undefined);
        errorToast(error?.data?.message || "");
        console.log(error);
      }
    }
  };

  const handleTokenAmountChange = (e: any) => {
    const inputValue = e.target.value;

    // Check if the input is empty or non-numeric
    if (inputValue === "" || !/^\d*$/.test(inputValue)) {
      setTokenAmount(0); // You can set it to an empty string or handle it differently
    } else {
      const numericValue = parseInt(inputValue, 10); // Convert to a number
      setTokenAmount(numericValue); // Set it as a number
    }
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <div>
      <Tooltip title="Remove Tokens">
        <Button onClick={() => setVisible(true)}>
          <PayCircleOutlined style={{ color: "#d438" }} />
        </Button>
      </Tooltip>
      <Modal
        title="Remove Player Tokens"
        open={visible}
        onCancel={handleCancel}
        footer={[
          <Button
            loading={removeTokensData.isLoading}
            style={{
              background: "#ECAC35",
              height: 35,
              margin: "auto",
              color: "black",
            }}
            onClick={() => removeTokensHandling()}
          >
            Remove Tokens
          </Button>,
        ]}
      >
        <Input
          maxLength={5}
          value={tokenAmount}
          placeholder="Enter Tokens"
          style={{ height: 43, width: "100%" }}
          onChange={handleTokenAmountChange}
        />
      </Modal>
    </div>
  );
};

export default RemoveTokensModal;
