import React, { useState } from "react";
import { Button, Form, Upload, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";

type ImageType = {
  image: string | undefined;
};

const UploadImage = ({ image }: ImageType) => {
  const [profileImage, setProfileImage] = useState(image);

  const handleImageUpload = (file: any) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      setProfileImage(reader.result as any);
      //   localStorage.setItem("uploadedImage", reader.result as any);
    };
    reader.onerror = () => {
      message.error("Image upload failed");
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };
  //   useEffect(() => {
  //     const storedImageUrl = localStorage.getItem("uploadedImage");
  //     if (storedImageUrl) {
  //       setProfileImage(storedImageUrl);
  //     }
  //   }, []);

  return (
    <Form.Item name="image">
      <Upload
        beforeUpload={handleImageUpload}
        showUploadList={false}
        className="upload_imgD"
        accept="image/*"
      >
        <div>
          <h4>Upload Image</h4>
          <Button icon={<UploadOutlined />}>Click to upload</Button>
        </div>
        {profileImage ? (
          <div
            style={{
              marginTop: 10,
              //   background: "pink",
              height: 150,
              width: 150,
            }}
          >
            <img
              style={{
                borderRadius: "50%",
                height: "100%",
                width: "100%",
              }}
              src={profileImage}
              alt="profile"
            />
          </div>
        ) : (
          <></>
        )}
      </Upload>
    </Form.Item>
  );
};

export default UploadImage;
