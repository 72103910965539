import { END_POINTS } from "../helpers";
import emptySplitApi from "../utils/rtk";

type notificationResponse = {
  statusCode: number;
  message?: string;
};

type notificationBody = {
  title: string;
  message: string;
};

type GetNotificationsResponse = {
  statusCode: number;
  code?: number;
  data?: any;
};

export const addNotificationApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    postAddNotification: builder.mutation<
      notificationResponse,
      notificationBody
    >({
      query: (body) => ({
        url: `${END_POINTS.addNotification}`,
        method: "POST",
        body,
      }),
    }),

    getNotifications: builder.query<GetNotificationsResponse, {}>({
      query: () => ({
        url: `${END_POINTS.getNotification}`,
        method: "GET",
      }),
    }),
  }),
});

export const { usePostAddNotificationMutation, useLazyGetNotificationsQuery } =
  addNotificationApi;
