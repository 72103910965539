import { Dropdown, MenuProps } from "antd";
import { useLanguageContext } from "../context/LanguageContext";
import { GlobalOutlined } from "@ant-design/icons";
import { STORAGE_KEYS, storeData } from "../helpers";

const ChangeLanguageDropdown = () => {
  const { language, changeLanguage } = useLanguageContext();

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: <span>English</span>,
      onClick: () => {
        changeLanguage("en");
        storeData(STORAGE_KEYS.language, "en");
      },
    },
    {
      key: "2",
      label: <span>Arabic</span>,
      onClick: () => {
        changeLanguage("ar");
        storeData(STORAGE_KEYS.language, "ar");
      },
    },
  ];

  return (
    <>
      <Dropdown menu={{ items }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <GlobalOutlined style={{ color: "#0F52BA", fontSize: 15 }} />
          <span style={{ marginLeft: 8 }}>Change Language</span>
        </div>
      </Dropdown>
    </>
  );
};

export default ChangeLanguageDropdown;
