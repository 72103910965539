import { Button, Card, Col, Input, Row, Spin } from "antd";
import { useEffect, useState } from "react";
import { ContentHeader } from "../../components";
import {
  useAddFaqsMutation,
  useDeleteFaqMutation,
  useLazyGetAllFaqsQuery,
} from "../../services/faqs";
import { successToast } from "../../helpers";

const ManageFaq = () => {
  const [faqs, setFaqs] = useState<Faqs[]>([]);
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");

  const [faqsData, getFaqData] = useLazyGetAllFaqsQuery();
  const [addFaq, addFaqData] = useAddFaqsMutation();
  const [deleteFaq, deleteFaqData] = useDeleteFaqMutation();

  const getAllfaqsHandler = async () => {
    try {
      const res = await faqsData({}).unwrap();

      if (res?.statusCode === 200) {
        setFaqs(res?.data?.data);
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  const handleAddFaq = async () => {
    let body = {
      question: question,
      answer: answer,
    };
    try {
      const res = await addFaq(body).unwrap();

      if (res?.statusCode === 200) {
        successToast("Added Successfully");
        getAllfaqsHandler();
        setQuestion("");
        setAnswer("");
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  const removefaqsHandler = async (id: string | undefined) => {
    try {
      const res = await deleteFaq({ faq_id: id }).unwrap();

      if (res?.statusCode === 200) {
        getAllfaqsHandler();
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllfaqsHandler();
  }, []);

  return (
    <div style={{ padding: 40 }}>
      <ContentHeader heading="Manage FAQ's" />

      <Card>
        <Spin spinning={getFaqData.isLoading || deleteFaqData.isLoading}>
          <Row gutter={4}>
            <Col xs={16} md={16}>
              <h3>FAQs</h3>

              {faqs?.map((item, index) => {
                return (
                  <div key={index}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-end",
                        gap: "20px",
                        width: "100%",
                      }}
                    >
                      <div style={{ width: "100%" }}>
                        <div>
                          <h4>Question:</h4>
                          <Input
                            disabled={!!item?.question}
                            type={"text"}
                            name="name"
                            value={item?.question}
                            placeholder="Question"
                            style={{
                              height: 55,
                              marginLeft: 20,
                              color: "black",
                            }}
                          ></Input>
                        </div>
                        <div style={{}}>
                          <h4>Answer:</h4>
                          <Input
                            disabled={!!item?.answer}
                            type={"text"}
                            name="name"
                            value={item?.answer}
                            placeholder="Answer"
                            style={{
                              height: 55,
                              marginLeft: 20,
                              color: "black",
                            }}
                          ></Input>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginTop: 20,
                      }}
                    >
                      <Button onClick={() => removefaqsHandler(item._id)}>
                        Remove
                      </Button>
                    </div>
                  </div>
                );
              })}

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-end",
                  gap: "20px",
                }}
              >
                <div style={{ width: "100%" }}>
                  <div>
                    <h4>Question:</h4>
                    <Input
                      type={"text"}
                      name="name"
                      value={question}
                      onChange={(val) => setQuestion(val.target.value)}
                      placeholder="Question"
                      style={{ height: 55, marginLeft: 20 }}
                    ></Input>
                  </div>
                  <div>
                    <h4>Answer:</h4>
                    <Input
                      type={"text"}
                      name="name"
                      value={answer}
                      onChange={(val) => setAnswer(val.target.value)}
                      placeholder="Answer"
                      style={{
                        height: 55,
                        marginLeft: 20,
                      }}
                    ></Input>
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: 20,
                }}
              >
                <Button
                  style={{
                    background: "#ECAC35",
                    width: 90,
                    height: 40,
                    color: "black",
                  }}
                  loading={addFaqData.isLoading}
                  onClick={handleAddFaq}
                  disabled={!question || !answer}
                >
                  Add
                </Button>
              </div>
            </Col>
          </Row>
        </Spin>
      </Card>
    </div>
  );
};

export default ManageFaq;
