import { Button, Card, Col, Form, Input, Row } from "antd";

import { SearchOutlined } from "@ant-design/icons";
import Table, { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { useLazyGetUpcomingTournamentsQuery } from "../../services/users";

const UpcomingTournamentsTable = () => {
  const [totalCount, setTotalCount] = useState<number | undefined>();
  const [query, setQuery] = useState("");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [upcomingTournamentsDetails, setUpcomingTournamentsDetails] =
    useState<any>();
  const [upcomingTournaments, upcomingTournamentsData] =
    useLazyGetUpcomingTournamentsQuery();

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    getTournamentHandler(page);
  };
  interface DataType {
    fixtures: any;
    key: string;
    index: number;
    startdate: string;
    name: string;
    endDate: string;
    matches: string;
  }

  const columns: ColumnsType<DataType> = [
    {
      title: "#",
      key: "rank",
      dataIndex: "rank",
      align: "center",
      width: "10%",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Image",
      dataIndex: "image",
      key: "image",

      align: "center",
      render: (_, { image }: any) => (
        <div>
          <img
            style={{ height: 70, width: 70, borderRadius: 100 }}
            src={image || "/static/images/dummyTP.png"}
            alt=""
          />
        </div>
      ),
    },

    {
      title: "Tournament Name",
      dataIndex: "name",
      key: "name",
      align: "center",
    },
    {
      title: "Matches",
      dataIndex: "matches",
      key: "matches",
      align: "center",
      render: (_, record) => (
        <div>
          <span>{record?.fixtures?.length || "-"}</span>
        </div>
      ),
    },

    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      align: "center",
      render: (_, record) => {
        const smallestDate: Date | null = record?.fixtures.reduce(
          (smallest: any, obj: any) => {
            const currentDate = new Date(obj.fixture.date);
            return !smallest || currentDate < smallest ? currentDate : smallest;
          },
          null
        );
        return smallestDate ? smallestDate.toISOString().substr(0, 10) : "-";
      },
    },
  ];

  const getTournamentHandler = async (page: number) => {
    try {
      const res = await upcomingTournaments({
        page: page,
        size: 10,
        query: query || "",
      }).unwrap();

      if (res?.statusCode === 200) {
        setUpcomingTournamentsDetails(res?.data?.data);
        setTotalCount(res?.data?.count);
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  const pagination = {
    pageSize: 10,
    current: currentPage,
    total: totalCount,
    onChange: handlePageChange,
  };

  useEffect(() => {
    if (query === "") {
      getTournamentHandler(1);
    }
  }, [query]);

  return (
    <div style={{}}>
      <Card>
        <div style={{ marginTop: 0 }}>
          <Row style={{ paddingTop: 10, paddingBottom: 10 }} gutter={16}>
            <Col xs={24} md={7}>
              <Form
                style={{ display: "flex", gap: 20 }}
                onFinish={() => getTournamentHandler(1)}
              >
                <Input
                  placeholder="Search Tournaments"
                  prefix={<SearchOutlined style={{ fontSize: "24px" }} />}
                  style={{ height: 45, borderRadius: 10 }}
                  onChange={(e) => setQuery(e.target.value)}
                />
                <Button
                  style={{
                    background: "#ECAC35",
                    width: 90,
                    height: 40,
                    margin: "auto",
                    color: "black",
                  }}
                  type="default"
                  htmlType="submit"
                >
                  Search
                </Button>
              </Form>
            </Col>
          </Row>

          <div style={{ marginTop: 10 }}>
            <Table
              columns={columns}
              dataSource={upcomingTournamentsDetails}
              rowKey="_id"
              loading={upcomingTournamentsData.isLoading}
              pagination={pagination}
            />
          </div>
        </div>
      </Card>
    </div>
  );
};

export default UpcomingTournamentsTable;
