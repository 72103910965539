import { Button, Card, Space, Checkbox } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import Table, { ColumnsType } from "antd/es/table";
import { useLazyGetLeagueDataQuery } from "../../../services/tournaments";
import { useEffect, useState } from "react";

const LeagueDetails = () => {
  const navigate = useNavigate();

  const [leagueDetails, leagueDetailsData] = useLazyGetLeagueDataQuery();
  const [leagueData, setLeagueData] = useState([]);
  const { id, year } = useParams();

  interface DataType {
    key: string;
    teamA: string;
    teamB: string;
    date: string;
    time: string;
  }

  const columns: ColumnsType<DataType> = [
    {
      title: "Team A",
      dataIndex: "teamA",
      key: "teamA",
      align: "center",
      // render: (text) => <a>{text}</a>,
    },
    {
      title: "Team B",
      dataIndex: "teamB",
      key: "teamB",
      align: "center",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      align: "center",
    },
    {
      title: "Time",
      dataIndex: "time",
      key: "time",
      align: "center",
    },

    {
      title: "Action",
      key: "action",
      align: "center",
      render: (_, record) => (
        <Space size="middle">
          <Checkbox />
        </Space>
      ),
    },
  ];

  const getLeaguesData = async () => {
    try {
      const res = await leagueDetails({
        league: id,
        season: 2023,
      }).unwrap();

      if (res?.statusCode === 200) {
        setLeagueData(res?.data);
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    getLeaguesData();
  }, []);

  return (
    <div style={{ padding: 50, gap: 20, display: "grid" }}>
      <Card>
        <div
          style={{
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <h2>Selection of the week</h2>
          <Button
            style={{
              background: "#ECAC35",
              width: 100,
              height: 40,
              color: "black",
            }}
            type="primary"
            // htmlType="submit"
            onClick={() => {
              navigate("/managetournaments", { replace: true });
            }}
          >
            Back
          </Button>
        </div>

        <div>
          <Table columns={columns} dataSource={leagueData} />
        </div>
      </Card>
    </div>
  );
};

export default LeagueDetails;
