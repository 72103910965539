import React, { useEffect, useState } from "react";
import { Card, Select } from "antd";
import { LineChart } from ".";

const RevanueChart = (props: any) => {
  const [roundOff, setRoundOff] = useState(100);
  const handleFilterChange = (value: string) => {
    props.onFilterChange(value);
    props.setName("left");
  };

  useEffect(() => {
    if (props?.details?.revenue) {
      const maxMonthData = props?.details?.revenue.reduce(
        (max: any, month: any) => (month.total > max.total ? month : max),
        props?.details?.revenue[0]
      );

      if (Math.round(maxMonthData.total / 100) * 100 > 100) {
        setRoundOff(Math.round(maxMonthData.total / 100) * 100);
      } else {
        setRoundOff(100);
      }
    }
  }, [props]);

  return (
    <div style={{ padding: 0 }}>
      <Card className="chart_card">
        <div
          style={{
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <h2>Revenue Generated</h2>
          <div style={{ width: "20%" }}>
            <Select
              style={{ width: "100%" }}
              onChange={handleFilterChange}
              defaultValue={"YEAR"}
              placeholder="Filter"
              options={[
                { value: "DAILY", label: "Daily" },
                { value: "WEEK", label: "Weekly" },
                { value: "MONTH", label: "Monthly" },
                { value: "YEAR", label: "Yearly" },
              ]}
            />
          </div>
        </div>
        <div style={{ margin: "auto" }} className="graph_ch">
          <LineChart data={props.graphData} roundOff={roundOff} />
        </div>
      </Card>
    </div>
  );
};

export default RevanueChart;
