import { Button, Card, Col, Form, Input, Modal, Space } from "antd";
import { useNavigate } from "react-router-dom";
import "react-phone-input-2/lib/style.css";
import { useEffect, useState } from "react";
import { errorToast, successToast } from "../../../helpers";

import {
  useAddReceiverMutation,
  useDeleteReceiverCategoryMutation,
  useLazyGetReceiverListQuery,
} from "../../../services/tournaments";
import { DeleteOutlined, EditFilled } from "@ant-design/icons";
import Table, { ColumnsType } from "antd/es/table";
import AddReceiverModal from "../../../components/AddReceiverModal";

const ManageReceivers = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [receiver, setReceiver] = useState<string>();

  const [addReceiver, addReceiverData] = useAddReceiverMutation();
  const [receiverList, receiverListData] = useLazyGetReceiverListQuery();
  const [receiverData, setReceiverData] = useState<any[]>();
  const [deleteCategory, deleteCategoryData] =
    useDeleteReceiverCategoryMutation();

  interface DataType {
    _id: string;
    key: string;
    name?: string;
  }
  const addReceiverDetails = async () => {
    if (!receiver?.length) {
      errorToast("Please input receiver name");
    } else {
      let body = {
        name: receiver,
      };
      try {
        const res = await addReceiver(body).unwrap();

        if (res?.statusCode === 200) {
          setReceiver("");
          getReceiverDetails();
          successToast("Receiver added successfully");
        }
      } catch (error: any) {
        console.log(error);
      }
    }
  };

  const handleDelete = async (id: string) => {
    try {
      const res = await deleteCategory({ Id: id }).unwrap();

      if (res?.statusCode === 200) {
        successToast("Receiver Deleted Successfully");
        getReceiverDetails();
      }
    } catch (error: any) {
      console.log(error);
      errorToast(error?.data?.message);
    }
  };
  const showDeleteConfirm = (record: any) => {
    confirm({
      title: "Hold on",
      content: "Are you sure delete this receiver?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        handleDelete(record?._id);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };
  const { confirm } = Modal;

  const columns: ColumnsType<DataType> = [
    // {
    //   title: "#",
    //   key: "rank",
    //   dataIndex: "rank",
    //   align: "center",
    //   width: "10%",
    //   // render: (text, record, index) => index + 1,
    //
    // },

    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      align: "center",
    },

    {
      title: "Action",
      key: "action",
      align: "center",
      render: (_, record) => (
        <Space size="middle">
          <AddReceiverModal apiHit={getReceiverDetails} id={record?._id} />
          <Button
            onClick={(e) => {
              e.preventDefault();
              showDeleteConfirm(record);
            }}
          >
            <DeleteOutlined style={{ color: "#d4380d" }} />
          </Button>
        </Space>
      ),
    },
  ];

  const getReceiverDetails = async () => {
    try {
      const res = await receiverList({}).unwrap();

      if (res?.statusCode === 200) {
        setReceiverData(res?.data);
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    getReceiverDetails();
  }, []);

  return (
    <div style={{ padding: 50 }}>
      <Card>
        <div
          style={{
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <h2>Add Receiver</h2>
          <Button
            style={{
              background: "#ECAC35",

              height: 40,
              color: "black",
            }}
            loading={addReceiverData.isLoading}
            type="primary"
            onClick={() => {
              navigate("/addtokens", { replace: true });
            }}
          >
            Back
          </Button>
        </div>
        <div>
          <Form
            name="basic"
            layout="vertical"
            form={form}
            style={{
              maxWidth: "80%",
              marginTop: 10,
            }}
            initialValues={{ remember: true }}
            // onFinish={onFinish}
            autoComplete="off"
          >
            <Col xs={24} md={12}>
              <Form.Item
                name="receiverName"
                rules={[
                  {
                    required: true,
                    message: "Please input receiver name",
                  },
                ]}
              >
                <div>
                  <h4>Add Receiver Name</h4>
                  <Input
                    value={receiver}
                    placeholder="Add New Receiver"
                    style={{ height: 43, width: "100%" }}
                    onChange={(e) => {
                      setReceiver(e.target.value);
                    }}
                  />
                </div>
              </Form.Item>
            </Col>
          </Form>
        </div>
        <Button
          loading={addReceiverData.isLoading}
          style={{
            background: "#ECAC35",
            height: 40,
            margin: "auto",
            color: "black",
          }}
          onClick={() => addReceiverDetails()}
        >
          Add
        </Button>
      </Card>

      <Card style={{ top: 30 }}>
        <Table
          columns={columns}
          dataSource={receiverData}
          rowKey="_id"
          loading={receiverListData?.isLoading}
        />
      </Card>
    </div>
  );
};

export default ManageReceivers;
