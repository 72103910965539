import { END_POINTS } from "../helpers";
import emptySplitApi from "../utils/rtk";

type AddTournamentsResponse = {
  statusCode: number;
  message?: string | undefined;
};

type AddTournamentsBody = {
  name: string;
  description: string;
  rules: string;
  fixtures: any;
  // fixture_id: string;
  // fixture: {
  //   id: string;
  //   referee: string;
  //   timezone: string;
  //   date: string;
  //   timestamp: string;
  //   periods: {
  //     first: string;
  //     second: string;
  //   };
  //   venue: {
  //     id: string;
  //     name: string;
  //     city: string;
  //   };
  //   status: {
  //     long: string;
  //     short: string;
  //     elapsed: string;
  //   };
  // };
  // league: {
  //   id: string;
  //   name: string;
  //   country: string;
  //   logo: string;
  //   flag: string;
  //   season: string;
  //   round: string;
  // };
  // teams: {
  //   home: {
  //     id: string;
  //     name: string;
  //     logo: string;
  //     winner: boolean;
  //   };
  //   away: {
  //     id: string;
  //     name: string;
  //     logo: string;
  //     winner: boolean;
  //   };
  // };
  // goals: {
  //   home: string;
  //   away: string;
  // };
  // score: {
  //   halftime: {
  //     home: string;
  //     away: string;
  //   };
  //   fulltime: {
  //     home: string;
  //     away: string;
  //   };
  //   extratime: {
  //     home: string;
  //     away: string;
  //   };
  //   penalty: {
  //     home: string;
  //     away: string;
  //   };
  // };
};

type GetLeaguesResponse = {
  statusCode: number;
  code?: number;
  data?: any;
};

type LeagueByIdResponse = {
  statusCode?: number;
  data?: any;
};

type GetLeagesByIdBody = {
  league: any;
};
type GetLeagesById = {
  league: any;
  season: any;
};
type GetAllTournamentsResponse = {
  data?: any;
  statusCode: number;
};

type PutBlockCategoryResponse = {
  statusCode: number;
  code?: number;
  data?: any;
};

type PutBlockCategoryParams = {
  tournamentId: string;
  body: { isActive: boolean };
};

type GetTournamentDetailsByIdResponse = {
  statusCode: number;
  data?: any;
};

type EditTournamentParams = {
  tournamentId: string;
  body: AddTournamentsBody;
};
type EditReceiverParams = {
  Id: string;
  body: {
    name: string;
  };
};

type EditTournamentResponse = {
  statusCode: number;
};

type GetTeamsParams = {
  size: number;
  page: number;
  query?: string | undefined;
};

type GetAllTeamsResponse = {
  statusCode: number;
  data?: any;
};

type AddTeamsResponse = {
  statusCode: number;
  data?: any;
};

type AddTeamsBody = {
  logo: string;
  name: string;
};
type AddReceiverBody = {
  name?: string;
};

type TeamCategoryParams = {
  body: {
    logo: string;
    name: string;
  };
  team_id: string | undefined;
};

type TeamCategoryResponse = {
  statusCode: number;
};

type GetTeamDetailsByIdResponse = {
  statusCode: number;
  data?: any;
};

type PutActiveParams = {
  teamId: string;
  body: { isActive: boolean };
};
type GetUsersResponse = {
  statusCode: number;
  data: any;
};

type SettleMentBody = {
  tokens: string;
  totalAmount: string;
  status: string;
  paymentMethod: string;
  transactionType: string;
  receivedBy: string;
  paidAmount: number;
  settlementAmount: number;
};

export const TournamentsApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    postAddTournaments: builder.mutation<
      AddTournamentsResponse,
      AddTournamentsBody
    >({
      query: (body) => ({
        url: `${END_POINTS.tournaments}`,
        method: "POST",
        body,
      }),
    }),

    getLeagues: builder.query<GetLeaguesResponse, {}>({
      query: () => ({
        url: `${END_POINTS.getLeague}`,
        method: "GET",
      }),
    }),
    getLeagesById: builder.query<LeagueByIdResponse, string | undefined>({
      query: (league) => ({
        url: END_POINTS.getLeagesById + "?league=" + league,
        method: "GET",
      }),
    }),

    getLeagueTournament: builder.query<
      GetLeaguesResponse,
      { size: number; page: number; query: string }
    >({
      query: ({ size, page, query }) => ({
        url:
          END_POINTS.getLeagueTournament +
          "?size=" +
          size +
          "&page=" +
          page +
          "&search=" +
          query,
        method: "GET",
      }),
    }),

    getAllTournaments: builder.query<
      GetAllTournamentsResponse,
      { size: number; page: number; query: string }
    >({
      query: ({ size, page, query }) => ({
        url:
          END_POINTS.tournaments +
          "?size=" +
          size +
          "&page=" +
          page +
          "&search=" +
          query,
        method: "GET",
      }),
    }),
    deleteCategory: builder.mutation<
      { statusCode: number },
      { categoryId: string }
    >({
      query: ({ categoryId }) => ({
        url: `${END_POINTS.tournaments}/${categoryId}`,
        method: "DELETE",
      }),
    }),
    putBlockCategory: builder.mutation<
      PutBlockCategoryResponse,
      PutBlockCategoryParams
    >({
      query: ({ body, tournamentId }) => ({
        url: `${END_POINTS.tournaments}/${tournamentId}`,
        method: "PUT",
        body,
      }),
    }),

    getTournamentDetailsById: builder.query<
      GetTournamentDetailsByIdResponse,
      {
        tournamentId: string;
        page: number;
        size: number;
        query: string;
        categoryId: string;
      }
    >({
      query: (body) => ({
        url:
          `${END_POINTS.tournaments}/${body?.tournamentId}` +
          "?rankingLimit=" +
          body?.size +
          "&rankingPage=" +
          body?.page +
          "&categoryId=" +
          body?.categoryId +
          "&search=" +
          body.query,
        method: "GET",
      }),
    }),
    getRankingDetailsById: builder.query<
      GetTournamentDetailsByIdResponse,
      {
        tournamentId: string;
        page: number;
        size: number;
        query: string;
        categoryId: string;
      }
    >({
      query: (body) => ({
        url:
          `${END_POINTS.tournamentRanking}/${body?.tournamentId}` +
          "?size=" +
          body?.size +
          "&page=" +
          body?.page +
          "&categoryId=" +
          body?.categoryId +
          "&search=" +
          body.query,
        method: "GET",
      }),
    }),
    editTournamentsById: builder.mutation<
      EditTournamentResponse,
      EditTournamentParams
    >({
      query: ({ body, tournamentId }) => ({
        url: `${END_POINTS.tournaments}/${tournamentId}`,
        method: "PUT",
        body,
      }),
    }),
    getLeagueData: builder.query<LeagueByIdResponse, GetLeagesById>({
      query: ({ league, season }) => ({
        url:
          END_POINTS.getLeaguesData + "?league=" + league + "&season=" + season,
        method: "GET",
      }),
    }),
    getTeams: builder.query<GetAllTeamsResponse, GetTeamsParams>({
      query: ({ size, page, query }) => ({
        url:
          END_POINTS.teams +
          "?size=" +
          size +
          "&page=" +
          page +
          "&search=" +
          query,
        method: "GET",
      }),
    }),
    postAddTeams: builder.mutation<AddTeamsResponse, AddTeamsBody>({
      query: (body) => ({
        url: `${END_POINTS.teams}`,
        method: "POST",
        body,
      }),
    }),
    addReceiver: builder.mutation<AddTeamsResponse, AddReceiverBody>({
      query: (body) => ({
        url: `${END_POINTS.receiverList}`,
        method: "POST",
        body,
      }),
    }),
    settlement: builder.mutation<AddTeamsResponse, SettleMentBody>({
      query: (body) => ({
        url: `${END_POINTS.settlement}`,
        method: "POST",
        body,
      }),
    }),
    deleteTeamCategory: builder.mutation<
      { statusCode: number },
      { teamId: string }
    >({
      query: ({ teamId }) => ({
        url: `${END_POINTS.teams}/${teamId}`,
        method: "DELETE",
      }),
    }),
    putEditTeamCategory: builder.mutation<
      TeamCategoryResponse,
      TeamCategoryParams
    >({
      query: ({ body, team_id }: any) => ({
        url: `${END_POINTS.teams}/${team_id}/`,
        method: "PUT",
        body,
      }),
    }),
    getTeamsDetailsById: builder.query<
      GetTeamDetailsByIdResponse,
      string | undefined
    >({
      query: (teamId) => ({
        url: `${END_POINTS.teams}/${teamId}/`,
        method: "GET",
      }),
    }),
    putActiveTeam: builder.mutation<AddTeamsResponse, PutActiveParams>({
      query: ({ body, teamId }) => ({
        url: `${END_POINTS.teams}/${teamId}`,
        method: "PUT",
        body,
      }),
    }),
    getTournamentsReports: builder.query<
      GetAllTournamentsResponse,
      { size: number; page: number; query: string; userId: string }
    >({
      query: ({ size, page, query, userId }) => ({
        url:
          `${END_POINTS.reports}/${userId}` +
          "?size=" +
          size +
          "&page=" +
          page +
          "&search=" +
          query,
        method: "GET",
      }),
    }),
    // getTokenAddList: builder.query<
    //   GetAllTournamentsResponse,
    //   { size: number; page: number; query: string; searchParam: any }
    // >({
    //   query: ({ size, page, query, searchParam }) => ({
    //     url: `${END_POINTS.tokenList}?size=${size}&page=${page}&${searchParam}=${query}`,
    //     method: "GET",
    //   }),
    // }),
    getTokenAddList: builder.query<
      GetAllTournamentsResponse,
      { size: number; page: number; query: string; searchParam: any }
    >({
      query: ({ size, page, query, searchParam }) => ({
        // url: `${END_POINTS.tokenList}?size=${size}&page=${page}+{${searchParam} ?'&'+ ${searchParam}=${query}: ''}`,
        url:
          END_POINTS.tokenList +
          "?" +
          `size=${size}&page=${page}` +
          (searchParam?.transactionType
            ? `&transactionType=${searchParam?.transactionType}`
            : "") +
          (searchParam?.payMethod
            ? `&payMethod=${searchParam?.payMethod}`
            : "") +
          (searchParam?.payStatus
            ? `&payStatus=${searchParam?.payStatus}`
            : "") +
          (searchParam?.receiver ? `&receiver=${searchParam?.receiver}` : "") +
          (searchParam?.userName ? `&userName=${searchParam?.userName}` : "") +
          (searchParam?.startDate
            ? `&startDate=${searchParam?.startDate}`
            : "") +
          (searchParam?.endDate ? `&endDate=${searchParam?.endDate}` : ""),

        method: "GET",
      }),
    }),
    getReceiverList: builder.query<GetAllTournamentsResponse, {}>({
      query: () => ({
        url: END_POINTS.receiverList,
        method: "GET",
      }),
    }),
    deleteReceiverCategory: builder.mutation<
      { statusCode: number },
      { Id: string }
    >({
      query: ({ Id }) => ({
        url: `${END_POINTS.receiverList}/${Id}`,
        method: "DELETE",
      }),
    }),
    getUsersList: builder.query<GetUsersResponse, {}>({
      query: () => ({
        url: END_POINTS.getUsers,
        method: "GET",
      }),
    }),
    editReceiverById: builder.mutation<
      EditTournamentResponse,
      EditReceiverParams
    >({
      query: ({ body, Id }) => ({
        url: `${END_POINTS.receiverList}/${Id}`,
        method: "PUT",
        body,
      }),
    }),
    getReceiverDetailsById: builder.query<
      GetTeamDetailsByIdResponse,
      string | undefined
    >({
      query: (Id) => ({
        url: `${END_POINTS.receiverList}/${Id}/`,
        method: "GET",
      }),
    }),
    getPredictionForTournaments: builder.query<
      GetAllTournamentsResponse,
      { tournamentId: string; categoryId: string; username: string }
    >({
      query: ({ tournamentId, categoryId, username }) => ({
        url:
          `${END_POINTS.tournamentPrediction}/${tournamentId}` +
          "?categoryId=" +
          categoryId +
          "&userName=" +
          username,
        method: "GET",
      }),
    }),

    getAllPredictions: builder.query<
      GetAllTournamentsResponse,
      { tournamentId: string; categoryId: string; page: number; size: number }
    >({
      query: ({ tournamentId, categoryId, page, size }) => ({
        url:
          `${END_POINTS.allPrediction}/${tournamentId}` +
          "?categoryId=" +
          categoryId +
          "&page=" +
          page +
          "&size=" +
          size,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useLazyGetUsersListQuery,
  useLazyGetTokenAddListQuery,
  useLazyGetTournamentsReportsQuery,
  usePutActiveTeamMutation,
  useLazyGetTeamsDetailsByIdQuery,
  useDeleteTeamCategoryMutation,
  useLazyGetLeaguesQuery,
  useLazyGetLeagueTournamentQuery,
  useLazyGetLeagesByIdQuery,
  usePostAddTournamentsMutation,
  useLazyGetAllTournamentsQuery,
  useDeleteCategoryMutation,
  usePutBlockCategoryMutation,
  useLazyGetTournamentDetailsByIdQuery,
  useEditTournamentsByIdMutation,
  useLazyGetLeagueDataQuery,
  useLazyGetTeamsQuery,
  usePostAddTeamsMutation,
  usePutEditTeamCategoryMutation,
  useLazyGetReceiverListQuery,
  useAddReceiverMutation,
  useDeleteReceiverCategoryMutation,
  useEditReceiverByIdMutation,
  useLazyGetReceiverDetailsByIdQuery,
  useSettlementMutation,
  useLazyGetRankingDetailsByIdQuery,
  useLazyGetPredictionForTournamentsQuery,
  useLazyGetAllPredictionsQuery,
} = TournamentsApi;
