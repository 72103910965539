import { Card, Col, Input, Row, Button, Space, Form } from "antd";
import { ContentHeader } from "../../components";
import { EyeOutlined, SearchOutlined } from "@ant-design/icons";
import Table, { ColumnsType } from "antd/es/table";

import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useLazyGetLeaderBoardsQuery } from "../../services/banners";

const ManageLeaderboard = () => {
  const navigate = useNavigate();
  const [totalCount, setTotalCount] = useState<number>();

  const [listData, setListData] = useState([]);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const [query, setQuery] = useState("");
  const [getLeaderBoard, getLeaderBoardData] = useLazyGetLeaderBoardsQuery();

  const [rowNumber, setRowNumber] = useState(0);

  const pageSize = 10;

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    getLeaderBoardList(page);
    const newRowNumber = (page - 1) * pageSize;
    setRowNumber(newRowNumber);
  };

  interface DataType {
    _id: string;
    userId: any;
    key: string;
    points: string;
    score: string;
    nunberOfTokens: string;
    winners: string;
    rank: string;
    name: string;
  }

  const columns: ColumnsType<DataType> = [
    {
      title: "Player Ranking",
      key: "rank",
      dataIndex: "rank",
      align: "center",
      render: (text, record, index) => `#${rowNumber + index + 1}`,
    },
    {
      title: "Player Name",
      dataIndex: "name",
      key: "name",
      align: "center",
      render: (_, record) => (
        <div>
          <span>{`${record?.userId?.firstName || "N/A"}`}</span>
        </div>
      ),
    },

    {
      title: "Player Points",
      dataIndex: "totalPoints",
      key: "totalPoints",
      align: "center",
    },

    {
      title: "Winning Matches",
      dataIndex: "winningMatches",
      key: "winningMatches",
      align: "center",
    },
    {
      title: "Player Tokens",
      dataIndex: "tokens",
      key: "tokens",
      align: "center",
      render: (_, record) => (
        <div>
          <span>{`${record?.userId?.tokens || "N/A"}`}</span>
        </div>
      ),
    },
    {
      title: "Earned Tokens",
      dataIndex: "totalTokens",
      key: "totalTokens",
      align: "center",
    },
    {
      title: "View Details",
      key: "action",
      align: "center",
      render: (_, record) => (
        <Space size="middle">
          <Button
            onClick={(e) => {
              e.preventDefault();
              navigate(`/manageLeaderboard/leaderboarddetails/${record?._id}`);
            }}
          >
            <EyeOutlined style={{ color: "#52c41a" }} />
          </Button>
        </Space>
      ),
    },
  ];

  const getLeaderBoardList = async (page: number) => {
    try {
      const res = await getLeaderBoard({
        page: page,
        size: 10,
        query: query || "",
      }).unwrap();

      if (res?.statusCode === 200) {
        setListData(res?.data?.data);
        setTotalCount(res?.data?.count);
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (query === "") {
      getLeaderBoardList(1);
    }
  }, [query]);

  const pagination = {
    pageSize,
    current: currentPage,
    total: totalCount,
    onChange: handlePageChange,
  };

  return (
    <div style={{ padding: 40 }}>
      <ContentHeader heading="Manage Leaderboard" />
      <Card>
        <div className="srch">
          <Row style={{ padding: "10px 0px" }} gutter={16}>
            <Col xs={24} md={7}>
              <Form
                style={{ display: "flex", gap: 20 }}
                onFinish={() => getLeaderBoardList(1)}
              >
                <Input
                  placeholder="Search"
                  prefix={<SearchOutlined style={{ fontSize: "24px" }} />}
                  style={{ height: 50, borderRadius: 10 }}
                  onChange={(e) => setQuery(e.target.value)}
                />
                <Button
                  style={{
                    background: "#ECAC35",
                    width: 90,
                    height: 43,
                    margin: "auto",
                    color: "black",
                  }}
                  type="default"
                  htmlType="submit"
                >
                  Search
                </Button>
              </Form>
            </Col>
          </Row>

          <Table
            columns={columns}
            dataSource={listData}
            rowKey="_id"
            loading={getLeaderBoardData.isLoading}
            pagination={pagination}
          />
        </div>
      </Card>
    </div>
  );
};

export default ManageLeaderboard;
