import AuthWrapper from "../../../layout/authWrapper";
import "../login/login.css";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Card, Form, Input } from "antd";
import { useNavigate } from "react-router-dom";
import { usePostForgetPasswordMutation } from "../../../services/auth";
import { errorToast, successToast } from "../../../helpers";

const ForgetPassword = () => {
  const navigate = useNavigate();

  const [postforgetPassword, PostforgetPasswordData] =
    usePostForgetPasswordMutation();

  const onFinish = async (values: any) => {
    const body = {
      email: values.email,
    };

    try {
      const res = await postforgetPassword(body).unwrap();

      if (res?.statusCode === 200) {
        successToast(res?.message);
        navigate("/", { replace: true });
      } else {
        errorToast(res?.message);
      }
    } catch (error: any) {
      errorToast(error?.data?.message || error?.data?.error);
    }
  };

  return (
    <AuthWrapper>
      <Card className="login_card">
        <div onClick={() => navigate(-1)}>
          <ArrowLeftOutlined
            style={{ fontSize: 20, cursor: "pointer", color: "#fff" }}
          />
        </div>
        <div className="login_img">
          <img src="/static/images/logo.png" alt="" />
        </div>
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          style={{
            maxWidth: 600,
            marginTop: 40,
          }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          //   onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: "Please input your email!",
              },
              {
                type: "email",
                message: "The input is not valid email!",
              },
            ]}
          >
            <Input
              style={{ width: "460px", height: "50px" }}
              placeholder="Email"
            />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
            <div style={{ display: "flex" }}>
              <Button
                style={{
                  background: "#ECAC35",
                  width: 200,
                  height: 50,
                  margin: "auto",
                  fontWeight: 600,
                  color: "black",
                }}
                type="primary"
                htmlType="submit"
                loading={PostforgetPasswordData.isLoading}
              >
                Send
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Card>
    </AuthWrapper>
  );
};

export default ForgetPassword;
