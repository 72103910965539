import { Button, Card, Col, Row, Select } from "antd";

import { DownloadOutlined, StarFilled } from "@ant-design/icons";
import Table, { ColumnsType } from "antd/es/table";

import { Dispatch, SetStateAction, useState } from "react";
import { API_URL, END_POINTS } from "../../helpers";

type TableTypes = {
  tableData: any;
  predictionCategory: string | undefined;
  setPredictionCategory: Dispatch<SetStateAction<string | undefined>>;
  currentPage: number;
  totalCount: number | undefined;
  getPredictionDetails: (page: number) => void;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  predictions: any;
  loading: boolean;
  tournamentId: string;
};

const AllPredictions = ({
  tableData,
  predictionCategory,
  setPredictionCategory,
  getPredictionDetails,
  setCurrentPage,
  currentPage,
  totalCount,
  predictions,
  loading,
  tournamentId,
}: TableTypes) => {
  const { Option } = Select;

  const [rowNumber, setRowNumber] = useState(0);

  const pageSize = 10;

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    getPredictionDetails(page);
    const newRowNumber = (page - 1) * pageSize;
    setRowNumber(newRowNumber);
  };

  const pagination = {
    pageSize,
    current: currentPage,
    total: totalCount,
    onChange: handlePageChange,
  };

  interface DataType {
    homeTeam_goals: string;
    userName: string;
    awayTeam: string;
    awayTeam_goals: string;
    homeTeam: string;
    isFavourite: boolean;
    tokenCategory: {
      name: string;
    };
  }

  const columns: ColumnsType<DataType> = [
    {
      title: "#",
      key: "rowNumber",
      align: "center",
      width: "10%",
      render: (text, record, index) => `${rowNumber + index + 1}`,
    },
    {
      title: "User Name",
      dataIndex: "username",
      key: "username",
      align: "center",
      render: (_, record) => (
        <div>
          <span>{`${record?.userName || "N/A"}`}</span>
        </div>
      ),
    },
    {
      title: "Category Name",
      dataIndex: "categoryName",
      key: "categoryName",
      align: "center",
      render: (_, record) => (
        <div>
          <span>{`${record?.tokenCategory?.name || "N/A"}`}</span>
        </div>
      ),
    },
    {
      title: "Team A",
      dataIndex: "teamA",
      key: "teamA",
      align: "center",
      render: (_, record) => (
        <div>
          <span>{`${record?.homeTeam || "N/A"}`}</span>
        </div>
      ),
    },
    {
      title: "Team B",
      dataIndex: "teamB",
      key: "teamB",
      align: "center",
      render: (_, record) => (
        <div>
          <span>{`${record?.awayTeam || "N/A"}`}</span>
        </div>
      ),
    },
    {
      title: "Predictions",
      dataIndex: "goals",
      key: "goals",
      align: "center",
      render: (_, record) => (
        <div>
          <span>
            {`${record?.homeTeam_goals} : ${record?.awayTeam_goals}` || ""}
          </span>
          {record?.isFavourite ? (
            <StarFilled
              style={{ color: "#ECAC35", fontSize: 16, marginLeft: 8 }}
            />
          ) : (
            ""
          )}
        </div>
      ),
    },
  ];

  const getCsv = () => {
    window.open(
      ` ${
        API_URL + END_POINTS.getPredictionCsv
      }?tournamentId=${tournamentId}&categoryId=${predictionCategory || ""}`
    );
  };

  const searchForSelectedReceiver = (value: any) => {
    setPredictionCategory(value);
  };

  return (
    <div style={{}}>
      <Card>
        <Row style={{ justifyContent: "space-between" }}>
          <Col xs={24} md={5}>
            <Select
              allowClear
              maxLength={20}
              className="select_type"
              onChange={(val) => searchForSelectedReceiver(val)}
              value={predictionCategory}
              // defaultValue={tableData?.tournamentCategories[0]?._id}
              style={{
                height: 75,
                width: "100%",
              }}
              placeholder="Select Category"
            >
              {tableData?.tournamentCategories?.map((item: any, index: any) => (
                <Option key={index} value={item?._id} label={item?.name}>
                  {item?.name}
                </Option>
              ))}
            </Select>
          </Col>
          <Col
            style={{
              margin: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Button
              icon={<DownloadOutlined />}
              style={{
                background: "#ECAC35",
                height: 35,
                color: "black",
              }}
              type="primary"
              onClick={getCsv}
            >
              Export CSV
            </Button>
          </Col>
        </Row>
        <div className="srch">
          <Row style={{ paddingTop: 10, paddingBottom: 10 }} gutter={16}></Row>

          <Table
            className="tableSelector"
            columns={columns}
            dataSource={predictions}
            pagination={pagination}
            rowKey={"_id"}
            loading={loading}
          />
        </div>
      </Card>
    </div>
  );
};

export default AllPredictions;
