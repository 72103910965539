import { Button, Card, Col, Form, Input, Row } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import "react-phone-input-2/lib/style.css";
import {
  useLazyGetTokenCategoryQuery,
  usePostAddTokenMutation,
  usePutEditTokenCategoryMutation,
} from "../../../services/auth";
import { useEffect, useState } from "react";
import { successToast } from "../../../helpers";
import { isNumber } from "../../../utils/validations";

const AddTokenCategories = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { view } = useParams<string>();

  const [editTokenCategory, editTokenCategoryData] =
    usePutEditTokenCategoryMutation();

  const [AddTokenCategory, AddTokenCategoryData] = usePostAddTokenMutation();
  const [viewTokenCategory, viewTokenCategoryData] =
    useLazyGetTokenCategoryQuery();

  const [categoryName, setCategoryName] = useState("");
  const [tokens, setTokens] = useState<number>(0);

  const url = window.location.pathname;
  const path = url.includes("add");

  const viewToken = async () => {
    try {
      const res = await viewTokenCategory(view).unwrap();
      if (res?.statusCode === 200) {
        form.setFieldsValue({
          categoryName: res?.data?.name,
          numberOfToken: res?.data?.tokens,
        });
        setCategoryName(res?.data?.name || "");
        setTokens(res?.data?.tokens);
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  const onFinish = async () => {
    let body = {
      name: categoryName,
      tokens: tokens,
    };
    try {
      if (path) {
        const res = await AddTokenCategory(body).unwrap();

        if (res?.statusCode === 200) {
          setCategoryName("");
          setTokens(parseInt(""));
          navigate("/managetokenscategories", { replace: true });
          successToast("Category Added Successfully");
        }
      } else {
        {
          const res = await editTokenCategory({
            body,
            Token_id: view,
          }).unwrap();

          if (res?.statusCode === 200) {
            setCategoryName("");
            setTokens(parseInt(""));
            navigate("/managetokenscategories", { replace: true });
            successToast("Category Edited Successfully");
          }
        }
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (view !== "add") {
      viewToken();
    }
  }, []);

  return (
    <div style={{ padding: 50 }}>
      <Card>
        <div
          style={{
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <h2>{path ? "Add " : "Edit "}Token Categories</h2>
          <Button
            style={{
              background: "#ECAC35",
              width: 100,
              height: 40,
              color: "black",
            }}
            type="primary"
            // htmlType="submit"
            onClick={() => {
              navigate("/managetokenscategories", { replace: true });
            }}
          >
            Back
          </Button>
        </div>

        <div>
          <Form
            name="basic"
            layout="vertical"
            form={form}
            style={{
              maxWidth: "80%",
              marginTop: 50,
            }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            autoComplete="off"
          >
            <Row gutter={16}>
              <Col xs={24} md={12}></Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} md={12}>
                <Form.Item
                  name="categoryName"
                  rules={[
                    {
                      required: true,
                      message: "Please input category name",
                      whitespace: true,
                    },
                    {
                      min: 3,
                      message: "Name must be at least 5 characters",
                    },
                  ]}
                >
                  <div>
                    <h4>Token Category Name</h4>
                    <Input
                      value={categoryName}
                      placeholder="Category Name"
                      style={{ height: 55 }}
                      onChange={(e) => {
                        setCategoryName(e.target.value);
                      }}
                    />
                  </div>
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  name="numberOfToken"
                  rules={[
                    {
                      required: true,
                      message: "Please input Number Of Tokens!",
                    },
                  ]}
                >
                  <div>
                    <h4>Number Of Tokens</h4>
                    <Input
                      value={tokens}
                      type="tel"
                      placeholder="Number Of Tokens"
                      style={{ height: 55 }}
                      onChange={(e) => {
                        const input = Number(e.target.value);
                        if (!isNaN(input)) {
                          setTokens(input);
                        }
                      }}
                      maxLength={5}
                    />
                  </div>
                </Form.Item>
              </Col>
            </Row>

            <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
              <div style={{ display: "flex" }}>
                <Button
                  style={{
                    background: "#ECAC35",
                    width: 200,
                    height: 50,
                    margin: "auto",
                    color: "black",
                  }}
                  type="primary"
                  htmlType="submit"
                  loading={
                    AddTokenCategoryData.isLoading ||
                    editTokenCategoryData.isLoading
                  }
                >
                  Submit
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      </Card>
    </div>
  );
};

export default AddTokenCategories;
