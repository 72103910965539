import { Button, Card, Col, Form, Input, Row, Space, Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { ContentHeader } from "../../components";
import { EyeOutlined, SearchOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useLazyGetUsersQuery } from "../../services/users";
import moment from "moment";

interface DataType {
  _id: any;
  key: string;
  name: string;
  phone: string;
  userName: string;
  email: string;
  status: boolean;
  lastDepositAmount: number;
  lastDepositDate: string;
  totalDeposits: number;
  totalTournamentsPlayed: number;
  tokens: number;
  countryCode: string;
  firstName: string;
  createdAt: string;
  isBlocked: boolean;
}

const Reports = () => {
  const navigate = useNavigate();
  const [userList, userListData] = useLazyGetUsersQuery();
  const [userListState, setUserListState] = useState([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalCount, setTotalCount] = useState<number | undefined>();
  const [query, setQuery] = useState("");
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    getUserDetails(page);
  };
  // const handleViewClick = async (record: any) => {
  //   navigate(`/reports/${record?._id}`);
  // };

  const handleViewClick = async (record: any) => {
    navigate(`/manageusers/userdetail/${record?._id}/reports`);
  };

  const columns: ColumnsType<DataType> = [
    // {
    //   title: "#",
    //   key: "rank",
    //   dataIndex: "rank",
    //   align: "center",
    //   width: "10%",
    //   render: (text, record, index) => index + 1,
    // },
    {
      title: "User Name",
      dataIndex: "userName",
      key: "userName",
      align: "center",
      width: "8%",

      render: (_, { userName }: any) => (
        <div>
          <span>{`${userName || "N/A"}`}</span>
        </div>
      ),
    },
    {
      title: "Name",
      dataIndex: "firstName",
      key: "firstName",
      align: "center",

      render: (_, { firstName }) => (
        <div>
          <span>{`${firstName || "N/A"}`}</span>
        </div>
      ),
    },

    {
      title: "Phone Number",
      dataIndex: "phone",
      key: "phone",
      align: "center",

      render: (_, { phone, countryCode }) => (
        <div>
          <span>{`${phone ? countryCode + " " + phone : "N/A"}`}</span>
        </div>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      align: "center",

      render: (_, { email }) => (
        <div>
          <span>{`${email || "N/A"}`}</span>
        </div>
      ),
    },
    {
      title: "Registered On",
      dataIndex: "firstName",
      key: "firstName",
      align: "center",

      render: (_, { createdAt }) => (
        <div>
          <span>{`${
            createdAt ? moment(createdAt).format("YYYY-MM-DD") : "-"
          }`}</span>
        </div>
      ),
    },

    {
      title: "Available Tokens",
      dataIndex: "phone",
      key: "phone",
      align: "center",

      render: (_, { tokens }) => (
        <div>
          <span>{`${tokens || 0}`}</span>
        </div>
      ),
    },
    {
      title: "Total Deposits",
      dataIndex: "email",
      key: "email",
      align: "center",

      render: (_, { totalDeposits }) => (
        <div>
          <span>{`${totalDeposits || 0}`}</span>
        </div>
      ),
    },
    {
      title: "Last Deposit Amount",
      dataIndex: "firstName",
      key: "firstName",
      align: "center",

      render: (_, { lastDepositAmount }) => (
        <div>
          <span>{`${lastDepositAmount || 0}`}</span>
        </div>
      ),
    },
    {
      title: "Last Deposit Date",
      dataIndex: "userName",
      key: "userName",
      align: "center",
      width: "8%",

      render: (_, { lastDepositDate }) => (
        <div>
          <span>{`${
            lastDepositDate ? moment(lastDepositDate).format("YYYY-MM-DD") : "-"
          }`}</span>
        </div>
      ),
    },
    {
      title: "Total Tournaments Played",
      dataIndex: "phone",
      key: "phone",
      align: "center",

      render: (_, { totalTournamentsPlayed }) => (
        <div>
          <span>{`${totalTournamentsPlayed || 0}`}</span>
        </div>
      ),
    },
    {
      title: "Status",
      dataIndex: "userName",
      key: "userName",
      align: "center",

      render: (_, { isBlocked }) => (
        <div>
          <span>{!isBlocked ? "Active" : "Inactive"}</span>
        </div>
      ),
    },

    {
      title: "View Reports ",
      key: "action",
      align: "center",
      render: (_, record) => (
        <Space size="middle">
          <Button
            onClick={(e) => {
              e.preventDefault();
              handleViewClick(record);
            }}
          >
            <EyeOutlined style={{ color: "#52c41a" }} />
          </Button>
        </Space>
      ),
    },
  ];

  const getUserDetails = async (page: number) => {
    try {
      const res = await userList({
        page: page,
        size: 10,
        query: query || "",
      }).unwrap();

      if (res?.statusCode === 200) {
        setUserListState(res?.data?.data);
        setTotalCount(res?.data?.count);
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  const pagination = {
    pageSize: 10,
    current: currentPage,
    total: totalCount,
    onChange: handlePageChange,
  };

  useEffect(() => {
    if (query === "") {
      getUserDetails(1);
    }
  }, [query]);

  return (
    <div style={{ padding: 40 }}>
      <ContentHeader heading="Reports" />
      <div className="srch">
        <Card>
          <Row
            style={{ justifyContent: "space-between", padding: "10px 0px" }}
            gutter={16}
          >
            <Col xs={24} md={7}>
              <Form
                style={{ display: "flex", gap: 20 }}
                onFinish={() => getUserDetails(1)}
              >
                <Input
                  placeholder="Search"
                  prefix={<SearchOutlined style={{ fontSize: "24px" }} />}
                  style={{ height: 50, borderRadius: 10 }}
                  onChange={(e) => setQuery(e.target.value)}
                />
                <Button
                  style={{
                    background: "#ECAC35",
                    width: 90,
                    height: 43,
                    margin: "auto",
                    color: "black",
                  }}
                  type="default"
                  htmlType="submit"
                >
                  Search
                </Button>
              </Form>
            </Col>
            <Col
              style={{
                margin: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            ></Col>
          </Row>
        </Card>
      </div>
      <Table
        className="tableSelector"
        columns={columns}
        dataSource={userListState}
        rowKey="_id"
        loading={userListData?.isLoading}
        pagination={pagination}
        scroll={{ x: 1500 }}
      />
    </div>
  );
};

export default Reports;
