export const isString = (val: string) => {
  if (val.includes(".")) {
    return true;
  }
  // if (val.length <= 2) {
  //   return false;
  // }
  if (val.length === 1 && val === " ") {
    return false;
  }
  if (
    val[val.length - 1] === " " &&
    val[val.length - 1] !== val[val.length - 2]
  ) {
    return true;
  }
  if (
    val[val.length - 1]?.trim()?.toLowerCase() !==
      val[val.length - 1]?.trim()?.toUpperCase() ||
    val === ""
  ) {
    return true;
  }
  return false;
};

export const isNumber = (value: string) => {
  if (!value.length) {
    return true;
  }

  return (
    !isNaN(parseFloat(value[value.length - 1])) &&
    isFinite(parseInt(value[value.length - 1]))
  );
};

export const isValidAlphaNumeric = (text: any) => {
  const reg = /[^A-Za-z0-9]/g;
  if (reg.test(text) !== true) {
    return true;
  }
  return false;
};
