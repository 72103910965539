import { useEffect, useState } from "react";
import {
  UserOutlined,
  TeamOutlined,
  BarChartOutlined,
  PieChartOutlined,
  FormOutlined,
  TrophyOutlined,
  SwitcherOutlined,
  SnippetsOutlined,
  BellOutlined,
  PayCircleOutlined,
  CalendarOutlined,
  PlusCircleOutlined,
  StarOutlined,
} from "@ant-design/icons";
import { Layout, Menu } from "antd";
import "./layout.css";
import { useNavigate, useLocation } from "react-router-dom";
import useAuth from "../hooks/useAuth";

const { Sider } = Layout;

const SiderBar = ({ collapsed }: { collapsed: boolean }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const userData = useAuth();
  const [selectedKey, setSelectedKey] = useState("1");
  const [list, setList] = useState<any>([]);

  const items = [
    {
      key: "1",
      icon: <PieChartOutlined />,
      label: "Dashboard",
      onClick: () => navigate("/dashboard"),
    },
    {
      key: "2",
      icon: <TeamOutlined />,
      label: "Manage Sub-Admin",
      onClick: () => navigate("/managesubadmins"),
    },
    {
      key: "3",
      icon: <UserOutlined />,
      label: "Manage Users",
      onClick: () => navigate("/manageusers"),
    },
    {
      key: "4",
      icon: <PlusCircleOutlined />,
      label: "Add Tokens",
      onClick: () => navigate("/addtokens"),
    },
    {
      key: "5",
      icon: <BarChartOutlined />,
      label: "Manage Leaderboard",
      onClick: () => navigate("/manageleaderboard"),
    },
    {
      key: "6",
      icon: <CalendarOutlined />,
      label: "Seasonal Competition",
      onClick: () => navigate("/seasonalcompetition"),
    },
    {
      key: "7",
      icon: <TrophyOutlined />,
      label: "Manage Tournaments",
      onClick: () => navigate("/managetournaments"),
    },
    {
      key: "8",
      icon: <PayCircleOutlined />,
      label: "Manage Tokens Categories",
      onClick: () => navigate("/managetokenscategories"),
    },
    // {
    //   key: "9",
    //   // icon: <LineChartOutlined />,
    //   // label: "Analytics",
    //   // onClick: () => navigate("/analytics"),
    // },
    {
      key: "9",
      icon: <StarOutlined />,
      label: "Favorite Teams",
      onClick: () => navigate("/favoriteteams"),
    },
    {
      key: "10",
      icon: <BellOutlined />,
      label: "Notifications",
      onClick: () => navigate("/notifications"),
    },
    // {
    //   key: "11",
    //   icon: <SolutionOutlined />,
    //   label: "Customer Support",
    //   onClick: () => navigate("/customersupport"),
    // },
    // {
    //   key: "11",
    //   icon: <ContainerOutlined />,
    //   label: "Manage News",
    //   onClick: () => navigate("/mangeNews"),
    // },

    {
      key: "11",
      icon: <SnippetsOutlined />,
      label: "Reports",
      onClick: () => navigate("/reports"),
    },
    {
      key: "12",
      icon: <FormOutlined />,
      label: "Manage CMS",
      onClick: () => navigate("/managecms"),
    },
    {
      key: "13",
      icon: <SwitcherOutlined />,
      label: "Manage FAQ's",
      onClick: () => navigate("/managefaq"),
    },
    // {
    //   key: "14",
    //   icon: <SettingOutlined />,
    //   label: "Settings",
    //   onClick: () => navigate("/settings"),
    // },
  ];

  useEffect(() => {
    if (location?.pathname?.includes("dashboard")) {
      setSelectedKey("1");
    } else if (location?.pathname?.includes("managesubadmins")) {
      setSelectedKey("2");
    } else if (location?.pathname?.includes("manageusers")) {
      setSelectedKey("3");
    } else if (location?.pathname?.includes("addtokens")) {
      setSelectedKey("4");
    } else if (location?.pathname?.includes("manageleaderboard")) {
      setSelectedKey("5");
    } else if (location?.pathname?.includes("managebanners")) {
      setSelectedKey("6");
    } else if (location?.pathname?.includes("managetournaments")) {
      setSelectedKey("7");
    } else if (location?.pathname?.includes("managetokenscategories")) {
      setSelectedKey("8");
    } else if (location?.pathname?.includes("favoriteteams")) {
      setSelectedKey("9");
    } else if (location?.pathname?.includes("notifications")) {
      setSelectedKey("10");
    } else if (location?.pathname?.includes("reports")) {
      setSelectedKey("11");
    } else if (location?.pathname?.includes("managecms")) {
      setSelectedKey("12");
    } else if (location?.pathname?.includes("managefaq")) {
      setSelectedKey("13");
    } else if (location?.pathname?.includes("settings")) {
      setSelectedKey("14");
    } else {
      setSelectedKey("");
    }
  }, [location]);

  useEffect(() => {
    let arr = [];
    if (userData && userData?.permissions?.length) {
      const permissions = userData?.permissions;
      arr = items?.map((item) => {
        let idx = -1;
        idx = permissions?.findIndex((ele: any) => ele?.label === item?.label);
        if (idx != -1) {
          if (permissions[idx].isView) {
            return item;
          }
          return false;
        }
      });
    } else {
      arr = items;
    }
    setList(arr);
  }, [userData]);

  return (
    <Sider trigger={null} collapsible collapsed={collapsed}>
      <div className="logo">
        <img src={"/static/images/logo.png"} className="logo_img" alt="" />
      </div>
      <Menu
        style={{ marginTop: 16 }}
        theme="dark"
        mode="inline"
        className="side_menu"
        defaultSelectedKeys={[selectedKey]}
        selectedKeys={[selectedKey]}
        items={list}
      />
    </Sider>
  );
};

export default SiderBar;
