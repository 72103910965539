import { Button, Card, DatePicker, Space, Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { EyeOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { useLazyUserTournamentQuery } from "../../services/users";
import { EventValue, RangeValue } from "rc-picker/lib/interface";

import { Dayjs } from "dayjs";
import { useNavigate } from "react-router-dom";

type PropTypes = {
  id: string | undefined;
};

const PlayerTournamentDetailsTable = ({ id }: PropTypes) => {
  const navigate = useNavigate();

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [totalCount, setTotalCount] = useState<number | undefined>();
  const [getUserTournament, getUserTournamentData] =
    useLazyUserTournamentQuery();
  const [userPredictions, setUserPredictions] = useState<any>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [selectedDates, setSelectedDates] = useState<
    RangeValue<Dayjs>[] | null
  >([]);

  interface DataType {
    fixtures: any[];
    noOfEntries: number;
    totalTokens: number;
    _id: string;
  }
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    getUserPredictionsHandler(page);
  };

  const columns: ColumnsType<DataType> = [
    // {
    //   title: "#",
    //   key: "rank",
    //   dataIndex: "rank",
    //   align: "center",
    //   width: "20%",
    //   render: (text, record, index) => index + 1,
    // },
    {
      title: "Tournament Name",
      key: "name",
      dataIndex: "name",
      align: "center",
    },
    {
      title: "Start Date",
      key: "rank",
      dataIndex: "rank",
      align: "center",

      render: (_, record) => {
        const smallestDate: Date | null = record?.fixtures.reduce(
          (smallest: any, obj: any) => {
            const currentDate = new Date(obj.fixture.date);
            return !smallest || currentDate < smallest ? currentDate : smallest;
          },
          null
        );
        return smallestDate ? smallestDate.toISOString().substr(0, 10) : "-";
      },
    },

    {
      title: "End Date",
      dataIndex: "points",
      key: "points",
      align: "center",
      render: (_, record) => {
        const largestDate: Date | null = record?.fixtures.reduce(
          (largest: Date | null, obj: any) => {
            const currentDate = new Date(obj.fixture.date);
            return !largest || currentDate > largest ? currentDate : largest;
          },
          null
        );
        return largestDate ? largestDate.toISOString().substr(0, 10) : "-";
      },
    },
    {
      title: "Number Of Entries",
      dataIndex: "noOfEntries",
      key: "noOfEntries",
      align: "center",
      render: (_, record) => (
        <div>
          <span>{record?.noOfEntries || "-"}</span>
        </div>
      ),
    },

    {
      title: "Total Tokens",
      dataIndex: "totalTokens",
      key: "totalTokens",
      align: "center",
      render: (_, record) => (
        <div>
          <span>{record?.totalTokens || "-"}</span>
        </div>
      ),
    },

    {
      title: "View Details",
      key: "action",
      align: "center",
      render: (_, record) => (
        <Space size="middle">
          <Button
            onClick={(e) => {
              e.preventDefault();
              navigate(`/userdetail/viewprediction/${id}/${record?._id}`);
              // navigate(`/userdetail/viewprediction/0909090909`);
            }}
          >
            <EyeOutlined style={{ color: "#52c41a" }} />
          </Button>
        </Space>
      ),
    },
  ];

  const getUserPredictionsHandler = async (page: number) => {
    try {
      const res = await getUserTournament({
        userId: id,
        page: page,
        size: 10,
        startDate: startDate,
        endDate: endDate,
      }).unwrap();

      if (res?.statusCode === 200) {
        setUserPredictions(res?.data?.data);
        setTotalCount(res?.data?.count);
      }
    } catch (error: any) {
      console.log(error);
    }
  };
  const pagination = {
    pageSize: 10,
    current: currentPage,
    total: totalCount,
    onChange: handlePageChange,
  };

  useEffect(() => {
    getUserPredictionsHandler(1);
  }, [selectedDates]);

  const handleDateChange = (dates: RangeValue<Dayjs>[]) => {
    setSelectedDates(dates);
  };

  return (
    <div>
      <Card>
        <div style={{ width: "20%", marginBottom: 20 }}>
          <DatePicker.RangePicker
            // disabledDate={(d) => !d || d.isAfter(new Date())}
            style={{ height: 43, width: "100%" }}
            placeholder={["Start date", "End date"]}
            format="YYYY-MM-DD"
            // value={dateRange}
            onChange={(val) => {
              handleDateChange(val as RangeValue<Dayjs>[]);
              if (val?.length) {
                setStartDate(val[0]?.format("YYYY-MM-DD") || "");
                setEndDate(val[1]?.format("YYYY-MM-DD") || "");
              } else {
                setStartDate("");
                setEndDate("");
              }
            }}
            value={selectedDates as [EventValue<Dayjs>, EventValue<Dayjs>]}
          />
        </div>
        <div>
          <div>
            <Table
              className="tableSelector"
              style={{ fontSize: 10 }}
              columns={columns}
              dataSource={userPredictions}
              pagination={pagination}
              loading={getUserTournamentData.isLoading}
            />
          </div>
        </div>
      </Card>
    </div>
  );
};

export default PlayerTournamentDetailsTable;
