import { Button, Card, Col, Input, Row, Select, Space } from "antd";

import { EyeOutlined, SearchOutlined } from "@ant-design/icons";
import Table, { ColumnsType } from "antd/es/table";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

type TableTypes = {
  tableData: TournamentDetailsTypes | undefined;
  rankingData: any;
  loading: boolean;
  selectedCategory: string | undefined;
  setSelectedCategory: Dispatch<SetStateAction<string | undefined>>;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  setQuery: Dispatch<SetStateAction<string>>;
  tournamentId: string | undefined;
  currentPage: number;
  totalCount: number | undefined;
  getTournamentRanking: (page: number) => void;
};

const RankingTable = ({
  tableData,
  setCurrentPage,
  currentPage,
  totalCount,
  setQuery,
  loading,
  selectedCategory,
  setSelectedCategory,
  rankingData,
  getTournamentRanking,
  tournamentId,
}: TableTypes) => {
  const navigate = useNavigate();
  const { Option } = Select;

  const [debouncedQuery, setDebouncedQuery] = useState("");
  const [rowNumber, setRowNumber] = useState(0);

  const pageSize = 10;

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    getTournamentRanking(page);
    const newRowNumber = (page - 1) * pageSize;
    setRowNumber(newRowNumber);
  };

  const pagination = {
    pageSize,
    current: currentPage,
    total: totalCount,
    onChange: handlePageChange,
  };

  interface DataType {
    key: string;
    points: string;
    userName: string;
    winners: string;
    rank: string;
    name: string;
    userId: {
      firstName: string;
    };
    categoryId: {
      name: string;
      _id: string;
    };
  }

  const columns: ColumnsType<DataType> = [
    {
      title: "#",
      key: "rowNumber",
      align: "center",
      width: "10%",
      render: (text, record, index) => `#${rowNumber + index + 1}`,
    },

    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      align: "center",
      render: (_, record) => (
        <div>
          <span>{`${record?.userId?.firstName || "N/A"}`}</span>
        </div>
      ),
    },
    {
      title: "User Name",
      dataIndex: "userName",
      key: "userName",
      align: "center",
    },
    {
      title: "Token Category",
      dataIndex: "tokenCategory",
      key: "tokenCategory",
      align: "center",
      render: (_, record) => (
        <div>
          <span>{`${record?.categoryId?.name || "N/A"}`}</span>
        </div>
      ),
    },

    {
      title: "Player Points",
      dataIndex: "points",
      key: "points",
      align: "center",
    },
    {
      title: "Number Of Matches",
      dataIndex: "totalMatches",
      key: "totalMatches",
      align: "center",
    },
    {
      title: "View Predictions",
      key: "action",
      align: "center",
      render: (_, record) => (
        <Space size="middle">
          <Button
            onClick={(e) => {
              e.preventDefault();
              navigate(
                `/managetournaments/tournamentdetails/${tournamentId}/${record?.categoryId?._id}/${record?.userName}`
              );
            }}
          >
            <EyeOutlined style={{ color: "#52c41a" }} />
          </Button>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    const delay = 1000; // Debounce delay in milliseconds
    const timerId = setTimeout(() => {
      setQuery(debouncedQuery);
    }, delay);

    return () => {
      clearTimeout(timerId); // Clear the timeout on cleanup
    };
  }, [debouncedQuery]);

  const searchForSelectedReceiver = (value: any) => {
    setSelectedCategory(value);
  };

  // useEffect(() => {
  //   if (tableData?.tokenCategories?.length) {
  //     setSelectedCategory(tableData?.tournamentCategories[0]?._id);
  //   }
  // }, [tableData]);

  return (
    <div>
      <Card>
        <div style={{ width: "20%", marginTop: 0 }} />
        <div className="srch">
          <div style={{ paddingBottom: 0 }}>
            {/* <span style={{ fontSize: 15 }}>
              Please select a token category to see the ranking details.
            </span> */}
          </div>
          <Row style={{ paddingTop: 0, paddingBottom: 0 }} gutter={16}>
            <Col xs={24} md={5}>
              <Select
                allowClear
                maxLength={20}
                className="select_type"
                onChange={(val) => searchForSelectedReceiver(val)}
                value={selectedCategory}
                // defaultValue={tableData?.tournamentCategories[0]?._id}
                style={{
                  height: 75,
                  width: "100%",
                }}
                placeholder="Select Category"
              >
                {tableData?.tournamentCategories?.map((item, index) => (
                  <Option key={index} value={item?._id} label={item?.name}>
                    {item?.name}
                  </Option>
                ))}
              </Select>
            </Col>

            <Col xs={24} md={7}>
              <Input
                placeholder="Search"
                prefix={<SearchOutlined style={{ fontSize: "24px" }} />}
                style={{ height: 43, borderRadius: 7, width: "60%" }}
                value={debouncedQuery}
                onChange={(e) => setDebouncedQuery(e.target.value)}
                maxLength={20}
              />
            </Col>
          </Row>

          <Table
            className="tableSelector"
            columns={columns}
            dataSource={rankingData}
            rowKey={"_id"}
            loading={loading}
            pagination={pagination}
          />
        </div>
      </Card>
    </div>
  );
};

export default RankingTable;
