import React, { useState, useContext } from "react";
import { STORAGE_KEYS, getData } from "../helpers";
interface LanguageContextType {
  language: string;
  changeLanguage: (lang: string) => void;
}
// const LanguageContext = React.createContext({});
const LanguageContext = React.createContext<LanguageContextType>({
  language: "en",
  changeLanguage: () => {},
});
export const useLanguageContext = () => useContext(LanguageContext);
export default function LanguageContextProvider({
  children,
}: {
  children: any;
}) {
  const lang = getData(STORAGE_KEYS.language);
  const [language, changeLanguage] = useState(`${lang}` || "en");
  return (
    <LanguageContext.Provider value={{ language, changeLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
}
