import { END_POINTS } from "../helpers";
import type { SubAdmins, User } from "../types/User";
import emptySplitApi from "../utils/rtk";

type PostAddSubAdminBody = {
  name: string;
  userName?: string | undefined;
  email: string;
  phone: string;
  countryCode: string;
  address: string;
  image?: string | undefined;
  password?: string;
  permission?: PermissionsBody[];
  accessRole?: string;
};

type PermissionsBody = {
  label?: string | undefined;
  isView?: boolean;
  isAdd?: boolean;
  isDelete?: boolean;
};

type PostAddSubAdminResponse = {
  statusCode: number;
  data?: any;
  message: string;
};

type GetSubAdminBody = {
  page?: number;
  size?: number;
  query?: string;
};

type GetSubAdminResponse = {
  statusCode: number;
  data?: any;
  message: string;
};

type DeleteSubadminResponse = {
  statusCode: number;
  code?: number;
  data: any;
  total?: number;
};

type DeleteSubadminParams = {
  subadmin_id: string;
};

type PutEditSubAdminParams = {
  subadmin_id: string;
  body: PutEditSubAdminBody;
};
type PutEditSubAdminResponse = {
  statusCode: number;
  data?: User;
  message: string;
};

type PutEditSubAdminBody = {
  name?: string;
  userName?: string;
  email?: string;
  phone?: string;
  countryCode?: string;
  address?: string;
  image?: string | undefined;
  password?: string;
  permission?: PermissionsBody[];
  isBlocked?: boolean;
  accessRole?: string;
};

type GetSubAdminByIdResponse = {
  statusCode: number;
  data: SubAdmins;
  total?: number;
  message?: string;
};

type PostAddRoleBody = {
  name: string;
};

type PostAddRoleResponse = {
  statusCode: number;
  data: any;
  message?: string;
};

type GetRoleBody = {
  page?: number;
  size?: number;
  query?: string;
};

type GetRoleResponse = {
  statusCode: number;
  data?: any;
  message: string;
};

type DeleteRoleResponse = {
  statusCode: number;
  data: any;
  message?: string;
};

type DeleteRoleParams = {
  role_id: string;
};

type UpdateRoleParams = {
  role_id: string;
  body: UpdateRoleBody;
};

type UpdateRoleBody = {
  name: string;
};

type UpdateRoleResponse = {
  statusCode: number;
  data: any;
  message?: string;
};

export const subAdminApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    postAddSubAdmin: builder.mutation<
      PostAddSubAdminResponse,
      PostAddSubAdminBody
    >({
      query: (body) => ({
        url: `${END_POINTS.subAdmin}`,
        method: "POST",
        body,
      }),
    }),
    getSubAdmin: builder.query<GetSubAdminResponse, GetSubAdminBody>({
      query: ({ size, page, query }) => ({
        url:
          END_POINTS.subAdmin +
          "?size=" +
          size +
          "&page=" +
          page +
          "&search=" +
          query,
        method: "GET",
      }),
    }),
    deleteSubAdmin: builder.mutation<
      DeleteSubadminResponse,
      DeleteSubadminParams
    >({
      query: ({ subadmin_id }) => ({
        url: `${END_POINTS.subAdmin}/${subadmin_id}`,
        method: "DELETE",
      }),
    }),
    postEditSubAdmin: builder.mutation<
      PutEditSubAdminResponse,
      PutEditSubAdminParams
    >({
      query: ({ body, subadmin_id }) => ({
        url: `${END_POINTS.subAdmin}/${subadmin_id}`,
        method: "PUT",
        body,
      }),
    }),
    getSubAdminDataById: builder.query<
      GetSubAdminByIdResponse,
      string | undefined
    >({
      query: (subadmin_id) => ({
        url: `${END_POINTS.subAdmin}/${subadmin_id}`,
        method: "GET",
      }),
    }),

    postAddRole: builder.mutation<PostAddRoleResponse, PostAddRoleBody>({
      query: (body) => ({
        url: `${END_POINTS.adminRole}`,
        method: "POST",
        body,
      }),
    }),
    getRole: builder.query<GetRoleResponse, GetRoleBody>({
      query: ({ size, page }) => ({
        url: END_POINTS.adminRole + "?size=" + size + "&page=" + page,
        method: "GET",
      }),
    }),
    deleteRole: builder.mutation<DeleteRoleResponse, DeleteRoleParams>({
      query: ({ role_id }) => ({
        url: `${END_POINTS.adminRole}/${role_id}`,
        method: "DELETE",
      }),
    }),
    postUpdateRole: builder.mutation<UpdateRoleResponse, UpdateRoleParams>({
      query: ({ body, role_id }) => ({
        url: `${END_POINTS.adminRole}/${role_id}`,
        method: "PUT",
        body,
      }),
    }),
  }),
});

export const {
  usePostAddSubAdminMutation,
  useLazyGetSubAdminQuery,
  useDeleteSubAdminMutation,
  usePostEditSubAdminMutation,
  useLazyGetSubAdminDataByIdQuery,
  useDeleteRoleMutation,
  usePostAddRoleMutation,
  useLazyGetRoleQuery,
  usePostUpdateRoleMutation,
} = subAdminApi;
