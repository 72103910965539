import { END_POINTS } from "../helpers";
import { Customers } from "../types/User";
import emptySplitApi from "../utils/rtk";

type GetUsersResponse = {
  statusCode: number;
  data: any;
};

type GetUsersParams = {
  size: number;
  page: number;
  query?: string | undefined;
};

type GetUserDataResponse = {
  statusCode: number;
  data?: Customers;
};

type PutBlockUserResponse = {
  statusCode: number;
  data?: Customers;
  message: string;
};

type PutBlockUserParams = {
  userId: string;
  body: { isBlocked?: boolean; isMessagingBlocked?: boolean };
};

type GetUserCsvResponse = {
  statusCode: number;
  code?: number;
  data?: any;
};

type PutAddTokenParams = {
  userId: string | undefined;
  body: {
    tokens?: number;
    receivedBy?: string;
    status?: string;
    // [key: string]: any;
  };
};

type RemoveTokensResponse = {
  statusCode?: number;
  message: string;
};
type GetDashboardResponse = {
  statusCode?: number;
  data?: any;
};

type GetDashboardParams = {
  type: string;
  type2: string;
};

type UserPredictionParams = {
  size: number;
  page: number;
  query?: string | undefined;
  userId: string | undefined;
  tournamentId?: string | undefined;
  userName?: string;
  startDate?: string;
  endDate: string;
};

export const authApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getUsers: builder.query<GetUsersResponse, GetUsersParams>({
      query: ({ size, page, query }) => ({
        url:
          END_POINTS.getUsers +
          "?size=" +
          size +
          "&page=" +
          page +
          "&search=" +
          query,
        method: "GET",
      }),
    }),
    getUserData: builder.query<GetUserDataResponse, string | undefined>({
      query: (userId) => ({
        url: `${END_POINTS.getUsers}/${userId}/`,
        method: "GET",
      }),
    }),
    putUpdateUser: builder.mutation<{ statusCode: number }, {}>({
      query: ({ body, userId }: any) => ({
        url: `${END_POINTS.getUsers}/${userId}/`,
        method: "PUT",
        body,
      }),
    }),
    putBlockUser: builder.mutation<PutBlockUserResponse, PutBlockUserParams>({
      query: ({ body, userId }) => ({
        url: `${END_POINTS.getUsers}/${userId}`,
        method: "PUT",
        body,
      }),
    }),
    deleteUser: builder.mutation<{ statusCode: number }, { userId: string }>({
      query: ({ userId }) => ({
        url: `${END_POINTS.getUsers}/${userId}`,
        method: "DELETE",
      }),
    }),
    getUserCsv: builder.query<GetUserCsvResponse, {}>({
      query: () => ({
        url: `${END_POINTS.getUserCsv}`,
        method: "GET",
      }),
    }),
    putAddToken: builder.mutation<PutBlockUserResponse, PutAddTokenParams>({
      query: ({ body, userId }) => ({
        url: `${END_POINTS.getUsers}/${userId}`,
        method: "PUT",
        body,
      }),
    }),
    getDashboard: builder.query<GetDashboardResponse, GetDashboardParams>({
      query: ({ type, type2 }) => ({
        url: END_POINTS.dashboard + "?type=" + type + "&type2=" + type2,
        method: "GET",
      }),
    }),

    getUserPredictions: builder.query<
      GetUserDataResponse,
      UserPredictionParams
    >({
      query: ({ userId, size, page, query }) => {
        const encodedQuery = encodeURIComponent(query || "");
        const url = `${END_POINTS.userPredictions}/${userId}?size=${size}&page=${page}&search=${encodedQuery}`;

        return {
          url: url,
          method: "GET",
        };
      },
    }),
    userTournament: builder.query<GetUserDataResponse, UserPredictionParams>({
      query: ({ userId, size, page, query, startDate, endDate }) => {
        const encodedQuery = encodeURIComponent(query || "");
        const url = `${END_POINTS.userTournament}/${userId}?size=${size}&page=${page}&startDate=${startDate}&endDate=${endDate}`;

        return {
          url: url,
          method: "GET",
        };
      },
    }),
    userTournamentDrop: builder.query<
      GetUserDataResponse,
      UserPredictionParams
    >({
      query: ({ userId, tournamentId }) => {
        const url = `${END_POINTS.userName}?userId=${userId}&tournamentId=${tournamentId}`;

        return {
          url: url,
          method: "GET",
        };
      },
    }),
    userNamePredictions: builder.query<
      GetUserDataResponse,
      UserPredictionParams
    >({
      query: ({ userId, tournamentId, userName }) => {
        const url = `${END_POINTS.userNamePredictions}?userId=${userId}&tournamentId=${tournamentId}&userName=${userName}`;

        return {
          url: url,
          method: "GET",
        };
      },
    }),
    getUpcomingTournaments: builder.query<GetUsersResponse, GetUsersParams>({
      query: ({ size, page, query }) => ({
        url:
          END_POINTS.upcomingTournament +
          "?size=" +
          size +
          "&page=" +
          page +
          "&search=" +
          query,
        method: "GET",
      }),
    }),
    postRemoveToken: builder.mutation<
      RemoveTokensResponse,
      { body: { tokens: number; userId: string } }
    >({
      query: ({ body }) => ({
        url: `${END_POINTS.removeToken}`,
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useLazyGetUsersQuery,
  useLazyGetUserDataQuery,
  usePutUpdateUserMutation,
  usePutBlockUserMutation,
  useDeleteUserMutation,
  useLazyGetUserCsvQuery,
  usePutAddTokenMutation,
  useLazyGetDashboardQuery,
  useLazyGetUserPredictionsQuery,
  useLazyGetUpcomingTournamentsQuery,
  useLazyUserTournamentQuery,
  useLazyUserTournamentDropQuery,
  useLazyUserNamePredictionsQuery,
  usePostRemoveTokenMutation,
} = authApi;
