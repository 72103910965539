import AuthWrapper from "../../../layout/authWrapper";
import "../login/login.css";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Card, Form, Input } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { usePostResetPasswordMutation } from "../../../services/auth";
import { errorToast, successToast } from "../../../helpers";

const ResetPassword = () => {
  const navigate = useNavigate();

  const { id } = useParams();

  const [postResetPassword, PostRestPasswordData] =
    usePostResetPasswordMutation();

  const onFinish = async (values: any) => {
    const body = {
      password: values.password,
    };

    try {
      const res = await postResetPassword({
        body,
        rendonId: id,
      } as any).unwrap();

      if (res?.statusCode === 200) {
        successToast(res?.message);
        navigate("/", { replace: true });
      } else {
        errorToast(res?.message);
      }
    } catch (error: any) {
      errorToast(error?.data?.message || error?.data?.error);
    }
  };

  const validateConfirmPassword = ({
    getFieldValue,
  }: {
    getFieldValue: any;
  }) => ({
    validator(_: any, value: any) {
      if (!value || getFieldValue("password") === value) {
        return Promise.resolve();
      }
      return Promise.reject(new Error("Passwords do not match."));
    },
  });

  return (
    <AuthWrapper>
      <Card className="login_card">
        <div onClick={() => navigate(-1)} style={{ display: "flex" }}>
          <ArrowLeftOutlined
            style={{ fontSize: 20, cursor: "pointer", color: "#ffff" }}
          />
        </div>
        <div className="login_img">
          <img src="/static/images/logo.png" alt="" />
        </div>

        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          style={{
            maxWidth: 460,
            marginTop: 40,
          }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          //   onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            style={{ width: 550 }}
            name="password"
            rules={[
              { required: true, message: "Please Enter password!" },
              {
                pattern:
                  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                message:
                  "Must contain 8 or more characters, one uppercase, one lowercase, one special character and one number.",
              },
            ]}
          >
            <Input.Password
              style={{ width: "460px", height: "50px" }}
              placeholder="New Password"
            />
          </Form.Item>
          <Form.Item
            name="confirmPassword"
            dependencies={["password"]}
            rules={[
              {
                required: true,
                message: "Please confirm your password!",
              },
              validateConfirmPassword,
            ]}
          >
            <Input.Password
              style={{ width: "460px", height: "50px", marginTop: 20 }}
              placeholder="Confirm New Password"
            />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
            <div style={{ display: "flex" }}>
              <Button
                style={{
                  background: "#ECAC35",
                  width: 200,
                  height: 50,
                  margin: "auto",
                  color: "black",
                  marginTop: 10,
                  fontWeight: 600,
                }}
                type="primary"
                htmlType="submit"
                loading={PostRestPasswordData.isLoading}
              >
                Save
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Card>
    </AuthWrapper>
  );
};

export default ResetPassword;
