import React, { useEffect, useState } from "react";
import { ContentHeader } from "../../components";

import {
  Button,
  Card,
  Col,
  Input,
  Row,
  Table,
  Select,
  Form,
  DatePicker,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import { usePutAddTokenMutation } from "../../services/users";
import { errorToast, successToast } from "../../helpers";
import {
  useLazyGetReceiverListQuery,
  useLazyGetTokenAddListQuery,
  useLazyGetUsersListQuery,
  useSettlementMutation,
} from "../../services/tournaments";
import { useNavigate } from "react-router-dom";
import dayjs, { Dayjs } from "dayjs";

import { EventValue, RangeValue } from "rc-picker/lib/interface";

const { Option } = Select;

type Types = {
  userName: string;
  firstName: string;
  _id: string;
};
interface DataType {
  _id: string;
  userId: {
    firstName: string;
    userName: string;
  };
  status: string;
  tokens: number;
  totalAmount: number;
  paymentMethod: number;
  pendingAmount: number;
  paidAmount: number;
  settlementAmount: number;
  transactionType: string;
  receivedBy: {
    name: string;
    createdAt: string;
  };
  createdAt: string;
}

const AddTokens = () => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [userList, userListData] = useLazyGetUsersListQuery();
  const [form] = Form.useForm();
  const [query, setQuery] = useState("");
  const [debouncedQuery, setDebouncedQuery] = useState("");
  const [addTokenList, setAddTokenList] = useState<any[]>();
  const [selectedUser, setSelectedUser] = useState<string>();
  const [selectedPaymentStatus, setSelectedPaymentStatus] = useState<string>();
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<string>();
  const [settlementAmount, setSettlementAmount] = useState<number>();
  const [selectedTransactionType, setSelectedTransactionType] =
    useState<string>();
  const [selectedReceiver, setSelectedReceiver] = useState<string>();
  const [userData, setUserData] = useState<Types[]>();
  const [receiverData, setReceiverData] = useState<any[]>();
  const [addToken, addTokenData] = usePutAddTokenMutation();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalCount, setTotalCount] = useState<number | undefined>();
  const [tokenInput, setTokenInput] = useState<number>();
  const [totalAmount, setTotalAmount] = useState<number>(0);
  const [pendingAmount, setPendingAmount] = useState<number>();
  const [paidAmount, setPaidAmount] = useState<number>();
  const [getTokenDetails, getTokenDetailsData] = useLazyGetTokenAddListQuery();
  const [receiverList, receiverListData] = useLazyGetReceiverListQuery();
  const [updateSettlement, updateSettlementData] = useSettlementMutation();

  // search states

  const [searchTransaction, setSearchTransaction] = useState<string>();
  const [searchPaymentStatus, setSearchPaymentStatus] = useState<string>();
  const [searchPaymentMethod, setSearchPaymentMethod] = useState<string>();
  const [searchReceiver, setSearchReceiver] = useState<string>();
  const [searchUser, setSearchUser] = useState<string>();
  const [rowNumber, setRowNumber] = useState(0);

  const [selectedDates, setSelectedDates] = useState<
    RangeValue<Dayjs>[] | null
  >([]);
  const handleDateChange = (dates: RangeValue<Dayjs>[]) => {
    setSelectedDates(dates);
  };

  //

  const [totalValues, setTotalValues] = useState({
    numberOfTokens: 0,
    paidAmount: 0,
    pendingAmount: 0,
    settledAmount: 0,
  });

  const navigate = useNavigate();

  useEffect(() => {
    form.setFieldsValue({
      pendingAmount: pendingAmount,
      settlementField: settlementAmount,
      paymentstatus: selectedPaymentStatus,
      paidAmountField: paidAmount,
      addToken: selectedUser,
      tokens: tokenInput,
    });
    form.validateFields(["pendingAmount"]);
  }, [tokenInput, paidAmount, selectedPaymentStatus]);

  const transactionType = [
    { name: "Add Tokens" },
    { name: "Payment" },
    { name: "Settlement" },
  ];

  const paymentStatus = [
    { name: "Fully Paid" },
    { name: "UnPaid" },
    { name: "Partially Paid" },
  ];

  const paymentMethods = [
    { name: "Bank transfer" },
    { name: "Crypto" },
    { name: "Cash" },
    { name: "Paid to friend" },
  ];
  const pageSize = 10;

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    getAddTokenList(page);
    const newRowNumber = (page - 1) * pageSize;
    setRowNumber(newRowNumber);
  };

  const handleChange = (value: any) => {
    form.setFieldsValue({
      addToken: value,
    });
    setSelectedUser(value);
    form.validateFields(["addToken"]);
  };

  const handleChangeForPaymentStatus = (value: any) => {
    form.setFieldsValue({
      paymentstatus: value,
    });
    if (value === "UnPaid") {
      setPaidAmount(0);
      setSettlementAmount(undefined);
    }
    if (value === "Fully Paid") {
      setPaidAmount(tokenInput);

      setPendingAmount(undefined);
      setSettlementAmount(-`${tokenInput}` || undefined);
    }

    setSelectedPaymentStatus(value);
    form.validateFields(["paymentstatus"]);
  };

  const handleChangeForPaymentMethods = (value: any) => {
    form.setFieldsValue({
      paymentMethods: value,
    });
    setSelectedPaymentMethod(value);
    form.validateFields(["paymentMethods"]);
  };
  const handleChangeForTransactionMethods = (value: any) => {
    form.setFieldsValue({
      TransactionField: value,
    });
    setSelectedTransactionType(value);
    if (value === "Payment") {
      setSelectedPaymentStatus("Fully Paid");
      setTokenInput(undefined);
      setPendingAmount(undefined);
      setPaidAmount(undefined);
    } else {
      setSelectedPaymentStatus(undefined);
    }
    if (value === "Settlement") {
      setSelectedUser(undefined);
      setPaidAmount(undefined);
      setTokenInput(undefined);
      setPendingAmount(undefined);
    }

    form.validateFields(["TransactionField"]);
  };

  const handleChangeForSelectedReceiver = (value: any) => {
    form.setFieldsValue({
      selectedReceiver: value,
    });
    setSelectedReceiver(value);

    form.validateFields(["selectedReceiver"]);
  };

  const onFinish = async () => {
    if (
      (!selectedUser?.length && selectedTransactionType === "Payment") ||
      (!selectedUser?.length && selectedTransactionType === "Add Tokens")
    ) {
      errorToast("Please select a user");
    } else {
      let body = {
        tokens: tokenInput,
        totalAmount: totalAmount || 0,
        pendingAmount: pendingAmount || 0,
        status: selectedPaymentStatus || "",
        paymentMethod: selectedPaymentMethod || "",
        transactionType: selectedTransactionType || "",
        receivedBy: selectedReceiver || "",
        paidAmount: paidAmount || 0,
        settlementAmount: settlementAmount || 0,
      };

      let res;
      try {
        if (selectedUser) {
          res = await addToken({ body, userId: selectedUser }).unwrap();
        } else {
          res = await updateSettlement(body as any).unwrap();
        }

        if (res?.statusCode === 200) {
          setTokenInput(undefined);
          setTotalAmount(0);
          setPendingAmount(undefined);
          setSelectedPaymentMethod("");
          setSelectedPaymentStatus(undefined);
          setSelectedReceiver("");
          setPaidAmount(undefined);
          setPendingAmount(undefined);
          setSelectedTransactionType("");
          setSettlementAmount(undefined);
          setSelectedUser(undefined);
          form.resetFields();
          successToast("Tokens Added SuccessFully");
          getAddTokenList(1);
        }
      } catch (error: any) {
        console.log(error);
      }
    }
  };

  const calculateTotalValues = (data: DataType[]) => {
    const {
      settledAmountTotal,
      numberOfTokens,
      paidAmountTotal,
      pendingAmountTotal,
    } = data.reduce(
      (accumulator, currentObj) => {
        return {
          settledAmountTotal:
            accumulator.settledAmountTotal +
            (currentObj?.settlementAmount || 0),
          numberOfTokens:
            accumulator.numberOfTokens + (currentObj?.tokens || 0),
          paidAmountTotal:
            accumulator.paidAmountTotal + (currentObj?.paidAmount || 0),
          pendingAmountTotal:
            accumulator.pendingAmountTotal + (currentObj?.pendingAmount || 0),
        };
      },
      {
        settledAmountTotal: 0,
        numberOfTokens: 0,
        paidAmountTotal: 0,
        pendingAmountTotal: 0,
      }
    );
    return {
      numberOfTokens,
      paidAmountTotal,
      pendingAmountTotal,
      settledAmountTotal,
    };
  };

  const getAddTokenList = async (page: number) => {
    try {
      const res = await getTokenDetails({
        page: page,
        size: 10,
        query: "",
        searchParam: {
          transactionType: searchTransaction,
          payMethod: searchPaymentMethod,
          payStatus: searchPaymentStatus,
          receiver: searchReceiver,
          userName: searchUser,
          startDate: startDate,
          endDate: endDate,
        },
      }).unwrap();

      if (res?.statusCode === 200) {
        setAddTokenList(res?.data?.data);
        setTotalCount(res?.data?.count);
        if (searchUser || searchReceiver) {
          const {
            numberOfTokens,
            paidAmountTotal,
            pendingAmountTotal,
            settledAmountTotal,
          } = calculateTotalValues(res?.data?.data);

          setTotalValues({
            numberOfTokens,
            paidAmount: paidAmountTotal,
            pendingAmount: pendingAmountTotal,
            settledAmount: settledAmountTotal,
          });
        } else {
          setTotalValues({
            numberOfTokens: 0,
            paidAmount: 0,
            pendingAmount: 0,
            settledAmount: 0,
          });
        }
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  const getUserDetails = async () => {
    try {
      const res = await userList({}).unwrap();

      if (res?.statusCode === 200) {
        setUserData(res?.data?.data);
      }
    } catch (error: any) {
      console.log(error);
    }
  };
  const getReceiverDetails = async () => {
    try {
      const res = await receiverList({}).unwrap();

      if (res?.statusCode === 200) {
        setReceiverData(res?.data);
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  const pagination = {
    pageSize,
    current: currentPage,
    total: totalCount,
    onChange: handlePageChange,
  };

  useEffect(() => {
    getUserDetails();
    getReceiverDetails();
  }, []);

  useEffect(() => {
    getAddTokenList(1);
  }, [
    query,
    searchTransaction,
    searchPaymentStatus,
    searchPaymentMethod,
    searchReceiver,
    searchUser,
    selectedDates,
  ]);

  useEffect(() => {
    const delay = 1000; // Debounce delay in milliseconds
    const timerId = setTimeout(() => {
      setQuery(debouncedQuery);
    }, delay);

    return () => {
      clearTimeout(timerId); // Clear the timeout on cleanup
    };
  }, [debouncedQuery]);

  const handleChangeForTokenInput = (e: any) => {
    const input = Number(e.target.value);
    if (!isNaN(input)) {
      setTokenInput(input);
      const pending = input - (paidAmount ? paidAmount : 0);
      setPendingAmount(Math.max(0, pending));
    }
  };

  const handleChangeForPaidAmount = (e: any) => {
    const input = Number(e.target.value);
    if (!isNaN(input)) {
      setPaidAmount(input);
      const pending = (tokenInput ? tokenInput : 0) - input;
      setPendingAmount(Math.max(0, pending));
      if (
        selectedTransactionType === "Add Tokens" ||
        selectedTransactionType === "Payment"
      ) {
        setSettlementAmount(-input);
      } else {
        setSettlementAmount(0);
      }
    }
  };

  const totalData = {
    _id: "",
    userId: {
      firstName: "",
      userName: "",
    },
    status: "",
    tokens: totalValues?.numberOfTokens || 0,
    totalAmount: 0,
    paymentMethod: "",
    pendingAmount: totalValues?.numberOfTokens - totalValues?.paidAmount || 0,
    paidAmount: totalValues?.paidAmount || 0,
    settlementAmount: totalValues?.settledAmount || 0,
    transactionType: "Sub-Total",
    receivedBy: {
      name: "",
      createdAt: "",
    },
    createdAt: "",
  };
  useEffect(() => {
    if (
      totalValues?.numberOfTokens ||
      totalValues?.paidAmount ||
      totalValues?.pendingAmount ||
      totalValues?.settledAmount
    ) {
      // Ensure addTokenList is an array and create a copy of it
      const newAddTokenList = Array.isArray(addTokenList)
        ? addTokenList.slice()
        : [];

      // Add totalData to the beginning of the newAddTokenList
      newAddTokenList.unshift(totalData);

      // Update the state with the newAddTokenList
      setAddTokenList(newAddTokenList);
    }
  }, [totalValues]);

  const columns: ColumnsType<DataType> = [
    {
      title: "#",
      key: "rank",
      dataIndex: "rank",
      align: "center",
      width: "5%",
      render: (text, record, index) => {
        if (
          totalValues?.numberOfTokens ||
          totalValues?.paidAmount ||
          totalValues?.pendingAmount ||
          totalValues?.settledAmount
        ) {
          return `${rowNumber + index}`;
        } else {
          return `${rowNumber + index + 1}`;
        }
      },
    },

    {
      title: "Transaction Date",
      key: "rank",
      dataIndex: "rank",
      align: "center",
      className: "smaller-title",
      render: (_, record) => (
        <div>
          <span>
            {record?.createdAt
              ? new Date(record?.createdAt).toISOString().split("T")[0]
              : "-"}
          </span>
        </div>
      ),
    },
    {
      title: "Transaction Type",
      key: "transactionType",
      dataIndex: "transactionType",
      align: "center",
      className: "smaller-title",
      render: (_, record) => (
        <div>
          <span>{`${record?.transactionType || "-"}`}</span>
        </div>
      ),
    },
    {
      title: "Username",
      dataIndex: "name",
      key: "name",
      align: "center",
      className: "smaller-title",

      render: (_, record) => (
        <div>
          <span>{`${record?.userId?.userName || "-"}`}</span>
        </div>
      ),
    },
    {
      title: "Number Of Tokens",
      dataIndex: "tokens",
      key: "tokens",
      align: "center",
      className: "smaller-title",

      render: (_, record) => (
        <div>
          <span>{`${record?.tokens || "-"}`}</span>
        </div>
      ),
    },

    {
      title: "Payment Status",
      dataIndex: "paymentStatus",
      key: "paymentStatus",
      align: "center",
      className: "smaller-title",

      render: (_, record) => (
        <div>
          <span>{`${record?.status || "-"}`}</span>
        </div>
      ),
    },

    {
      title: "Paid Amount",
      dataIndex: "pendingAmount",
      key: "pendingAmount",
      align: "center",
      className: "smaller-title",

      render: (_, record) => (
        <div>
          <span>{`${record?.paidAmount || "-"}`}</span>
        </div>
      ),
    },

    {
      title: "Pending Amount",
      dataIndex: "pendingAmount",
      key: "pendingAmount",
      align: "center",
      className: "smaller-title",

      render: (_, record) => (
        <div>
          {record?.pendingAmount < 0 ? (
            <span>{`${record?.pendingAmount || ""}`}</span>
          ) : (
            <span>{`-${record?.pendingAmount || ""}`}</span>
          )}
        </div>
      ),
    },

    {
      title: "Payment Method",
      dataIndex: "paymentMethod",
      key: "paymentMethod",
      align: "center",
      className: "smaller-title",

      render: (_, record) => (
        <div>
          <span>{`${record?.paymentMethod || "-"}`}</span>
        </div>
      ),
    },

    {
      title: "Payment Receivers",
      dataIndex: "paymentReceivers",
      key: "paymentReceivers",
      align: "center",
      className: "smaller-title",

      render: (_, record) => (
        <div>
          <span>{`${record?.receivedBy?.name || "-"}`}</span>
        </div>
      ),
    },
    {
      title: "Settled Amount",
      dataIndex: "totalAmount",
      key: "totalAmount",
      align: "center",
      className: "smaller-title",

      render: (_, record) => (
        <div>
          <span>{`${record?.settlementAmount || "-"}`}</span>
        </div>
      ),
    },
  ];

  const validatePaidAmount = (_: any, value: number) => {
    if (value > (tokenInput as any) && selectedTransactionType !== "Payment") {
      return Promise.reject("Paid amount must not be greater number of tokens");
    }
    return Promise.resolve();
  };

  // for search //

  const searchForTransactionMethods = (value: any) => {
    setSearchTransaction(value);
  };
  const searchForPaymentStatus = (value: any) => {
    setSearchPaymentStatus(value);
  };
  const searchForPaymentMethod = (value: any) => {
    setSearchPaymentMethod(value);
  };
  const searchForSelectedReceiver = (value: any) => {
    setSearchReceiver(value);
  };
  const searchForSelectedUser = (value: any) => {
    setSearchUser(value);
  };

  //

  return (
    <div style={{ padding: 40 }}>
      <ContentHeader heading="Add Tokens" />
      <Card>
        <Form form={form} onFinish={onFinish}>
          <Row gutter={16}>
            <Col xs={24} md={6}>
              <Form.Item
                name="TransactionField"
                rules={[
                  {
                    required: true,
                    message: "Please select a transaction type",
                  },
                ]}
              >
                <Select
                  allowClear
                  maxLength={20}
                  className="select_type"
                  onChange={(val) => handleChangeForTransactionMethods(val)}
                  value={selectedTransactionType}
                  style={{ height: 75, width: "100%" }}
                  placeholder="Transaction Type"
                >
                  {transactionType?.map((item, index) => (
                    <Option key={index} value={item?.name} label={item?.name}>
                      {item?.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} md={6}>
              <Form.Item name="addToken">
                <Select
                  allowClear
                  disabled={selectedTransactionType === "Settlement"}
                  maxLength={20}
                  className="select_type"
                  onChange={(val) => handleChange(val)}
                  value={selectedUser}
                  style={{ height: 75, width: "100%" }}
                  placeholder="Select User"
                  showSearch
                  optionFilterProp="label" // Set the property to use for filtering options
                  filterOption={(input, option) =>
                    (option?.label?.toString()?.toLowerCase() || "").indexOf(
                      input.toLowerCase()
                    ) !== -1
                  }
                >
                  {userData?.map((item, index) => (
                    <Option
                      key={index}
                      value={item?._id}
                      label={item?.userName}
                    >
                      {item?.userName}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} md={6}>
              <Form.Item
                name="tokens"
                rules={[
                  {
                    pattern: /^[0-9]*$/,
                    message: "Please enter only numeric values",
                  },
                ]}
              >
                <Input
                  value={tokenInput}
                  disabled={selectedTransactionType !== "Add Tokens"}
                  type="tel"
                  placeholder="Number Of Tokens"
                  style={{ height: 43 }}
                  onChange={handleChangeForTokenInput}
                  maxLength={4}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={6}>
              <Form.Item name="paymentstatus">
                <Select
                  allowClear
                  disabled={
                    selectedTransactionType === "Settlement" ||
                    selectedTransactionType === "Payment"
                  }
                  maxLength={20}
                  className="select_type"
                  onChange={(val) => handleChangeForPaymentStatus(val)}
                  value={selectedPaymentStatus}
                  style={{
                    color: "black",
                    opacity: 1,
                    height: 43,
                  }}
                  placeholder="Payment Status"
                >
                  {paymentStatus?.map((item, index) => (
                    <Option key={index} value={item?.name} label={item?.name}>
                      {item?.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col xs={24} md={6}>
              <Form.Item
                name="paidAmountField"
                rules={[
                  {
                    pattern: /^[0-9]*$/,
                    message: "Please enter only numeric values",
                  },

                  {
                    validator: validatePaidAmount,
                  },
                ]}
              >
                <Input
                  disabled={
                    selectedTransactionType === "Settlement" ||
                    selectedPaymentStatus === "UnPaid" ||
                    (selectedPaymentStatus === "Fully Paid" &&
                      selectedTransactionType !== "Payment")
                  }
                  value={paidAmount}
                  type="tel"
                  placeholder="Paid Amount"
                  style={{ height: 43 }}
                  onChange={handleChangeForPaidAmount}
                  maxLength={10}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={6}>
              <Form.Item
                name="pendingAmount"
                rules={[
                  {
                    pattern: /^[0-9]*$/,
                    message: "Please enter only numeric values",
                  },
                ]}
              >
                <Input
                  value={pendingAmount}
                  type="tel"
                  placeholder="Pending Amount"
                  style={{
                    backgroundColor: "#f5f5f5",
                    color: "#000",

                    opacity: 1,
                    height: 43,
                  }}
                  // onChange={() => handlePendingAmount()}
                  maxLength={10}
                  disabled
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={6}>
              <Form.Item
                name="paymentMethods"
                rules={[
                  {
                    required: true,
                    message: "Please select a payment method",
                  },
                ]}
              >
                <Select
                  allowClear
                  maxLength={20}
                  className="select_type"
                  onChange={(val) => handleChangeForPaymentMethods(val)}
                  value={selectedPaymentMethod}
                  style={{ height: 75, width: "100%" }}
                  placeholder="Payment Methods"
                >
                  {paymentMethods?.map((item, index) => (
                    <Option key={index} value={item?.name} label={item?.name}>
                      {item?.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} md={6}>
              <Form.Item
                name="selectedReceiver"
                rules={[
                  { required: true, message: "Please select a receiver" },
                ]}
              >
                <Select
                  allowClear
                  maxLength={20}
                  className="select_type"
                  onChange={(val) => handleChangeForSelectedReceiver(val)}
                  value={selectedReceiver}
                  style={{ height: 75, width: "100%" }}
                  placeholder="Payment Receivers"
                  showSearch
                  optionFilterProp="label" // Set the property to use for filtering options
                  filterOption={(input, option) =>
                    (option?.label?.toString()?.toLowerCase() || "").indexOf(
                      input.toLowerCase()
                    ) !== -1
                  }
                >
                  {receiverData?.map((item, index) => (
                    <Option key={index} value={item?._id} label={item?.name}>
                      {item?.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} md={6}>
              <Form.Item name="settlementField">
                <Input
                  disabled={selectedTransactionType !== "Settlement"}
                  value={settlementAmount}
                  type="tel"
                  placeholder="Settlement Amount"
                  style={{
                    color: "#000",
                    opacity: 1,
                    height: 43,
                  }}
                  onChange={(e) => {
                    const input = Number(e.target.value);
                    if (!isNaN(input)) {
                      setSettlementAmount(input);
                    }
                  }}
                  maxLength={10}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row style={{ width: "100%", justifyContent: "space-between" }}>
            <Col style={{}}>
              <Button
                style={{
                  background: "#ECAC35",
                  width: 150,
                  height: 40,
                  margin: "auto",
                  color: "black",
                }}
                onClick={() => {
                  navigate(`/addtokens/managereceivers`);
                }}
              >
                Manage Receivers
              </Button>
            </Col>

            <Row>
              <Col>
                <Form.Item style={{ display: "flex", flexDirection: "row" }}>
                  <Col style={{ right: 10 }}>
                    <Button
                      style={{
                        background: "#ECAC35",
                        width: 85,
                        height: 40,
                        margin: "auto",
                        color: "black",
                      }}
                      type="default"
                      htmlType="submit"
                      loading={
                        addTokenData.isLoading || updateSettlementData.isLoading
                      }
                    >
                      Submit
                    </Button>
                  </Col>
                </Form.Item>
              </Col>
            </Row>
          </Row>
        </Form>
      </Card>
      <Card style={{ marginTop: 20 }}>
        <Row style={{ padding: "10px 0px" }} gutter={16}>
          <Col xs={14} md={4}>
            <DatePicker.RangePicker
              disabledDate={(d) => !d || d.isAfter(new Date())}
              style={{ height: 43, width: "100%" }}
              placeholder={["Start date", "End date"]}
              format="YYYY-MM-DD"
              // value={dateRange}
              onChange={(val) => {
                handleDateChange(val as RangeValue<Dayjs>[]);
                if (val?.length) {
                  setStartDate(val[0]?.format("YYYY-MM-DD") || "");
                  setEndDate(val[1]?.format("YYYY-MM-DD") || "");
                } else {
                  setStartDate("");
                  setEndDate("");
                }
              }}
              value={selectedDates as [EventValue<Dayjs>, EventValue<Dayjs>]}
            />
          </Col>
          <Col xs={14} md={4}>
            <Select
              allowClear
              maxLength={20}
              className="select_type"
              onChange={(val) => searchForTransactionMethods(val)}
              value={searchTransaction}
              style={{ height: 75, width: "100%" }}
              placeholder="Transaction Type"
            >
              {transactionType?.map((item, index) => (
                <Option key={index} value={item?.name} label={item?.name}>
                  {item?.name}
                </Option>
              ))}
            </Select>
          </Col>

          <Col xs={14} md={4}>
            <Select
              allowClear
              maxLength={20}
              className="select_type"
              onChange={(val) => searchForSelectedUser(val)}
              value={searchUser}
              style={{ height: 75, width: "100%" }}
              placeholder="Select User"
              showSearch
              optionFilterProp="label" // Set the property to use for filtering options
              filterOption={(input, option) =>
                (option?.label?.toString()?.toLowerCase() || "").indexOf(
                  input.toLowerCase()
                ) !== -1
              }
            >
              {userData?.map((item, index) => (
                <Option
                  key={index}
                  value={item?.userName}
                  label={item?.userName}
                >
                  {item?.userName}
                </Option>
              ))}
            </Select>
          </Col>

          <Col xs={14} md={4}>
            <Select
              allowClear
              maxLength={20}
              className="select_type"
              onChange={(val) => searchForPaymentStatus(val)}
              value={searchPaymentStatus}
              style={{ height: 75, width: "100%" }}
              placeholder="Payment Status"
            >
              {paymentStatus?.map((item, index) => (
                <Option key={index} value={item?.name} label={item?.name}>
                  {item?.name}
                </Option>
              ))}
            </Select>
          </Col>
          <Col xs={14} md={4}>
            <Select
              allowClear
              maxLength={20}
              className="select_type"
              onChange={(val) => searchForPaymentMethod(val)}
              value={searchPaymentMethod}
              style={{ height: 75, width: "100%" }}
              placeholder="Payment Method"
            >
              {paymentMethods?.map((item, index) => (
                <Option key={index} value={item?.name} label={item?.name}>
                  {item?.name}
                </Option>
              ))}
            </Select>
          </Col>
          <Col xs={14} md={4}>
            <Select
              allowClear
              maxLength={20}
              className="select_type"
              onChange={(val) => searchForSelectedReceiver(val)}
              value={searchReceiver}
              style={{
                height: 75,
                width: "100%",
              }}
              placeholder="Select Receiver"
              showSearch
              optionFilterProp="label" // Set the property to use for filtering options
              filterOption={(input, option) =>
                (option?.label?.toString()?.toLowerCase() || "").indexOf(
                  input.toLowerCase()
                ) !== -1
              }
            >
              {receiverData?.map((item, index) => (
                <Option key={index} value={item?.name} label={item?.name}>
                  {item?.name}
                </Option>
              ))}
            </Select>
          </Col>
        </Row>
        <Table
          style={{ marginTop: 10 }}
          className="tableSelector"
          columns={columns}
          dataSource={addTokenList}
          rowKey="_id"
          loading={getTokenDetailsData.isLoading}
          pagination={pagination}
        />
      </Card>
    </div>
  );
};

export default AddTokens;
