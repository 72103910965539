import { Card, Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { StarFilled } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { useLazyGetUserPredictionsQuery } from "../../services/users";

type PropTypes = {
  id: string | undefined;
};

const ReportsPredictionTable = ({ id }: PropTypes) => {
  const [totalCount, setTotalCount] = useState<number | undefined>();

  const [userPredictionsList, setUserPredictionsList] = useState<any[]>();
  const [getUserPredictions, getUserPredictionsData] =
    useLazyGetUserPredictionsQuery();
  const [currentPage, setCurrentPage] = useState<number>(1);

  interface DataType {
    homeTeam: string;
    awayTeam: string;
    homeTeam_goals: number | undefined;
    awayTeam_goals: number | undefined;
    key: string;
    isFavourite: boolean | undefined;
  }
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    getUserPredictionsHandler(page);
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "#",
      key: "rank",
      dataIndex: "rank",
      align: "center",
      width: "20%",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Teams",
      key: "rank",
      dataIndex: "rank",
      align: "center",
      width: "40%",

      render: (_, record) => (
        <div>
          <span style={{ fontWeight: 500 }}>
            {`${record?.homeTeam} / ${record?.awayTeam}` || "-"}
          </span>
        </div>
      ),
    },

    {
      title: "Predictions",
      dataIndex: "points",
      key: "points",
      align: "center",
      width: "40%",

      render: (_, record) => (
        <div>
          <span style={{ fontWeight: 600 }}>
            {`${record?.homeTeam_goals} : ${record?.awayTeam_goals}` || "-"}
          </span>
          {record?.isFavourite ? (
            <StarFilled
              style={{ color: "#ECAC35", fontSize: 16, marginLeft: 8 }}
            />
          ) : (
            ""
          )}
        </div>
      ),
    },
  ];

  const getUserPredictionsHandler = async (page: number) => {
    try {
      const res = await getUserPredictions({
        userId: id || "",
        size: 10,
        page: page,
        endDate: "",
      }).unwrap();

      if (res?.statusCode === 200) {
        setUserPredictionsList(res?.data?.data);
        setTotalCount(res?.data?.count);
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  const pagination = {
    pageSize: 10,
    current: currentPage,
    total: totalCount,
    onChange: handlePageChange,
  };

  useEffect(() => {
    getUserPredictionsHandler(1);
  }, []);

  return (
    <div>
      <Card>
        <div>
          <div>
            <Table
              className="tableSelector"
              style={{ fontSize: 10 }}
              columns={columns}
              dataSource={userPredictionsList}
              pagination={pagination}
              loading={getUserPredictionsData.isLoading}
            />
          </div>
        </div>
      </Card>
    </div>
  );
};

export default ReportsPredictionTable;
