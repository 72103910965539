import React, { useEffect, useState } from "react";
import { Card, Select } from "antd";
import { LineChart } from ".";

const UsersChart = (props: any) => {
  const [roundOff, setRoundOff] = useState(100);

  const handleFilterChange = (value: string) => {
    props.onFilterChange(value);
    props.setName("right");
  };

  useEffect(() => {
    if (props?.details?.users) {
      const maxMonthData = props?.details?.users.reduce(
        (max: any, month: any) => (month.total > max.total ? month : max),
        props?.details?.users[0]
      );

      if (Math.round(maxMonthData.total / 100) * 100 > 100) {
        setRoundOff(Math.round(maxMonthData.total / 100) * 100);
      } else {
        setRoundOff(100);
      }
    }
  }, [props]);

  return (
    <div style={{ padding: 0 }}>
      <Card className="chart_card">
        <div
          style={{
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <h2>Total Users/Players</h2>
          <div style={{ width: "20%" }}>
            <Select
              style={{ width: "100%" }}
              defaultValue={"MONTH"}
              onChange={handleFilterChange}
              placeholder="Filter"
              options={[
                { value: "DAILY", label: "Daily" },
                { value: "WEEK", label: "Weekly" },
                { value: "MONTH", label: "Monthly" },
                { value: "YEAR", label: "Yearly" },
              ]}
            />
          </div>
        </div>
        <div style={{ margin: "auto" }} className="graph_ch">
          <LineChart data={props.graphCustomerData} roundOff={roundOff} />
        </div>
      </Card>
    </div>
  );
};

export default UsersChart;
