import { Button, Card, Col, Row, Spin } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import PlayerPredictionTable from "../../../components/tables/PlayerPredictionTable";
import { useEffect, useState } from "react";
import { Customers } from "../../../types/User";
import { useLazyGetUserDataQuery } from "../../../services/users";
import { PlayerTournamentDetailsTable } from "../../../components/tables";

const UserDetails = () => {
  const navigate = useNavigate();
  const { id, view } = useParams();
  const [userDetail, setUserDetail] = useState<Customers>();
  const [getUser, getUserData] = useLazyGetUserDataQuery();

  const getUserDetails = async () => {
    try {
      const res = await getUser(id).unwrap();

      if (res?.statusCode === 200) {
        setUserDetail(res?.data);
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUserDetails();
  }, []);

  return (
    <div style={{ padding: 50, gap: 20, display: "grid" }}>
      <Card>
        <Spin spinning={getUserData.isLoading}>
          <div
            style={{
              alignItems: "center",
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            <h2>User details</h2>
            <Button
              style={{
                background: "#ECAC35",
                width: 100,
                height: 40,
                color: "black",
              }}
              type="primary"
              // htmlType="submit"
              onClick={() => {
                if (view === "user") {
                  navigate("/manageUsers", { replace: true });
                } else {
                  navigate("/reports", { replace: true });
                }
              }}
            >
              Back
            </Button>
          </div>

          <div>
            <Row gutter={16}>
              <Col xs={24} md={12}>
                <h4>User image</h4>
                <div
                  style={
                    {
                      // display: "flex",
                    }
                  }
                >
                  <img
                    style={{
                      height: 100,
                      width: 100,
                      borderRadius: "50%",
                      marginTop: 10,
                    }}
                    src={userDetail?.image || "/static/images/dummy.png"}
                    alt=""
                  />
                </div>
              </Col>
            </Row>

            <Row
              gutter={20}
              style={{
                justifyContent: "space-between",
              }}
            >
              <Row
                style={{
                  width: userDetail?.phoneHistory?.length ? "70%" : "100%",
                }}
              >
                <Col xs={14} md={6}>
                  <h4>Full Name</h4>
                  <h5>
                    {userDetail?.firstName || "-"} {userDetail?.lastName || " "}
                  </h5>
                </Col>
                <Col xs={14} md={6}>
                  <h4>Username</h4>
                  <h5>{userDetail?.userName || "-"}</h5>
                </Col>
                <Col xs={14} md={6}>
                  <h4>Email</h4>
                  <h5>{userDetail?.email || "-"}</h5>
                </Col>
                <Col xs={14} md={6}>
                  <h4>Tokens</h4>
                  <h5>{userDetail?.tokens || "0"}</h5>
                </Col>
                <Col xs={14} md={6}>
                  <h4>
                    {userDetail?.phoneHistory?.length ? "Current" : " "} Phone
                    No.
                  </h4>
                  <h5>
                    {userDetail?.countryCode || " "} {userDetail?.phone || " "}
                  </h5>
                </Col>
                <Col xs={14} md={6}>
                  <h4>Favorite Team</h4>
                  <h5>{userDetail?.favouriteTeam?.name || "-"}</h5>
                </Col>
              </Row>
              {userDetail?.phoneHistory?.length ? (
                <Row style={{ width: "30%" }}>
                  <Col xs={24} md={14}>
                    <h4>Old Phone Numbers</h4>
                    {userDetail?.phoneHistory?.map((item) => {
                      return (
                        <div style={{ width: "100%" }}>
                          <h5>
                            {item?.countryCode || "-"} {item?.phone || "-"}
                          </h5>
                        </div>
                      );
                    })}
                  </Col>
                </Row>
              ) : (
                <></>
              )}
            </Row>

            {/* <Row gutter={12}>
              <Col xs={14} md={6}>
                <h4>Tokens</h4>
                <h5>{userDetail?.tokens || "0"}</h5>
              </Col>
              <Col xs={14} md={6}>
                <h4>Phone Number</h4>
                <h5>
                  {userDetail?.countryCode || " "} {userDetail?.phone || " "}
                </h5>
              </Col>
              <Col xs={14} md={6}>
                <h4>Favorite Team</h4>
                <h5>{userDetail?.favouriteTeam?.name || "-"}</h5>
              </Col>
            </Row> */}
          </div>
        </Spin>
      </Card>

      <div>
        {/* <PlayerPredictionTable id={id} /> */}
        <PlayerTournamentDetailsTable id={id} />
      </div>
    </div>
  );
};

export default UserDetails;
