import { useState } from "react";
import { Button, Card, Col, Form, Input, Row } from "antd";
import { ContentHeader } from "../../components";
import { usePostAddNotificationMutation } from "../../services/addNotifications";
import { successToast } from "../../helpers";
import { useNavigate } from "react-router-dom";

const Notifications = () => {
  const [title, setTitle] = useState("");
  // const navigate = useNavigate();
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const [addNotification, addNotificationData] =
    usePostAddNotificationMutation();
  const { TextArea } = Input;

  const onFinish = async () => {
    let body = {
      title: title,
      message: message,
    };
    try {
      const res = await addNotification(body).unwrap();

      if (res?.statusCode === 200) {
        successToast("Notification sent successfully");

        setTitle("");
        setMessage("");
        navigate("history");
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  return (
    <div style={{ padding: 40 }}>
      <ContentHeader
        heading="Notifications"
        buttonText="View History"
        buttonNavigateTo="notifications/history"
      />
      <Card>
        <Form
          name="basic"
          style={{
            maxWidth: "80%",
          }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Row gutter={16}>
            <Col xs={24} md={12}>
              <Form.Item
                name="title"
                rules={[
                  {
                    required: true,
                    message: "Please input Title!",
                    whitespace: true,
                  },
                ]}
              >
                <div>
                  <h4>Title</h4>
                  <Input
                    placeholder="Title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    style={{ height: 55 }}
                    maxLength={50}
                  />
                </div>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} md={12}>
              <Form.Item
                name="description"
                rules={[
                  {
                    required: true,
                    message: "Please input Description!",
                    whitespace: true,
                  },
                ]}
              >
                <div>
                  <h4>Description</h4>
                  <TextArea
                    rows={8}
                    placeholder="Description"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  />
                </div>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item wrapperCol={{}}>
            <Button
              loading={addNotificationData?.isLoading}
              style={{
                background: "#ECAC35",
                width: 100,
                height: 40,
                marginTop: 20,
                color: "black",
              }}
              type="primary"
              htmlType="submit"
            >
              Send
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default Notifications;
