import { Card, Select, Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { StarFilled } from "@ant-design/icons";
import { useEffect, useState } from "react";
import {
  useLazyUserNamePredictionsQuery,
  useLazyUserTournamentDropQuery,
} from "../../services/users";
import moment from "moment";

type PropTypes = {
  id: string | undefined;
  view: string | undefined;
};

const PlayerPredictionTable = ({ id, view }: PropTypes) => {
  const { Option } = Select;
  const [userName, seUserName] = useState<string>();
  const [totalCount, setTotalCount] = useState<number | undefined>();
  const [getUserPredictionsDrop, getUserPredictionsDropData] =
    useLazyUserTournamentDropQuery();
  const [userPredictions, setUserPredictions] = useState<any[]>();
  const [userPredictionsList, setUserPredictionsList] = useState<any[]>();
  const [getUserPredictions, getUserPredictionsData] =
    useLazyUserNamePredictionsQuery();
  const [currentPage, setCurrentPage] = useState<number>(1);

  interface DataType {
    homeTeam: string;
    awayTeam: string;
    homeTeam_goals: number | undefined;
    awayTeam_goals: number | undefined;
    key: string;
    isFavourite: boolean | undefined;
    fixtureId: { fixture: { date: string } };
    category: {
      name: string;
    };
  }
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    getUserPredictionsHandler(page);
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "#",
      key: "rank",
      dataIndex: "rank",
      align: "center",
      width: "20%",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Teams",
      key: "rank",
      dataIndex: "rank",
      align: "center",

      render: (_, record) => (
        <div>
          <span style={{ fontWeight: 500 }}>
            {`${record?.homeTeam} / ${record?.awayTeam}` || "-"}
          </span>
        </div>
      ),
    },
    {
      title: "Token Categories",
      key: "rank",
      dataIndex: "rank",
      align: "center",

      render: (_, record) => (
        <div>
          <span style={{ fontWeight: 500 }}>
            {`${record?.category?.name}` || "-"}
          </span>
        </div>
      ),
    },
    {
      title: "Fixture Date & Time",
      dataIndex: "date",
      key: "date",
      align: "center",
      render: (_, record) => (
        <div>
          <span>{`${
            moment(record?.fixtureId?.fixture.date).format("YYYY-MM-DD") || "-"
          }`}</span>{" "}
          {","}{" "}
          <span>{`${
            moment(record?.fixtureId?.fixture.date).format("HH:mm") || "N/A"
          }`}</span>
        </div>
      ),
    },

    {
      title: "Predictions",
      dataIndex: "points",
      key: "points",
      align: "center",

      render: (_, record) => (
        <div>
          <span style={{ fontWeight: 600 }}>
            {`${record?.homeTeam_goals} : ${record?.awayTeam_goals}` || "-"}
          </span>
          {record?.isFavourite ? (
            <StarFilled
              style={{ color: "#ECAC35", fontSize: 16, marginLeft: 8 }}
            />
          ) : (
            ""
          )}
        </div>
      ),
    },
  ];

  const getUserPredictionsHandler = async (page: number) => {
    try {
      const res = await getUserPredictionsDrop({
        userId: id || "",
        tournamentId: view || "",
        size: 0,
        page: 0,
        endDate: "",
      }).unwrap();

      if (res?.statusCode === 200) {
        setUserPredictions(res?.data as any);
        setTotalCount(res?.data?.count);
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  const userPrediction = async () => {
    try {
      const res = await getUserPredictions({
        userId: id || "",
        tournamentId: view || "",
        userName: userName || "",
        size: 0,
        page: 0,
        endDate: "",
      }).unwrap();

      if (res?.statusCode === 200) {
        setUserPredictionsList(res?.data as any);
        setTotalCount(res?.data?.count);
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  const pagination = {
    pageSize: 10,
    current: currentPage,
    total: totalCount,
    onChange: handlePageChange,
  };

  useEffect(() => {
    getUserPredictionsHandler(1);
  }, []);
  useEffect(() => {
    userPrediction();
  }, [userName]);

  const searchForSelectedReceiver = (value: any) => {
    seUserName(value);
  };

  return (
    <div>
      <Card>
        <div style={{ paddingBottom: 15 }}>
          <span style={{ fontSize: 16, fontWeight: 500 }}>
            Please select a username to see the prediction list
          </span>
          <span style={{ fontSize: 16, fontWeight: 500, color: "red" }}>*</span>
        </div>
        <div style={{ width: "20%", marginBottom: 20 }}>
          <Select
            allowClear
            maxLength={20}
            className="select_type"
            onChange={(val) => searchForSelectedReceiver(val)}
            value={userName}
            style={{
              height: 75,
              width: "100%",
            }}
            placeholder="Select UserName"
            showSearch
            optionFilterProp="label"
            filterOption={(input, option) =>
              (option?.label?.toString()?.toLowerCase() || "").indexOf(
                input.toLowerCase()
              ) !== -1
            }
          >
            {userPredictions?.map((item, index) => (
              <Option key={index} value={item?.userName} label={item?.userName}>
                {item?.userName}
              </Option>
            ))}
          </Select>
        </div>
        <div>
          <div>
            <Table
              className="tableSelector"
              style={{ fontSize: 10 }}
              columns={columns}
              dataSource={userPredictionsList}
              // pagination={pagination}
              loading={getUserPredictionsData.isLoading}
            />
          </div>
        </div>
      </Card>
    </div>
  );
};

export default PlayerPredictionTable;
