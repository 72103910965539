import { END_POINTS } from "../helpers";
import emptySplitApi from "../utils/rtk";

type GetBannersResponse = {
  statusCode: number;
  data: any;
};

type GetBannersParams = {
  size: number;
  page: number;
  query?: string | undefined;
};

type GetBannerDataResponse = {
  statusCode: number;
  data?: any;
};

type UpdateBannerResponse = {
  statusCode: number;
  data?: any;
};

type DeleteBannersResponse = {
  statusCode: number;
};
type PutBlockUserBody = {
  isBlocked: boolean;
};

type PutUpdateBannerParams = {
  bannerId: string | undefined;
  body: any;
};
type AddBannersBody = {};
type CommonResponse = {
  statusCode: number;
};

type PutBlockCategoryResponse = {
  statusCode: number;
};

type PutBlockCategoryParams = {
  id: string;
  body: { isActive: boolean };
};

export const authApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getBanners: builder.query<GetBannersResponse, GetBannersParams>({
      query: ({ size, page, query }) => ({
        url:
          END_POINTS.banners +
          "?size=" +
          size +
          "&page=" +
          page +
          "&search=" +
          query,
        method: "GET",
      }),
    }),
    postAddBanners: builder.mutation<CommonResponse, AddBannersBody>({
      query: (body) => ({
        url: END_POINTS.banners,
        method: "POST",
        body,
      }),
    }),
    getBannerData: builder.query<GetBannerDataResponse, string | undefined>({
      query: (bannerId) => ({
        url: `${END_POINTS.banners}/${bannerId}/`,
        method: "GET",
      }),
    }),
    putUpdateBanner: builder.mutation<
      UpdateBannerResponse,
      PutUpdateBannerParams
    >({
      query: ({ body, bannerId }: any) => ({
        url: `${END_POINTS.banners}/${bannerId}/`,
        method: "PUT",
        body,
      }),
    }),

    deleteBanners: builder.mutation<
      DeleteBannersResponse,
      { bannerId: string }
    >({
      query: ({ bannerId }) => ({
        url: `${END_POINTS.banners}/${bannerId}`,
        method: "DELETE",
      }),
    }),
    putBlockBannerCategory: builder.mutation<
      PutBlockCategoryResponse,
      PutBlockCategoryParams
    >({
      query: ({ body, id }) => ({
        url: `${END_POINTS.banners}/${id}`,
        method: "PUT",
        body,
      }),
    }),
    getLeaderBoards: builder.query<GetBannersResponse, GetBannersParams>({
      query: ({ size, page, query }) => ({
        url:
          END_POINTS.leaderBoard +
          "?size=" +
          size +
          "&page=" +
          page +
          "&search=" +
          query,
        method: "GET",
      }),
    }),
    getLeaderBoardsDetailsById: builder.query<
      GetBannersResponse,
      string | undefined
    >({
      query: (id) => ({
        url: `${END_POINTS.leaderBoard}/${id}/`,
        method: "GET",
      }),
    }),
    getActiveTournaments: builder.query<GetBannersResponse, {}>({
      query: () => ({
        url: END_POINTS.activeTournaments,
      }),
    }),
  }),
});

export const {
  useLazyGetActiveTournamentsQuery,
  useLazyGetLeaderBoardsDetailsByIdQuery,
  useLazyGetLeaderBoardsQuery,
  useLazyGetBannersQuery,
  usePostAddBannersMutation,
  useDeleteBannersMutation,
  useLazyGetBannerDataQuery,
  usePutUpdateBannerMutation,
  usePutBlockBannerCategoryMutation,
} = authApi;
