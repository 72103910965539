import { Button, Card, Col, Row, Spin } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import PlayerPredictionTable from "../../../components/tables/PlayerPredictionTable";
import { useEffect, useState } from "react";
import { Customers } from "../../../types/User";
import { useLazyGetUserDataQuery } from "../../../services/users";
import { PlayerTournamentDetailsTable } from "../../../components/tables";

const PredictionDetails = () => {
  const navigate = useNavigate();
  const { id, view } = useParams();
  const [userDetail, setUserDetail] = useState<Customers>();
  const [getUser, getUserData] = useLazyGetUserDataQuery();

  //   const getUserDetails = async () => {
  //     try {
  //       const res = await getUser(id).unwrap();

  //       if (res?.statusCode === 200) {
  //         setUserDetail(res?.data);
  //       }
  //     } catch (error: any) {
  //       console.log(error);
  //     }
  //   };

  //   useEffect(() => {
  //     getUserDetails();
  //   }, []);

  return (
    <div style={{ padding: 50, gap: 20, display: "grid" }}>
      <div>
        <PlayerPredictionTable id={id} view={view} />
      </div>
    </div>
  );
};

export default PredictionDetails;
