import { Button, Card, Col, Form, Input, Row } from "antd";
import { ContentHeader } from "../../components";
import { useState } from "react";

import { errorToast, successToast } from "../../helpers";
import { usePutAddServicesMutation } from "../../services/auth";
import { useNavigate } from "react-router-dom";

const Settings = () => {
  const navigate = useNavigate();

  const [servicesAmount, setServicesAmount] = useState<number | undefined>();
  const [addServices, addServicesData] = usePutAddServicesMutation();

  const onFinish = async () => {
    let body = {
      serviceValue: 0,
    };
    try {
      const res = await addServices(body).unwrap();

      if (res?.statusCode === 200) {
        successToast(" Service Amount Added successfully");
        setServicesAmount(0);
        // navigate("/dashboard", { replace: true });
      }
    } catch (error: any) {
      errorToast(error?.data?.message || error?.data?.error);
    }
  };

  const validateServiceAmount = (_: any, value: any) => {
    if (value < 100) {
      return Promise.resolve();
    }
    return Promise.reject("Service Amount must be less than 100");
  };

  return (
    <div style={{ padding: 40 }}>
      <ContentHeader heading="Settings" />
      <Card className="cdddddd">
        <Form onFinish={onFinish}>
          <Row gutter={16}>
            <Col xs={24} md={6}>
              <Form.Item
                name="serviceAmount"
                rules={[
                  {
                    required: true,
                    validator: validateServiceAmount,
                  },
                ]}
              >
                <Input
                  type="tel"
                  className="custom-number-input"
                  suffix={<p>%</p>}
                  placeholder="Service Amount"
                  style={{ height: 50 }}
                  value={servicesAmount}
                  onChange={(e) => {
                    setServicesAmount(parseInt(e.target.value));
                  }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item>
            <Button
              style={{
                background: "#ECAC35",
                width: 100,
                height: 43,
                fontWeight: 500,
                color: "black",
              }}
              type="default"
              htmlType="submit"
              loading={addServicesData.isLoading}
            >
              Save
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default Settings;
