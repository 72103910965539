import { END_POINTS } from "../helpers";
import type { CmsResponse, User } from "../types/User";
import emptySplitApi from "../utils/rtk";

type PostLoginBody = {
  key?: string;
  password?: string;
};

type PostLoginResponse = {
  statusCode: number;
  data?: User;
  message: string;
};

type PostForgotPasswordBody = {
  email?: string;
};

type PostForgotPasswordResponse = {
  statusCode: number;
  data?: User;
  message: string;
};

type UpdateCmsResponse = {
  statusCode: number;
  message: string;
};

type UpdateCmsBody = {
  aboutUs: string;
  cancellation: string;
  email: string;
  phone: string;
  privacy: string;
  terms: string;
  countryCode: string;
};

type CommonResponse = {
  statusCode: number;
  message: string;
};

type ChangePasswordBody = {
  oldPassword?: string;
  password?: string;
};

type GetProfileResponse = {
  statusCode: number;
  code?: number;
  data: User;
};

type GetCmsResponse = {
  data: CmsResponse;
  statusCode: number;
};

type ResetPasswordBody = { email: string };

type ResetPasswordResponse = {
  statusCode: number;
  message: string;
};
type UpdateProfileResponse = {
  statusCode: number;
};
type PutUpdateCmsParams = {
  cms_id: string;
  body: UpdateCmsBody;
};

type UpdateProfileBody = {};
type AddTokenBody = {
  name: string;
  tokens: number | undefined;
};

type GetTokenCategoryResponse = { statusCode: number; data: any };
type GetAlTokenCategoryResponse = {};

type GetTokenParams = {
  size: number;
  page: number;
  query?: string | undefined;
};

type GetAllTokenResponse = {
  statusCode: number;
  code?: number;
  data?: any;
  total?: number;
};

type EditTokenCategoryResponse = { statusCode: number };

type EditTokenCategoryParams = {};

type PutServicesBody = {
  serviceValue: number;
};

export const authApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    postLogin: builder.mutation<PostLoginResponse, PostLoginBody>({
      query: (body) => ({
        url: END_POINTS.login,
        method: "POST",
        body,
      }),
    }),

    postForgetPassword: builder.mutation<
      PostForgotPasswordResponse,
      PostForgotPasswordBody
    >({
      query: (body) => ({
        url: END_POINTS.forgetPassword,
        method: "POST",
        body,
      }),
    }),
    postResetPassword: builder.mutation<
      ResetPasswordResponse,
      ResetPasswordBody
    >({
      query: ({ body, rendonId }: any) => ({
        url: `${END_POINTS.resetPassword}/${rendonId}/`,
        method: "POST",
        body,
      }),
    }),

    getProfile: builder.query<GetProfileResponse, {}>({
      query: () => ({
        url: END_POINTS.getProfile,
        method: "GET",
      }),
    }),

    putUpdateProfile: builder.mutation<
      UpdateProfileResponse,
      UpdateProfileBody
    >({
      query: (body) => ({
        url: END_POINTS.updateProfile,
        method: "PUT",
        body,
      }),
    }),
    postChangePassword: builder.mutation<CommonResponse, ChangePasswordBody>({
      query: (body) => ({
        url: END_POINTS.changePassword,
        method: "POST",
        body,
      }),
    }),
    putUpdateCms: builder.mutation<UpdateCmsResponse, PutUpdateCmsParams>({
      query: ({ body, cms_id }: any) => ({
        url: `${END_POINTS.updateCms}/${cms_id}/`,
        method: "PUT",
        body,
      }),
    }),
    getCms: builder.query<GetCmsResponse, {}>({
      query: () => ({
        url: END_POINTS.getCms,
        method: "GET",
      }),
    }),
    postAddToken: builder.mutation<CommonResponse, AddTokenBody>({
      query: (body) => ({
        url: END_POINTS.addTokenCategory,
        method: "POST",
        body,
      }),
    }),
    getTokenCategory: builder.query<
      GetTokenCategoryResponse,
      string | undefined
    >({
      query: (tokenId) => ({
        url: `${END_POINTS.viewTokenCategory}/${tokenId}/`,
        method: "GET",
      }),
    }),
    getAllTokenCategory: builder.query<
      GetAlTokenCategoryResponse,
      { query: string }
    >({
      query: ({ query }) => ({
        url: END_POINTS.viewAllTokenCategory + "?search=" + query,
        method: "GET",
      }),
    }),
    getToken: builder.query<GetAllTokenResponse, GetTokenParams>({
      query: ({ size, page, query }) => ({
        url:
          END_POINTS.viewAllTokenCategory +
          "?size=" +
          size +
          "&page=" +
          page +
          "&search=" +
          query,
        method: "GET",
      }),
    }),
    deleteTokenCategory: builder.mutation<
      CommonResponse,
      { Token_id: string | undefined }
    >({
      query: ({ Token_id }) => ({
        url: `${END_POINTS.deleteToken}/${Token_id}/`,
        method: "DELETE",
      }),
    }),
    putEditTokenCategory: builder.mutation<
      EditTokenCategoryResponse,
      EditTokenCategoryParams
    >({
      query: ({ body, Token_id }: any) => ({
        url: `${END_POINTS.editTokenCategory}/${Token_id}/`,
        method: "PUT",
        body,
      }),
    }),
    putAddServices: builder.mutation<UpdateProfileResponse, PutServicesBody>({
      query: (body) => ({
        url: END_POINTS.updateProfile,
        method: "PUT",
        body,
      }),
    }),
  }),
});

export const {
  usePostLoginMutation,
  usePostForgetPasswordMutation,
  usePostResetPasswordMutation,
  useLazyGetProfileQuery,
  usePutUpdateProfileMutation,
  usePostChangePasswordMutation,
  useLazyGetCmsQuery,
  usePutUpdateCmsMutation,
  usePostAddTokenMutation,
  useLazyGetTokenCategoryQuery,
  useLazyGetAllTokenCategoryQuery,
  useLazyGetTokenQuery,
  useDeleteTokenCategoryMutation,
  usePutEditTokenCategoryMutation,
  usePutAddServicesMutation,
} = authApi;
