import { Button, Card, Col, Form, Input, Row, Space, Spin, Table } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import "react-phone-input-2/lib/style.css";
import { useEffect, useState } from "react";
import { ColumnsType } from "antd/es/table";
import { SearchOutlined, UploadOutlined } from "@ant-design/icons";
import {
  useEditTournamentsByIdMutation,
  useLazyGetLeagueTournamentQuery,
  useLazyGetTournamentDetailsByIdQuery,
  usePostAddTournamentsMutation,
} from "../../../services/tournaments";
import { useAppDispatch, useAppSelector } from "../../../hooks/store";
import { UploadMedia } from "../../../utils/mediaUpload";
import Upload, { RcFile } from "antd/es/upload";
import { errorToast, successToast } from "../../../helpers";
import { useLazyGetTokenQuery } from "../../../services/auth";
import { Select } from "antd";
import {
  getMatchData,
  updateSelection,
} from "../../../reducers/matchDataSlice";
import { SelectedMatchesTable } from "../../../components/tables";

const AddTournamentCategory = () => {
  const [form] = Form.useForm();
  const matchData = useAppSelector(getMatchData);

  const { view } = useParams();
  const [tokenList, tokenListData] = useLazyGetTokenQuery();
  const [allTokenList, setAllTokenList] = useState<TokenCategoryTypes[]>();
  const [selectedCategory, setSelectCategory] = useState([]);
  const [leaguesForTournaments, leaguesForTournamentsData] =
    useLazyGetLeagueTournamentQuery();
  const [servicesAmount, setServicesAmount] = useState<number | undefined>(0);
  const [loading, setLoading] = useState(false);

  const [addTournament, addTournamentData] = usePostAddTournamentsMutation();
  const [getDetails, getDetailsData] = useLazyGetTournamentDetailsByIdQuery();
  const [editTournament, editTournamentData] = useEditTournamentsByIdMutation();
  const [leaguesForTournamentState, setLeaguesForTournamentState] = useState(
    []
  );

  const [values, setValues] = useState({
    name: "",
  });

  const [rules, setRules] = useState(" ");
  const [cutOffTime, setCutOffTime] = useState<number>();
  const navigate = useNavigate();
  const [query, setQuery] = useState("");
  const [totalCount, setTotalCount] = useState<number>();
  const [image, setImage] = useState("");
  const [data, setData] = useState<any[] | undefined>();
  const [tournamentDetails, setTournamentDetails] =
    useState<TournamentDetailsTypes>();

  const [currentPage, setCurrentPage] = useState<number>(1);
  const dispatch = useAppDispatch();
  const onFinish = async (val: any) => {
    let body = {
      image: image || val?.image,
      name: values?.name || val?.name,
      description: "",
      rules: rules || val?.rules,
      cutOffTime: cutOffTime || val?.cutOffTime,
      tokenCategories: val?.tokenCategory,
      commission: servicesAmount || val?.serviceAmount,
      fixtures: data,
    };

    try {
      if (!matchData?.fixtures?.length && view === "add") {
        errorToast("Please add matches");
      } else if (view !== "add") {
        const res = await editTournament({
          body,
          tournamentId: view || "",
        }).unwrap();

        if (res?.statusCode === 200) {
          dispatch(
            updateSelection({
              details: {
                cutoffTime: 0,
                description: "",
                fixtures: [],
                image: "",
                name: "",
                rules: "",
                tokenCategories: [],
                commission: 0,
              },
            })
          );
          navigate("/managetournaments", { replace: true });
          successToast("Tournament Updated Successfully");
        }
      } else {
        const res = await addTournament(body).unwrap();

        if (res?.statusCode === 200) {
          successToast(res?.message || "");
          navigate("/managetournaments", { replace: true });
          dispatch(
            updateSelection({
              details: {
                cutoffTime: 0,
                description: "",
                fixtures: [],
                image: "",
                name: "",
                rules: "",
                tokenCategories: [],
                commission: 0,
              },
            })
          );
        }
      }
    } catch (error: any) {
      console.log(error);
    }
  };
  const url = window.location.pathname;
  const path = url.includes("add");

  const { Option } = Select;
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    getLeaguesForTournaments(page);
  };

  interface DataType {
    year: any | undefined;
    leagueId: number | undefined;
    key: string;
    leagueName: string;
    startDate: string;
    endDate: string;
    totalMatches: string;
  }

  const columns: ColumnsType<DataType> = [
    {
      title: "Image",
      dataIndex: "leagueImage",
      key: "leagueImage",
      width: "8%",
      align: "center",
      render: (_, { leagueImage }: any) => (
        <div>
          <img
            style={{ height: 70, width: 70, borderRadius: 100 }}
            src={leagueImage || "/static/images/dummyTP.png"}
            alt=""
          />
        </div>
      ),
    },

    {
      title: "League Name",
      dataIndex: "leagueName",
      key: "leagueName",
      align: "center",
    },

    {
      title: "Start Date",
      dataIndex: "startDate",
      key: "startDate",
      align: "center",
      render: (_, { startDate }: any) => (
        <div>
          <span>{`${startDate || "-"}`}</span>
        </div>
      ),
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      key: "endDate",
      align: "center",
      render: (_, { endDate }: any) => (
        <div>
          <span>{`${endDate || "-"}`}</span>
        </div>
      ),
    },
    {
      title: "Country Name",
      dataIndex: "countryName",
      key: "countryName",
      align: "center",
      render: (_, { countryName }: any) => (
        <div>
          <span>{`${countryName || "-"}`}</span>
        </div>
      ),
    },

    {
      title: "Action",
      key: "action",
      align: "center",
      render: (_, record) => (
        <Space size="middle">
          <Button
            type="primary"
            onClick={(e) => {
              e.preventDefault();
              navigate(
                `/managetournamentscategories/addTournamentDetails/${record?.leagueId}/${view}`
              );
            }}
            disabled={
              tournamentDetails
                ? tournamentDetails?.isEditable
                  ? false
                  : true
                : false
            }
            style={{
              fontSize: 12,
              backgroundColor: "#ECAC35",
              fontWeight: 500,
              color: "black",
              borderRadius: 10,
              width: 118,
              height: 30,
            }}
          >
            Select matches
          </Button>
        </Space>
      ),
    },
  ];

  const getLeaguesForTournaments = async (page: number) => {
    try {
      setLoading(true);
      const res = await leaguesForTournaments({
        page: page,
        size: 10,
        query: query || "",
      }).unwrap();
      setLoading(false);

      if (res?.statusCode === 200) {
        setLeaguesForTournamentState(res?.data?.data);
        setTotalCount(res?.data?.count);
      }
    } catch (error: any) {
      setLoading(false);

      console.log(error);
    }
  };

  const handleKeyPress = (e: any) => {
    if (e.key === "Enter") {
      getLeaguesForTournaments(1);
    }
  };

  useEffect(() => {
    if (query === "") {
      getLeaguesForTournaments(1);
    }
  }, [query]);

  useEffect(() => {
    getTokenCategoryDetails(1);
  }, []);

  useEffect(() => {
    addTournamentHandler();
    getTournamentDetails(1);
  }, []);

  const getTokenCategoryDetails = async (page: number) => {
    try {
      const res = await tokenList({
        page: page,
        size: 10,
        query: "",
      }).unwrap();
      setLoading(false);

      if (res?.statusCode === 200) {
        setAllTokenList(res?.data?.data);
      }
    } catch (error: any) {
      setLoading(false);

      console.log(error);
    }
  };

  const handleImageUpload = async (file: RcFile) => {
    if (file?.type.includes("image")) {
      try {
        const res = await UploadMedia(file);

        if (res?.statusCode === 200) {
          setImage(res?.data);
          form.setFieldsValue({
            image: res?.data,
          });
          dispatch(
            updateSelection({
              details: {
                ...matchData,
                image: res?.data,
              },
            })
          );
        }
      } catch (error: any) {
        console.log(error);
      }
    } else {
      errorToast("Only images are allowed");
    }

    return false;
  };

  const addTournamentHandler = async () => {
    const newArray = matchData?.fixtures?.map((obj: any) => ({
      fixture_id: obj.fixture.id,
      fixture: obj?.fixture,
      league: obj?.league,
      teams: obj?.teams,
      goals: obj?.goals,
      score: obj?.score,
    }));

    setData(newArray);
  };

  const pagination = {
    pageSize: 10,
    current: currentPage,
    total: totalCount,
    onChange: handlePageChange,
  };

  const handleChange = (value: any) => {
    form.setFieldsValue({
      tokenCategory: value,
    });
    setSelectCategory(value);

    dispatch(
      updateSelection({
        details: {
          ...matchData,
          tokenCategories: value,
        },
      })
    );

    form.validateFields(["tokenCategory"]);
  };

  const backButtonHandler = () => {
    navigate("/managetournaments", { replace: true });
    dispatch(
      updateSelection({
        details: {
          cutoffTime: 0,
          description: "",
          fixtures: [],
          image: "",
          name: "",
          rules: "",
          tokenCategories: [],
          commission: 0,
        },
      })
    );
  };

  const getTournamentDetails = async (page: number) => {
    try {
      let body = {
        tournamentId: view || "",
        size: 10,
        page: page,
        query: "",
        categoryId: "",
      };
      if (!matchData?.fixtures?.length && view !== "add") {
        const res = await getDetails(body).unwrap();

        if (res?.statusCode === 200) {
          setTournamentDetails(res?.data);

          dispatch(
            updateSelection({
              details: {
                cutoffTime: res?.data?.cutOffTime,
                description: "",
                fixtures: [],
                image: res?.data?.image,
                name: res?.data?.name,
                rules: "",
                tokenCategories: res?.data?.tokenCategories,
                commission: res?.data?.commission,
              },
            })
          );
          form.setFieldsValue({
            name: res?.data?.name || matchData?.name,
            image: res?.data?.image || matchData?.image,
            tokenCategory:
              res?.data?.tokenCategories || matchData?.tokenCategories,
            cutOffTime: res?.data?.cutOffTime || matchData?.cutoffTime,
            userName: res?.data?.userName,
            address: res?.data?.address,
            rules: res?.data?.rules || matchData?.rules,
            serviceAmount: res?.data?.commission || matchData?.commission,
          });
          setSelectCategory(
            res?.data?.tokenCategories || matchData?.tokenCategories
          );
          setImage(res?.data?.image || matchData?.image);
          setCutOffTime(res?.data?.cutOffTime || matchData?.cutoffTime);
          setServicesAmount(res?.data?.commission || matchData?.commission);
          setRules(res?.data?.rules || matchData?.rules);
          setValues({
            ...values,
            name: res?.data?.name || matchData?.name,
          });
        }
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  const validateServiceAmount = (_: any, value: any) => {
    if (value <= 100) {
      return Promise.resolve();
    }
    return Promise.reject("Commission must be less than 100");
  };

  useEffect(() => {
    if (matchData) {
      form.setFieldsValue({
        name: matchData?.name,
        image: matchData?.image,
        tokenCategory: matchData?.tokenCategories,
        rules: matchData?.rules,
        cutOffTime: matchData?.cutoffTime,
        serviceAmount: matchData?.commission,
      });
    }
  }, []);

  return (
    <div style={{ padding: 50 }}>
      <Card loading={getDetailsData.isLoading}>
        <div
          style={{
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <h2>{path ? "Add " : "Edit"}Tournament</h2>
          <Button
            style={{
              background: "#ECAC35",
              width: 100,
              height: 40,
              color: "black",
            }}
            type="primary"
            // htmlType="submit"
            onClick={() => backButtonHandler()}
          >
            Back
          </Button>
        </div>

        <div>
          <Form
            name="basic"
            layout="vertical"
            form={form}
            style={{
              maxWidth: "85%",
              marginTop: 4,
              paddingLeft: 10,
            }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            autoComplete="off"
          >
            <Row gutter={16}>
              <Col xs={24} md={12}>
                <Form.Item
                  name="image"
                  rules={[
                    {
                      required: true,
                      message: "Please upload image",
                    },
                  ]}
                >
                  <Upload
                    beforeUpload={handleImageUpload}
                    showUploadList={false}
                    className="upload_imgD"
                    accept="image/*"
                  >
                    <div>
                      <h4>Upload Image</h4>
                      <Button icon={<UploadOutlined />}>Click to upload</Button>
                    </div>
                    {image || matchData?.image ? (
                      <img
                        style={{
                          height: 110,
                          width: 110,
                          borderRadius: "50%",
                          marginTop: 20,
                        }}
                        src={image || matchData?.image}
                        alt="profile"
                      />
                    ) : (
                      <></>
                    )}
                  </Upload>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} md={12}></Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} md={12}>
                <Form.Item
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "Please input tournament name",
                      whitespace: true,
                    },
                    {
                      min: 5,
                      message: "tournament name must be at least 5 characters",
                    },
                  ]}
                >
                  <div>
                    <h4>Tournament Name</h4>
                    <Input
                      value={values?.name || matchData?.name}
                      onChange={(e) => {
                        setValues({ ...values, name: e.target.value });

                        dispatch(
                          updateSelection({
                            details: {
                              ...matchData,
                              name: e.target.value || "",
                            },
                          })
                        );
                      }}
                      placeholder="Tournament Name"
                      style={{ height: 43 }}
                    />
                  </div>
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  // label="Username"
                  name="tokenCategory"
                  rules={[
                    {
                      required: true,
                      message: "Please select at least one token category",
                    },
                  ]}
                >
                  <div>
                    <h4>Token Categories</h4>
                    <Select
                      disabled={tournamentDetails?.isEditable === false}
                      className="select_type"
                      mode="multiple"
                      onChange={(val) => handleChange(val)}
                      value={
                        selectedCategory?.length
                          ? selectedCategory
                          : matchData?.tokenCategories
                      }
                      style={{ height: 75, width: "100%" }}
                      placeholder="Tokens"
                    >
                      {allTokenList?.map((item, index) => {
                        return (
                          <Option key={index} value={item?._id}>
                            {item?.name as any}
                          </Option>
                        );
                      })}
                    </Select>
                  </div>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} md={12}>
                <Form.Item
                  name="cutOffTime"
                  rules={[
                    {
                      required: true,
                      message: "Please input cut-off time",
                    },
                  ]}
                >
                  <div>
                    <h4>{`Cut-off Time (In minutes)`}</h4>
                    <Input
                      value={cutOffTime || matchData?.cutoffTime}
                      onChange={(e) => {
                        setCutOffTime(parseInt(e.target.value));
                        dispatch(
                          updateSelection({
                            details: {
                              ...matchData,
                              cutoffTime: parseInt(e.target.value) || 0,
                            },
                          })
                        );
                      }}
                      type="tel"
                      className="custom-number-input"
                      placeholder="Add Time"
                      style={{ height: 43 }}
                      maxLength={3}
                    />
                  </div>
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  name="serviceAmount"
                  rules={[
                    {
                      required: true,
                      validator: validateServiceAmount,
                    },
                  ]}
                >
                  <div>
                    <h4>{`Commission (In Percent)`}</h4>

                    <Input
                      disabled={tournamentDetails?.isEditable === false}
                      maxLength={3}
                      type="tel"
                      className="custom-number-input"
                      suffix={<p>%</p>}
                      placeholder="Service Amount"
                      style={{ height: 43 }}
                      value={servicesAmount || matchData?.commission}
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        const parsedValue = parseInt(inputValue);

                        if (!isNaN(parsedValue)) {
                          setServicesAmount(parsedValue);
                          dispatch(
                            updateSelection({
                              details: {
                                ...matchData,
                                commission: parsedValue || 0,
                              },
                            })
                          );
                        } else {
                          setServicesAmount(0);
                          dispatch(
                            updateSelection({
                              details: {
                                ...matchData,
                                commission: 0,
                              },
                            })
                          );
                        }
                      }}
                    />
                  </div>
                </Form.Item>
              </Col>
            </Row>

            <div>
              <h4>Add matches for tournament</h4>
              <Row style={{ paddingBottom: 20 }} gutter={16}>
                <Col xs={24} md={12}>
                  <div style={{ display: "flex", gap: 20 }}>
                    {/* <Form
                    style={{ display: "flex", gap: 20 }}
                    onFinish={() => {
                      getLeaguesForTournaments(1);
                    }}
                  > */}
                    <Input
                      placeholder="Search Tournament"
                      prefix={<SearchOutlined style={{ fontSize: "24px" }} />}
                      style={{ height: 50, borderRadius: 10 }}
                      onChange={(e) => setQuery(e.target.value)}
                      onKeyDown={(e) => handleKeyPress(e)}
                      onPressEnter={(e) => e.preventDefault()}
                    />
                    <Button
                      style={{
                        background: "#ECAC35",
                        width: 90,
                        height: 43,
                        margin: "auto",
                        color: "black",
                      }}
                      type="ghost"
                      // htmlType="reset"
                      onClick={() => {
                        getLeaguesForTournaments(1);
                      }}
                    >
                      Search
                    </Button>
                  </div>
                  {/* </Form> */}
                </Col>
              </Row>

              <Card loading={loading}>
                <Table
                  columns={columns}
                  className="tableSelector"
                  dataSource={leaguesForTournamentState}
                  loading={leaguesForTournamentsData.isLoading || loading}
                  pagination={pagination}
                  rowKey="_Id"
                />
              </Card>
            </div>

            <Form.Item>
              {matchData?.fixtures?.length ? (
                <div style={{ marginTop: 20 }}>
                  <h4>Selected Matches</h4>
                  <SelectedMatchesTable data={matchData?.fixtures} />
                </div>
              ) : null}
              <div style={{ display: "flex", marginTop: 30 }}>
                <Button
                  style={{
                    background: "#ECAC35",
                    width: 200,
                    height: 50,
                    color: "black",
                    fontWeight: 500,
                  }}
                  loading={
                    addTournamentData.isLoading || editTournamentData?.isLoading
                  }
                  type="primary"
                  htmlType="submit"
                >
                  Save
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      </Card>
    </div>
  );
};

export default AddTournamentCategory;
