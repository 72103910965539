import {
  Card,
  Col,
  Input,
  Row,
  Button,
  Space,
  Modal,
  Form,
  Switch,
} from "antd";
import { ContentHeader } from "../../components";
import { DeleteOutlined, EditFilled, SearchOutlined } from "@ant-design/icons";
import Table, { ColumnsType } from "antd/es/table";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { errorToast, successToast } from "../../helpers";
import {
  useDeleteTeamCategoryMutation,
  useLazyGetTeamsQuery,
  usePutActiveTeamMutation,
} from "../../services/tournaments";

const ManageTeams = () => {
  const navigate = useNavigate();
  const [query, setquery] = useState("");
  const [totalCount, setTotalCount] = useState<number>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [deleteCategory, deleteCategoryData] = useDeleteTeamCategoryMutation();
  const [allTeamList, setAllTeamList] = useState([]);
  const [teamList, teamListData] = useLazyGetTeamsQuery();
  const [deactivateTeam, deactivateTeamData] = usePutActiveTeamMutation();

  const [rowNumber, setRowNumber] = useState(0);

  const pageSize = 10;

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    getTeamDetails(page);
    const newRowNumber = (page - 1) * pageSize;
    setRowNumber(newRowNumber);
  };
  interface DataType {
    _id: string;
    key: string;
    name?: string;
  }

  const handleBlock = async (isActive: boolean, _id: string) => {
    let body = {
      isActive: isActive,
    };
    try {
      const res = await deactivateTeam({ body, teamId: _id }).unwrap();
      if (res?.statusCode === 200) {
        getTeamDetails(1);
        successToast(
          isActive
            ? "Team Activated Successfully!"
            : "Team Deactivated Successfully!"
        );
      }
    } catch (error: any) {
      console.log(error);
      errorToast(error?.data?.message);
    }
  };
  const handleDelete = async (id: string) => {
    try {
      const res = await deleteCategory({ teamId: id }).unwrap();

      if (res?.statusCode === 200) {
        successToast("Team Deleted Successfully");
        getTeamDetails(1);
      }
    } catch (error: any) {
      console.log(error);
      errorToast(error?.data?.message);
    }
  };
  const showDeleteConfirm = (record: any) => {
    confirm({
      title: "Hold on",
      content: "Are you sure delete this team category?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        handleDelete(record?._id);
        console.log("OK");
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };
  const { confirm } = Modal;

  const columns: ColumnsType<DataType> = [
    {
      title: "#",
      key: "rank",
      dataIndex: "rank",
      align: "center",
      width: "10%",
      render: (text, record, index) => `${rowNumber + index + 1}`,
    },
    {
      title: "Image",
      dataIndex: "image",
      key: "image",

      align: "center",
      render: (_, { logo }: any) => (
        <div>
          <img
            style={{ height: 70, width: 70, borderRadius: 100 }}
            src={logo || "/static/images/dummyTP.png"}
            alt=""
          />
        </div>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      align: "center",
    },

    {
      title: "Action",
      key: "action",
      align: "center",
      render: (_, record) => (
        <Space size="middle">
          <Button
            onClick={(e) => {
              e.preventDefault();
              navigate(`/favoriteteams/${record?._id}`);
            }}
          >
            <EditFilled style={{ color: "#C69320" }} />
          </Button>
          <Button
            onClick={(e) => {
              e.preventDefault();
              showDeleteConfirm(record);
            }}
          >
            <DeleteOutlined style={{ color: "#d4380d" }} />
          </Button>
        </Space>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      align: "center",
      render: (_, { isActive, _id }: any) => (
        <Switch
          size="small"
          defaultChecked={isActive}
          onChange={() => handleBlock(!isActive, _id)}
        />
      ),
    },
  ];

  const getTeamDetails = async (page: number) => {
    try {
      const res = await teamList({
        page: page,
        size: 10,
        query: query || "",
      }).unwrap();
      if (res?.statusCode === 200) {
        setAllTeamList(res?.data?.data);
        setTotalCount(res?.data?.count);
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (query === "") {
      getTeamDetails(1);
    }
  }, [query]);

  const pagination = {
    pageSize,
    current: currentPage,
    total: totalCount,
    onChange: handlePageChange,
  };

  return (
    <div style={{ padding: 40 }}>
      <ContentHeader
        buttonText="Add"
        buttonNavigateTo="favoriteteams/add"
        heading="Favorite Teams "
      />
      <Card>
        <div className="srch">
          <Row style={{ paddingTop: 20, paddingBottom: 20 }} gutter={16}>
            <Col xs={24} md={7}>
              <Form
                style={{ display: "flex", gap: 20 }}
                onFinish={() => getTeamDetails(1)}
              >
                <Input
                  placeholder="Search"
                  prefix={<SearchOutlined style={{ fontSize: "24px" }} />}
                  style={{ height: 50, borderRadius: 10 }}
                  onChange={(e) => setquery(e.target.value)}
                />
                <Button
                  style={{
                    background: "#ECAC35",
                    width: 90,
                    height: 43,
                    margin: "auto",
                    color: "black",
                  }}
                  type="default"
                  htmlType="submit"
                >
                  Search
                </Button>
              </Form>
            </Col>
          </Row>

          <Table
            columns={columns}
            dataSource={allTeamList}
            pagination={pagination}
            rowKey="_id"
            loading={
              teamListData.isLoading ||
              deleteCategoryData.isLoading ||
              deactivateTeamData.isLoading
            }
          />
        </div>
      </Card>
    </div>
  );
};

export default ManageTeams;
