import {
  DeleteOutlined,
  EditFilled,
  EditOutlined,
  EyeOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Space,
  Switch,
  Table,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import { useNavigate } from "react-router-dom";
import { ContentHeader } from "../../components";
import { useEffect, useState } from "react";
import {
  useDeleteSubAdminMutation,
  useLazyGetSubAdminQuery,
  usePostEditSubAdminMutation,
} from "../../services/subAdmin";
import { errorToast, successToast } from "../../helpers";

const { confirm } = Modal;

interface DataType {
  _id: string;
  name: string;
  phone: string;
  address: string;
  email: string;
  status: boolean;
  image?: string;
  countryCode?: string;
  isBlocked?: boolean;
}

const ManageSubAdmins = () => {
  const navigate = useNavigate();

  const [subAdminList, setSubAdminList] = useState<DataType[]>([]);
  const [totalCount, setTotalCount] = useState<number>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [query, setQuery] = useState("");

  const [getSubAdminMethod] = useLazyGetSubAdminQuery();
  const [deleteSubAdminMethod] = useDeleteSubAdminMutation();
  const [updateBlockEditSubAdmin] = usePostEditSubAdminMutation();

  const handleBlock = async (isBlocked: boolean, _id: string) => {
    let body = {
      isBlocked: isBlocked,
    };

    try {
      setLoading(true);
      const res = await updateBlockEditSubAdmin({
        body,
        subadmin_id: _id,
      }).unwrap();
      setLoading(false);
      if (res?.statusCode === 200) {
        const updatedData = subAdminList?.map((x: DataType) => {
          if (x._id === _id) {
            return {
              ...x,
              isBlocked: isBlocked,
            };
          }

          return x;
        });
        setSubAdminList(updatedData);
        // getSubAdminData(currentPage);
        successToast(
          isBlocked
            ? "User Deactivated Successfully!"
            : "User Activated Successfully!"
        );
      }
    } catch (error: any) {
      setLoading(false);
      console.log(error);
      errorToast(error?.data?.message);
    }
  };

  const handleDelete = async (id: string) => {
    try {
      setLoading(true);
      const res = await deleteSubAdminMethod({ subadmin_id: id }).unwrap();
      setLoading(false);
      if (res?.statusCode === 200) {
        successToast("Sub admin deleted successfully");
        getSubAdminData(currentPage);
      }
    } catch (error: any) {
      setLoading(false);
      console.log(error);
      errorToast(error?.data?.message);
    }
  };

  const showDeleteConfirm = (record: any) => {
    confirm({
      title: "Hold on",
      content: "Are you sure you want to delete this Sub admin?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        handleDelete(record?._id);
        console.log("OK");
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      width: "10%",
      align: "center",
      render: (_, { image }) => (
        <div>
          <img
            style={{ height: 60, width: 60, borderRadius: 100 }}
            src={image || "/static/images/dummy.png"}
            alt=""
          />
        </div>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      align: "center",
      // render: (text) => <a>{text}</a>,
    },
    {
      title: "Phone No.",
      dataIndex: "phone",
      key: "phone",
      align: "center",
      render: (_, { phone, countryCode }) => (
        <div>
          <span>{`${phone ? countryCode + " " + phone : "N/A"}`}</span>
        </div>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      align: "center",
    },

    {
      title: "Action",
      key: "action",
      align: "center",
      render: (_, record) => (
        <Space size="middle">
          <Button
            onClick={(e) => {
              e.preventDefault();
              navigate(`/managesubadmins/subadmindetail/${record?._id}`);
            }}
          >
            <EyeOutlined style={{ color: "#52c41a" }} />
          </Button>

          <Button
            onClick={(e) => {
              e.preventDefault();
              navigate(`/managesubadmins/${record?._id}`);
            }}
          >
            <EditFilled style={{ color: "#ECAC35" }} />
          </Button>
          <Button
            onClick={(e) => {
              e.preventDefault();
              showDeleteConfirm(record);
            }}
          >
            <DeleteOutlined style={{ color: "#d4380d" }} />
          </Button>
        </Space>
      ),
    },
    {
      title: "Account Status",
      dataIndex: "status",
      key: "status",
      align: "center",
      render: (_, { isBlocked, _id }) => (
        <Switch
          size="small"
          defaultChecked={!isBlocked}
          onChange={() => handleBlock(!isBlocked, _id)}
        />
      ),
    },
  ];

  const getSubAdminData = async (page: any) => {
    setCurrentPage(page);
    try {
      setLoading(true);
      let body = {
        page: page,
        size: 10,
        query: query || "",
      };
      const res = await getSubAdminMethod(body).unwrap();
      setLoading(false);
      if (res?.statusCode === 200) {
        setSubAdminList(res?.data?.data);
        setTotalCount(res?.data?.count);
      }
    } catch (error: any) {
      console.log(error);
      setLoading(false);
    }
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    getSubAdminData(page);
  };

  const pagination = {
    pageSize: 10,
    current: currentPage,
    total: totalCount,
    onChange: handlePageChange,
  };

  useEffect(() => {
    if (query === "") {
      getSubAdminData(1);
    }
  }, [query]);

  return (
    <div style={{ padding: 50 }}>
      <ContentHeader
        heading="Manage Sub Admins"
        buttonText="Add Sub Admin"
        buttonNavigateTo="managesubadmins/add"
      />
      <div className="srch">
        <Card>
          <Row style={{ marginBottom: 30, marginTop: 10 }} gutter={16}>
            <Col xs={24} md={7}>
              <Form
                style={{ display: "flex", gap: "20px" }}
                onFinish={() => getSubAdminData(1)}
              >
                <Input
                  placeholder="Search"
                  prefix={<SearchOutlined style={{ fontSize: "24px" }} />}
                  style={{ height: 43, borderRadius: 10 }}
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                />
                <Button
                  style={{
                    background: "#ECAC35",
                    width: 100,
                    height: 43,
                    margin: "auto",
                    color: "black",
                  }}
                  type="primary"
                  htmlType="submit"
                  disabled={!query ? true : false}
                >
                  Search
                </Button>
              </Form>
            </Col>
          </Row>

          <Table
            columns={columns}
            dataSource={subAdminList}
            pagination={pagination}
            loading={loading}
            rowKey="_id"
          />
        </Card>
      </div>
    </div>
  );
};

export default ManageSubAdmins;
