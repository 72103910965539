import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  TimePicker,
} from "antd";
import { useNavigate, useParams } from "react-router-dom";
import "react-phone-input-2/lib/style.css";

import {
  useLazyGetActiveTournamentsQuery,
  useLazyGetBannerDataQuery,
  usePostAddBannersMutation,
  usePutUpdateBannerMutation,
} from "../../../services/banners";
import { useEffect, useState } from "react";
import { UploadMedia } from "../../../utils/mediaUpload";
import { errorToast, successToast } from "../../../helpers";
import Upload, { RcFile } from "antd/es/upload";
import { UploadOutlined } from "@ant-design/icons";
import dayjs, { Dayjs } from "dayjs";

import EditText from "../../../components/textEditor";
import { isString } from "../../../utils/validations";
import moment from "moment";

const SeasonalCompetitionForm = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [bannerType, setBannerType] = useState("");
  const [getResponse, setgetResponse] = useState<any>([]);
  const [selectedTournament, setSelectedTournament] = useState<any>([]);

  const [activeTournamentList, setActiveTournamentList] =
    useState<TokenCategoryTypes[]>();

  const [startTime, setStartTime] = useState<Dayjs | null>(null);
  const [endTime, setEndTime] = useState<Dayjs | null>(null);

  const { RangePicker } = DatePicker;

  const { view } = useParams();

  const [values, setValues] = useState({
    title: "",
    tokenFee: "",
    startDate: "",
    endDate: "",
  });

  const [addBanners, addBannersData] = usePostAddBannersMutation();
  const [activeTournaments, activeTournamentsData] =
    useLazyGetActiveTournamentsQuery();
  const [getBanner, getBannerData] = useLazyGetBannerDataQuery();
  const [editBanner, editBannerData] = usePutUpdateBannerMutation();
  const [dates, setDates] = useState<any>([]);
  const [image, setImage] = useState(null);
  const { Option } = Select;

  const [description, setDescription] = useState(view !== "add" ? "" : " ");
  const [bannerDescription, setBannerDescription] = useState(
    view !== "add" ? "" : " "
  );

  const getBannerDetails = async () => {
    try {
      const res = await getBanner(view).unwrap();

      if (res?.statusCode === 200) {
        setgetResponse(res?.data);
        let shuruDate = dayjs(
          moment(
            `${res?.data?.startDate}T${res?.data?.startTime}:00.037Z`
          ).format("YYYY-MM-DD")
        );
        let entimDate = dayjs(
          moment(`${res?.data?.endDate}T${res?.data?.endTime}:00.037Z`).format(
            "YYYY-MM-DD"
          )
        );

        form.setFieldsValue({
          title: res?.data?.title,
          tokenFee: res?.data?.tokenFee,
          type: res?.data?.type,
          description: res?.data?.description,
          bannerDescription: res?.data?.bannerDescription,
          image: res?.data?.image,
          addTournaments: res?.data?.tournaments,
          dates: [shuruDate, entimDate],
        });

        setImage(res?.data?.image || "");
        setBannerType(res?.data?.type);
        setDescription(res?.data?.description || " ");
        setBannerDescription(res?.data?.bannerDescription || " ");
        // setActiveTournamentList(res?.data?.tournaments);
        {
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          selectedTournament?.length
            ? null
            : res?.data?.tournaments.forEach(function (item: any) {
                selectedTournament.push(item?._id);
                setSelectedTournament(selectedTournament);
              });
        }

        let utcStartTime = moment(
          `${res?.data?.startDate}T${res?.data?.startTime}:00.037Z`
        ).format("HH:mm");

        setStartTime(
          res?.data?.startTime ? dayjs(utcStartTime, "HH:mm") : null
        );

        let utcEndTime = moment(
          `${res?.data?.endDate}T${res?.data?.endTime}:00.037Z`
        ).format("HH:mm:ss");

        setStartTime(
          res?.data?.startTime ? dayjs(utcStartTime, "HH:mm") : null
        );

        setEndTime(res?.data?.endTime ? dayjs(utcEndTime, "HH:mm") : null);
        setValues({
          ...values,
          title: res?.data?.title || "",

          tokenFee: res?.data?.tokenFee || "",
          startDate: `${dayjs(res?.data?.startDate)}` || "",
          endDate: `${dayjs(res?.data?.endDate)}` || "",
        });
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (view !== "add") {
      getBannerDetails();
    }
  }, []);

  const onFinish = async (data: any) => {
    const { dates } = data;

    let start = "";
    let end = "";

    if (bannerType !== "BANNER") {
      start = dates[0]?.format("YYYY-MM-DD");
      end = dates[1]?.format("YYYY-MM-DD");
    }

    // let startTiming = new Date(startTime as any).toISOString();
    // let endTiming = new Date(endTime as any).toISOString();

    // Combine date and time strings

    let startUtcDateString = "";
    let endUtcDateString = "";

    if (bannerType === "SEASON_BANNER") {
      const startDateTimeString = `${start}T${moment(
        new Date(startTime as any)
      ).format("HH:mm")}`;

      // Create a Date object in the local timezone
      const startLocalDate = new Date(startDateTimeString);

      // Convert it to UTC
      const startUtcDate = new Date(startLocalDate.toISOString());

      // Format the UTC date as a string
      startUtcDateString = startUtcDate.toISOString();

      // Combine date and time strings
      const endDateTimeString = `${end}T${moment(
        new Date(endTime as any)
      ).format("HH:mm")}`;

      // Create a Date object in the local timezone
      const endLocalDate = new Date(endDateTimeString);

      // Convert it to UTC
      const endUtcDate = new Date(endLocalDate.toISOString());

      // Format the UTC date as a string
      endUtcDateString = endUtcDate.toISOString();
    }

    let body = {
      title: values.title,
      startDate: startUtcDateString?.split("T")[0] || "",
      endDate: endUtcDateString?.split("T")[0] || "",
      image: image,
      description: description,
      bannerDescription: bannerDescription,
      tokenFee: values.tokenFee,
      type: data.type,
      startTime:
        bannerType === "SEASON_BANNER"
          ? startUtcDateString?.split("T")[1]?.slice(0, 5)
          : "",
      endTime:
        bannerType === "SEASON_BANNER"
          ? endUtcDateString?.split("T")[1]?.slice(0, 5)
          : "",

      tournaments: selectedTournament,
    };

    if (view === "add") {
      try {
        const res = await addBanners(body).unwrap();

        if (res?.statusCode === 200) {
          successToast("Banner Added Successfully");
          navigate("/seasonalcompetition", { replace: true });
        }
      } catch (error: any) {
        console.log(error);
      }
    } else {
      const res = await editBanner({
        body,
        bannerId: view,
      }).unwrap();

      if (res?.statusCode === 200) {
        successToast("Banner Edited Successfully");
        navigate("/seasonalcompetition", { replace: true });
      }
    }
  };

  const handleImageUpload = async (file: RcFile) => {
    if (file?.size < 794996) {
      errorToast("Banner image minimum dimensions must be 2340 × 618 ");
    } else if (file?.type.includes("image")) {
      try {
        const res = await UploadMedia(file);

        if (res?.statusCode === 200) {
          setImage(res?.data);
        }
      } catch (error: any) {
        console.log(error);
      }
    } else {
      errorToast("Only images are allowed");
    }

    return false;
  };

  const validateDateRange = (_: any, value: string | any[]) => {
    if (value && value.length > 1) {
      const startDate = value[0];
      const endDate = value[1];

      if (startDate && endDate && startDate.isAfter(endDate, "day")) {
        return Promise.reject("End date must be after start date");
      }
    }

    return Promise.resolve();
  };

  const handleOptionChange = (value: any) => {
    form.setFieldsValue({
      type: value,
    });

    form.validateFields(["types"]);
    setBannerType(value);
  };

  const sDate = dayjs(getResponse?.startDate);
  const eDate = dayjs(getResponse?.endDate);

  const activeTournamentHandler = async () => {
    try {
      const res = await activeTournaments({}).unwrap();

      if (res?.statusCode === 200) {
        setActiveTournamentList(res?.data);
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  const handleChange = (value: any) => {
    form.setFieldsValue({
      addTournaments: value,
    });
    setSelectedTournament(value);

    form.validateFields(["addTournaments"]);
  };

  const handleTimeChange = (times: any) => {
    if (times) {
      setStartTime(times);
    } else {
      setStartTime(null);
    }
  };
  const handleEndTimeChange = (times: any) => {
    if (times) {
      setEndTime(times);
    } else {
      setEndTime(null);
    }
  };

  useEffect(() => {
    activeTournamentHandler();
  }, []);

  return (
    <div style={{ padding: 50 }}>
      <Card>
        <div
          style={{
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <h2>{view === "add" ? "Add Banner" : "Edit Banner"}</h2>
          <Button
            style={{
              background: "#ECAC35",
              width: 100,
              height: 40,
              color: "black",
            }}
            type="primary"
            // htmlType="submit"
            onClick={() => {
              navigate("/seasonalcompetition", { replace: true });
            }}
          >
            Back
          </Button>
        </div>

        <div>
          <Form
            name="basic"
            layout="vertical"
            form={form}
            style={{
              maxWidth: "80%",
              marginTop: 20,
            }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            autoComplete="off"
          >
            <Row gutter={16}>
              <Col xs={24} md={12}>
                <Form.Item
                  name="image"
                  rules={[
                    {
                      required: true,
                      message: "Please add banner image!",
                    },
                  ]}
                >
                  <Upload
                    beforeUpload={handleImageUpload}
                    showUploadList={false}
                    className="upload_imgD"
                    accept="image/*"
                  >
                    <div>
                      <h4>Upload Image</h4>
                      <Button icon={<UploadOutlined />}>Click to upload</Button>
                    </div>
                    {image ? (
                      <img
                        style={{
                          height: 150,
                          width: "100%",
                          // borderRadius: "5%",
                          marginTop: 20,
                        }}
                        src={image}
                        alt="profile"
                      />
                    ) : (
                      <></>
                    )}
                  </Upload>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} md={12}>
                <Form.Item
                  name="title"
                  rules={[
                    {
                      required: true,
                      message: "Please input title of banner!",
                      whitespace: true,
                    },
                    {
                      min: 5,
                      message: "Title must be at least 5 characters",
                    },
                  ]}
                >
                  <div>
                    <h4>Title</h4>
                    <Input
                      value={values.title}
                      onChange={(e) => {
                        if (e.target.value === " " || e.target.value === ".") {
                        }
                        // else if (isString(e.target.value)) {
                        //   setValues({ ...values, title: e.target.value });
                        // }
                        else {
                          setValues({ ...values, title: e.target.value });
                        }
                      }}
                      placeholder="Title"
                      style={{ height: 55 }}
                      maxLength={50}
                    />
                  </div>
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  name="type"
                  rules={[
                    {
                      required: true,
                      message: "Please input banner type!",
                    },
                  ]}
                >
                  <div>
                    <h4>Types</h4>
                    <Select
                      value={bannerType || "Select Banner Type"}
                      onChange={handleOptionChange}
                      style={{ height: 55 }}
                      placeholder="Banner Type"
                    >
                      <Option value="BANNER">Banner</Option>
                      <Option value="SEASON_BANNER">Season Banner</Option>
                    </Select>
                  </div>
                </Form.Item>
              </Col>
            </Row>
            {bannerType === "SEASON_BANNER" ? (
              <Row gutter={16}>
                <Col xs={24} md={12}>
                  <Form.Item
                    name="tokenFee"
                    rules={[
                      {
                        pattern: /^[0-9]*$/,
                        message: "Please enter only numeric values",
                      },

                      {
                        required: true,
                        message: "Please input Token Fee!",
                      },
                    ]}
                  >
                    <div>
                      <h4>Token Fee</h4>
                      <Input
                        value={values.tokenFee}
                        onChange={(e) =>
                          setValues({ ...values, tokenFee: e.target.value })
                        }
                        type="tel"
                        placeholder="Token Fee"
                        style={{ height: 55 }}
                        maxLength={5}
                      />
                    </div>
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <h4>Date</h4>
                  <Form.Item
                    name="dates"
                    rules={[
                      { required: true, message: "Please select a date range" },
                      { validator: validateDateRange },
                    ]}
                    validateTrigger={["onChange", "onBlur"]}
                  >
                    <RangePicker
                      defaultValue={view !== "add" ? [sDate, eDate] : undefined}
                      onChange={(val) => console.log(val)}
                      style={{ width: "100%", height: 55 }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            ) : (
              <></>
            )}
            {bannerType === "SEASON_BANNER" ? (
              <Row gutter={16}>
                <Col xs={24} md={12}>
                  <Form.Item
                    name="time"
                    rules={[
                      {
                        // required: true,
                        message: "Please input Time !",
                        whitespace: true,
                      },
                    ]}
                  >
                    <div>
                      <h4>Time</h4>

                      <TimePicker
                        placeholder="Start Time"
                        onChange={handleTimeChange}
                        value={startTime}
                        format="HH:mm"
                        style={{ width: "49%", height: 55 }}
                      />
                      <TimePicker
                        placeholder="End Time"
                        onChange={handleEndTimeChange}
                        value={endTime}
                        format="HH:mm"
                        style={{ width: "49%", height: 55, left: 10 }}
                      />
                    </div>
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item name="addTournaments">
                    <div>
                      <h4>Select Tournament</h4>
                      <Select
                        maxTagCount="responsive"
                        mode="multiple"
                        onChange={(val) => handleChange(val)}
                        value={selectedTournament}
                        style={{ height: 60, width: "100%", overflow: "auto" }}
                        placeholder="Select Tournament"
                        showSearch
                        optionFilterProp="label" // Set the property to use for filtering options
                        filterOption={(input, option) =>
                          (
                            option?.label?.toString()?.toLowerCase() || ""
                          ).indexOf(input.toLowerCase()) !== -1
                        }
                      >
                        {activeTournamentList?.map((item, index) => {
                          return (
                            <>
                              {view === "add" ? (
                                !item?.isLive &&
                                !item?.isFinished &&
                                item?.isActive ? (
                                  <Option
                                    key={index}
                                    value={item?._id}
                                    label={item?.name}
                                  >
                                    {item?.name as any}
                                  </Option>
                                ) : null
                              ) : (
                                <Option
                                  disabled={
                                    !item?.isLive &&
                                    !item?.isFinished &&
                                    item?.isActive
                                      ? false
                                      : true
                                  }
                                  key={index}
                                  value={item?._id}
                                  label={item?.name}
                                >
                                  {item?.name as any}
                                </Option>
                              )}
                            </>
                          );
                        })}
                      </Select>
                    </div>
                  </Form.Item>
                </Col>
              </Row>
            ) : (
              <></>
            )}
            <Row gutter={16}>
              <Col xs={24} md={12}>
                <Form.Item name="bannerDescription">
                  <div>
                    <h4>Banner Description</h4>

                    {bannerDescription?.length ? (
                      <EditText
                        content={bannerDescription}
                        setContent={setBannerDescription}
                      />
                    ) : null}
                  </div>
                </Form.Item>
              </Col>

              <Col xs={24} md={12}>
                <Form.Item name="description">
                  <div>
                    <h4>Description</h4>

                    {description?.length ? (
                      <EditText
                        content={description}
                        setContent={setDescription}
                      />
                    ) : null}
                  </div>
                </Form.Item>
              </Col>
            </Row>

            <Form.Item wrapperCol={{}}>
              <div style={{ marginTop: 10 }}>
                <Button
                  style={{
                    background: "#ECAC35",
                    width: 180,
                    height: 50,
                    margin: "auto",
                    color: "black",
                    fontWeight: 500,
                  }}
                  type="primary"
                  htmlType="submit"
                  loading={addBannersData.isLoading || editBannerData.isLoading}
                >
                  Save
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      </Card>
    </div>
  );
};

export default SeasonalCompetitionForm;
