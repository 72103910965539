import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const LineChart = ({ data, roundOff }: { data: any; roundOff: number }) => {
  const options = {
    responsive: true,
    tension: 0.5,
    scales: {
      y: {
        min: 0,
        max: roundOff,
      },
    },
    plugins: {
      legend: {
        display: true,
      },
    },
  };
  return <Line options={options} data={data} />;
};

export default LineChart;
