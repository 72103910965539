import { Card, Col, Input, Row } from "antd";

import { EyeOutlined, SearchOutlined } from "@ant-design/icons";
import Table, { ColumnsType } from "antd/es/table";

import { useNavigate } from "react-router-dom";
import moment from "moment";

const MatchDetailsTable = (tableData: any) => {
  const navigate = useNavigate();

  interface DataType {
    teams: any;
    fixture: any;
    key: string;
    points: string;
    userName: string;
    winners: string;
    rank: string;
    name: string;
  }

  const columns: ColumnsType<DataType> = [
    {
      title: "#",
      key: "rank",
      dataIndex: "rank",
      align: "center",
      width: "10%",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Team A",
      dataIndex: "id",
      key: "id",
      align: "center",
      render: (_, record) => (
        <div>
          <span>{`${record?.teams?.away?.name || "N/A"}`}</span>
        </div>
      ),
    },
    {
      title: "Team B",
      dataIndex: "teamB",
      key: "teamB",
      align: "center",
      render: (_, record) => (
        <div>
          <span>{`${record?.teams?.home?.name || "N/A"}`}</span>
        </div>
      ),
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      align: "center",
      render: (_, record) => (
        <div>
          <span>{`${
            moment(record?.fixture?.date).format("YYYY-MM-DD") || "N/A"
          }`}</span>
        </div>
      ),
    },
    {
      title: "Time",
      dataIndex: "time",
      key: "time",
      align: "center",
      render: (_, record) => (
        <div>
          <span>{`${
            moment(record?.fixture?.date).format("HH:mm") || "N/A"
          }`}</span>
        </div>
      ),
    },
  ];

  return (
    <div style={{}}>
      <Card>
        <div className="srch">
          <Row style={{ paddingTop: 10, paddingBottom: 10 }} gutter={16}></Row>

          <Table columns={columns} dataSource={tableData?.tableData} />
        </div>
      </Card>
    </div>
  );
};

export default MatchDetailsTable;
