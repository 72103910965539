import { Button, Card, Col, Row, Spin } from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useLazyGetLeaderBoardsDetailsByIdQuery } from "../../../services/banners";

const LeaderBoardDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [leaderBoarddetails, setLeaderBoardDetails] =
    useState<LeaderBoardTypes>();
  const [getDetails, getDetailsData] = useLazyGetLeaderBoardsDetailsByIdQuery();

  const getLeaderBoardDetails = async () => {
    try {
      const res = await getDetails(id).unwrap();

      if (res?.statusCode === 200) {
        setLeaderBoardDetails(res?.data);
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    getLeaderBoardDetails();
  }, []);

  return (
    <div style={{ padding: 50, gap: 20, display: "grid" }}>
      <Spin spinning={getDetailsData.isLoading}>
        <Card>
          <div
            style={{
              alignItems: "center",
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            <h2>User details</h2>
            <Button
              style={{
                background: "#ECAC35",
                width: 100,
                height: 40,
                color: "black",
              }}
              type="primary"
              // htmlType="submit"
              onClick={() => {
                navigate("/manageLeaderboard", { replace: true });
              }}
            >
              Back
            </Button>
          </div>

          <div>
            <Row gutter={16}>
              <Col xs={24} md={12}>
                <h4>Profile image</h4>
                <div
                  style={{
                    display: "flex",
                  }}
                >
                  <img
                    style={{
                      height: 100,
                      // margin: "auto",
                      borderRadius: 100,
                    }}
                    src={
                      leaderBoarddetails?.userId?.image ||
                      "/static/images/dummy.png"
                    }
                    alt=""
                  />
                </div>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col xs={20} md={8}>
                <h4>Full Name</h4>
                <h5>
                  {leaderBoarddetails?.userId?.firstName}{" "}
                  {leaderBoarddetails?.userId?.lastName}
                </h5>
              </Col>
              <Col xs={20} md={8}>
                <h4>Username</h4>
                <h5>{leaderBoarddetails?.userId?.userName || ""}</h5>
              </Col>
              <Col xs={20} md={8}>
                <h4>Email</h4>
                <h5>{leaderBoarddetails?.userId?.email}</h5>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col xs={20} md={8}>
                <h4>Player Points</h4>
                <h5>{leaderBoarddetails?.totalPoints || 0}</h5>
              </Col>

              <Col xs={20} md={8}>
                <h4>Winning Matches</h4>
                <h5>{leaderBoarddetails?.winningMatches || 0}</h5>
              </Col>
              <Col xs={20} md={8}>
                <h4>Phone Number</h4>
                <h5>
                  {leaderBoarddetails?.userId?.countryCode}{" "}
                  {leaderBoarddetails?.userId?.phone}
                </h5>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col xs={20} md={8}>
                <h4>Earned Tokens</h4>
                <h5>{leaderBoarddetails?.totalTokens || 0}</h5>
              </Col>
            </Row>
          </div>
        </Card>
      </Spin>
    </div>
  );
};

export default LeaderBoardDetails;
