import { Card, Col, Input, Row, Button, Space, Modal } from "antd";
import { ContentHeader } from "../../components";
import { DeleteOutlined, EditFilled, SearchOutlined } from "@ant-design/icons";
import Table, { ColumnsType } from "antd/es/table";

import { useNavigate } from "react-router-dom";
import {
  useDeleteTokenCategoryMutation,
  useLazyGetTokenQuery,
} from "../../services/auth";
import { useEffect, useState } from "react";
import { successToast } from "../../helpers";
import useAuth from "../../hooks/useAuth";

const ManageTokensCategories = () => {
  const navigate = useNavigate();
  const [query, setquery] = useState("");
  const [totalCount, setTotalCount] = useState<number>();
  const [currentPage, setCurrentPage] = useState<number>(1);

  const [allTokenList, setAllTokenList] = useState([]);
  const [tokenList, tokenListData] = useLazyGetTokenQuery();
  const [deleteTokenCategory, deleteTokenCategoryData] =
    useDeleteTokenCategoryMutation();

  const [hidePermission, setHidePermission] = useState<any>({
    isAdd: true,
    isDelete: true,
  });

  const userData = useAuth();

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    getTokenCategoryDetails(page);
  };
  interface DataType {
    _id: string;
    key: string;
    name?: string;
    tokens?: string;
  }
  const { confirm } = Modal;

  const showDeleteConfirm = (record: any) => {
    confirm({
      title: "Hold on",
      content: "Are you sure delete this Token Category?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        handleDelete(record?._id);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const checkPermission = () => {
    const permissions = userData?.permissions;
    if (permissions?.length) {
      let idx = -1;
      idx = permissions?.findIndex(
        (ele: any) => ele?.label === "Manage Token Categories"
      );
      if (idx != -1) {
        if (!permissions[idx]?.isAdd) {
          let data = {
            isAdd: false,
            isDelete: hidePermission?.isDelete,
          };
          setHidePermission(data);
        }
        if (!permissions[idx]?.isDelete) {
          let data = {
            isAdd: hidePermission?.isAdd,
            isDelete: false,
          };
          setHidePermission(data);
        }
      }
    }
  };

  useEffect(() => {
    if (userData && userData?.permissions?.length) {
      checkPermission();
    }
  }, [userData]);
  const columns: ColumnsType<DataType> = [
    {
      title: "Token Category Name",
      dataIndex: "name",
      key: "name",
      align: "center",
    },
    {
      title: "Number Of Tokens",
      key: "tokens",
      dataIndex: "tokens",
      align: "center",
    },

    {
      title: "Action",
      key: "action",
      align: "center",
      render: (_, record) => (
        <Space size="middle">
          {hidePermission?.isAdd ? (
            <Button
              onClick={(e) => {
                e.preventDefault();
                navigate(`/managetokenscategories/${record?._id}`);
              }}
            >
              <EditFilled style={{ color: "#C69320" }} />
            </Button>
          ) : null}
          {hidePermission?.isDelete ? (
            <Button
              onClick={(e) => {
                e.preventDefault();
                showDeleteConfirm(record);
              }}
            >
              <DeleteOutlined style={{ color: "#d4380d" }} />
            </Button>
          ) : null}
        </Space>
      ),
    },
  ];

  const getTokenCategoryDetails = async (page: number) => {
    try {
      const res = await tokenList({
        page: page,
        size: 5,
        query: query || "",
      }).unwrap();

      if (res?.statusCode === 200) {
        setAllTokenList(res?.data?.data);
        setTotalCount(res?.data?.count);
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (query === "") {
      getTokenCategoryDetails(1);
    }
  }, [query]);

  const pagination = {
    pageSize: 10,
    current: currentPage,
    total: totalCount,
    onChange: handlePageChange,
  };

  const handleDelete = async (id: string) => {
    try {
      const res = await deleteTokenCategory({ Token_id: id }).unwrap();

      if (res?.statusCode === 200) {
        successToast("Category Deleted Successfully");
        getTokenCategoryDetails(1);
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  return (
    <div style={{ padding: 40 }}>
      <ContentHeader
        buttonText={hidePermission?.isAdd ? "Add Category" : ""}
        buttonNavigateTo="managetokenscategories/add"
        heading="Manage Tokens Categories"
      />
      <Card>
        <div className="srch">
          <Row style={{ paddingTop: 20, paddingBottom: 20 }} gutter={16}>
            <Col style={{ display: "flex", gap: 20 }} xs={24} md={7}>
              <Input
                placeholder="Search"
                prefix={<SearchOutlined style={{ fontSize: "24px" }} />}
                style={{ height: 50, borderRadius: 10 }}
                value={query}
                onChange={(e) => setquery(e.target.value)}
              />
              <Button
                style={{
                  background: "#ECAC35",
                  width: 100,
                  height: 40,
                  margin: "auto",
                  color: "black",
                }}
                type="default"
                htmlType="button"
                onClick={() => getTokenCategoryDetails(1)}
              >
                Search
              </Button>
            </Col>
          </Row>

          <Table
            columns={columns}
            dataSource={allTokenList}
            pagination={pagination}
            rowKey="_id"
            loading={tokenListData.isLoading}
          />
        </div>
      </Card>
    </div>
  );
};

export default ManageTokensCategories;
