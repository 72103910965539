import { Modal, Input, Button } from "antd";
import { useEffect, useState } from "react";
import { errorToast, successToast } from "../helpers";
import {
  useEditReceiverByIdMutation,
  useLazyGetReceiverDetailsByIdQuery,
} from "../services/tournaments";
import { EditFilled } from "@ant-design/icons";

type ModalTypes = {
  apiHit: () => void;
  id: string;
};

const AddReceiverModal = ({ apiHit, id }: ModalTypes) => {
  const [receiver, setReceiver] = useState<string>();
  const [visible, setVisible] = useState(false);
  const [addReceiver, addReceiverData] = useEditReceiverByIdMutation();

  const [getReceiverById, getReceiverByIdData] =
    useLazyGetReceiverDetailsByIdQuery();

  const getReceiverDetails = async () => {
    try {
      const res = await getReceiverById(id).unwrap();

      if (res?.statusCode === 200) {
        setReceiver(res?.data?.name);
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (visible) {
      getReceiverDetails();
    }
  }, [visible]);

  const editReceiverDetails = async () => {
    if (!receiver?.length) {
      errorToast("Please input receiver name");
    } else {
      let body = {
        name: receiver,
      };
      try {
        const res = await addReceiver({ body, Id: id }).unwrap();
        if (res?.statusCode === 200) {
          setVisible(false);
          apiHit();
          setReceiver("");
          successToast("Receiver updated successfully");
        }
      } catch (error: any) {
        console.log(error);
      }
    }
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <div>
      <Button onClick={() => setVisible(true)}>
        <EditFilled style={{ color: "#C69320" }} />
      </Button>
      <Modal
        style={{}}
        title="Edit Receiver"
        visible={visible}
        onCancel={handleCancel}
        footer={[
          <Button
            loading={addReceiverData.isLoading}
            style={{
              background: "#ECAC35",
              height: 35,
              margin: "auto",
              color: "black",
            }}
            onClick={() => editReceiverDetails()}
          >
            Edit
          </Button>,
        ]}
      >
        <Input
          value={receiver}
          placeholder="Edit Receiver"
          style={{ height: 43, width: "100%" }}
          onChange={(e) => {
            setReceiver(e.target.value);
          }}
        />
      </Modal>
    </div>
  );
};

export default AddReceiverModal;
