import AuthWrapper from "../../../layout/authWrapper";
import "./login.css";
import { Button, Card, Checkbox, Form, Input } from "antd";
import { useNavigate } from "react-router-dom";
import { generateSEKAndHash } from "../../../Crypto";
import { usePostLoginMutation } from "../../../services/auth";
import {
  STORAGE_KEYS,
  errorToast,
  getData,
  storeData,
  successToast,
} from "../../../helpers";
import { useAppDispatch } from "../../../hooks/store";
import { setCredentials } from "../../../reducers/authSlice";
import { useEffect, useState } from "react";
import { removeItem } from "../../../helpers/constants/storage";

const Login = () => {
  const [postLogin, postLoginData] = usePostLoginMutation();
  const [isRemember, setisRemember] = useState(false);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const data = generateSEKAndHash();
  const deviceId = navigator.userAgent;

  const onFinish = async (values: any) => {
    const body = {
      email: values.email,
      password: values.password,
      hash: data?.hash,
      appKey: data?.appkey,
      sek: data?.sek,
      deviceId: deviceId,
    };
    if (isRemember) {
      storeData(STORAGE_KEYS.credentials, JSON.stringify(body));
    } else {
      await removeItem(STORAGE_KEYS.credentials);
    }

    try {
      const res = await postLogin(body).unwrap();

      if (res?.statusCode === 200) {
        storeData(STORAGE_KEYS.token, JSON.stringify(res?.data?.token));
        storeData(STORAGE_KEYS.userData, JSON.stringify(res?.data));
        dispatch(
          setCredentials({
            user: res?.data || null,
            token: res?.data?.token || null,
          })
        );
        navigate("/dashboard", { replace: true });
        successToast(res?.message);
      } else {
        errorToast(res?.message);
      }
    } catch (error: any) {
      errorToast(error?.data?.message);
    }
  };

  const onSelectChange = async () => {
    const creds = getData(STORAGE_KEYS.credentials);

    if (creds) {
      const credentials = await JSON.parse(`${creds}`);
      form.setFieldsValue({
        email: credentials?.email,
        password: credentials?.password,
      });
      setisRemember(true);
    }
  };

  useEffect(() => {
    onSelectChange();
  }, []);

  return (
    <AuthWrapper>
      <Card className="login_card">
        <div className="login_img">
          <img src="/static/images/logo.png" alt="" />
        </div>
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          style={{
            maxWidth: 460,
            marginTop: 40,
            // display: "flex",
            justifyContent: "center",
          }}
          form={form}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          //   onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: "Please input your email!",
              },
              {
                type: "email",
                message: "The input is not valid email!",
              },
            ]}
          >
            <Input
              style={{ width: "460px", height: "50px" }}
              placeholder="Email"
            />
          </Form.Item>

          <Form.Item
            // label="Password"
            style={{ width: "550px" }}
            name="password"
            rules={[
              { required: true, message: "Please input your old password!" },
              {
                pattern:
                  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                message:
                  "Must contain 8 or more characters, one uppercase, one lowercase, one special character and one number.",
              },
            ]}
          >
            <Input.Password
              style={{ width: "460px", height: "50px" }}
              placeholder="Password"
            />
          </Form.Item>

          <Form.Item name="remember" valuePropName="checked">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "460px",
              }}
            >
              <Checkbox
                onChange={() => setisRemember(!isRemember)}
                checked={isRemember}
                style={{ color: "#ffff", fontWeight: 600 }}
              >
                Remember me
              </Checkbox>
              <div
                style={{ cursor: "pointer", color: "#ffff", fontWeight: 600 }}
                onClick={() => {
                  navigate("/forgotpassword");
                }}
              >
                Forgot password?
              </div>
            </div>
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
            <div style={{ display: "flex" }}>
              <Button
                style={{
                  background: "#ECAC35",
                  width: 150,
                  height: 50,
                  margin: "auto",
                  color: "black",
                  fontWeight: 600,
                }}
                type="primary"
                htmlType="submit"
                loading={postLoginData.isLoading}
              >
                Login
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Card>
    </AuthWrapper>
  );
};

export default Login;
