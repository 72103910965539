import { Card, Col, Input, Row, Button, Space, Modal } from "antd";
import { ContentHeader } from "../../components";
import { DeleteOutlined, EditFilled, SearchOutlined } from "@ant-design/icons";
import Table, { ColumnsType } from "antd/es/table";

import { useNavigate } from "react-router-dom";

const ManageBanners = () => {
  const navigate = useNavigate();

  interface DataType {
    key: string;
    competition: string;
    matchDate: string;
    matchVenue: string;
    nunberOfTokens: string;
    winners: string;
    title: string;
    name: any;
  }
  const { confirm } = Modal;

  const showDeleteConfirm = () => {
    confirm({
      title: "Hold on",
      content: "Are you sure delete this Banner?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        console.log("OK");
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "Banner Image",
      dataIndex: "name",
      key: "name",
      align: "center",
    },
    {
      title: "Title",
      key: "title",
      dataIndex: "title",
      align: "center",
      width: "10%",
    },
    {
      title: "Competition",
      dataIndex: "competition",
      key: "competition",
      align: "center",
    },
    {
      title: "Match Date",
      dataIndex: "matchDate",
      key: "matchDate",
      align: "center",
    },
    {
      title: "Match Venue",
      dataIndex: "matchVenue",
      key: "matchVenue",
      align: "center",
    },

    {
      title: "Action",
      key: "action",
      align: "center",
      render: (_, record) => (
        <Space size="middle">
          <Button
            onClick={(e) => {
              e.preventDefault();
              navigate(`/banners/edit`);
            }}
          >
            <EditFilled style={{ color: "#C69320" }} />
          </Button>
          <Button
            onClick={(e) => {
              e.preventDefault();
              showDeleteConfirm();
            }}
          >
            <DeleteOutlined style={{ color: "#d4380d" }} />
          </Button>
        </Space>
      ),
    },
  ];

  const data: DataType[] = [
    {
      key: "1",
      name: (
        <img src="/static/images/selfie.png" alt="" height={70} width={90} />
      ),
      matchDate: "15/7/2024",
      matchVenue: "America ground club of FB",
      nunberOfTokens: "0.33",
      winners: "3",
      title: "Lorem ....",
      competition: "Competition1",
    },
    {
      key: "2",
      name: (
        <img src="/static/images/selfie.png" alt="" height={70} width={90} />
      ),
      matchDate: "15/7/2024",
      matchVenue: "America ground club of FB",
      nunberOfTokens: "5.53",
      winners: "2",
      title: "Lorem ....",
      competition: "Competition2",
    },
    {
      key: "3",
      name: (
        <img src="/static/images/selfie.png" alt="" height={70} width={90} />
      ),
      matchDate: "15/7/2024",
      matchVenue: "America ground club of FB",
      nunberOfTokens: "20.5",
      winners: "4",
      title: "Lorem ....",
      competition: "Competition3",
    },
    {
      key: "4",
      name: (
        <img src="/static/images/selfie.png" alt="" height={70} width={90} />
      ),
      matchDate: "15/7/2024",
      matchVenue: "America ground club of FB",
      nunberOfTokens: "20.5",
      winners: "4",
      title: "Lorem ....",
      competition: "Competition4",
    },
    {
      key: "5",
      name: (
        <img src="/static/images/selfie.png" alt="" height={70} width={90} />
      ),
      matchDate: "15/7/2024",
      matchVenue: "America ground club of FB",
      nunberOfTokens: "50",
      winners: "2",
      title: "Lorem ....",
      competition: "Competition5",
    },
  ];

  return (
    <div style={{ padding: 50 }}>
      <ContentHeader
        heading="Manage Banners"
        buttonNavigateTo="banners/add"
        buttonText="Add Banner"
      />
      <Card>
        <div className="srch">
          <Row style={{ paddingTop: 10, paddingBottom: 10 }} gutter={16}>
            <Col xs={24} md={7}>
              <Input
                placeholder="Search"
                prefix={<SearchOutlined style={{ fontSize: "24px" }} />}
                style={{ height: 55, borderRadius: 10 }}
              />
            </Col>
          </Row>

          <Table columns={columns} dataSource={data} />
        </div>
      </Card>
    </div>
  );
};

export default ManageBanners;
