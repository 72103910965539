import { useState, useEffect } from "react";
import { Button, Card } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import { useNavigate } from "react-router-dom";
import { useLazyGetNotificationsQuery } from "../../../services/addNotifications";
import moment from "moment";

const NotificationsHistory = () => {
  const navigate = useNavigate();
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    getNotificationsDetails(page);
  };

  const [query, setQuery] = useState("");
  const [totalCount, setTotalCount] = useState<number>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [notificationList, setNotificationList] = useState([]);
  const [notifications, notificationsData] = useLazyGetNotificationsQuery();

  const getNotificationsDetails = async (page: number) => {
    try {
      const res = await notifications({
        page: page,
        size: 5,
        query: query || "",
      }).unwrap();

      if (res?.statusCode === 200) {
        setNotificationList(res?.data?.data);
        setTotalCount(res?.data?.count);
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (query === "") {
      getNotificationsDetails(1);
    }
  }, [query]);

  interface DataType {
    key: string;
    title: string;
    message: string;
    serial: number;
    createdAt: string;
  }

  const columns: ColumnsType<DataType> = [
    {
      title: "S.No.",
      dataIndex: "serial",
      key: "serial",
      width: "5%",
      align: "center",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      width: "15%",
    },
    {
      title: "Description",
      dataIndex: "message",
      key: "message",
      width: "60%",
    },
    {
      title: "Time",
      dataIndex: "createdAt",
      key: "createdAt",
      width: "20%",
      render: (_, record) => (
        <div>
          <span>
            {moment(record?.createdAt).format("MMMM Do YYYY, h:mm a")}
          </span>
        </div>
      ),
    },
  ];

  const pagination = {
    pageSize: 10,
    current: currentPage,
    total: totalCount,
    onChange: handlePageChange,
  };

  return (
    <div style={{ padding: 50 }}>
      <Card>
        <div>
          <div
            style={{
              alignItems: "center",
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            <h2>Notification History</h2>
            <Button
              style={{
                background: "#ECAC35",
                width: 100,
                height: 40,
                color: "black",
              }}
              type="primary"
              onClick={() => {
                navigate("/notifications", { replace: true });
              }}
            >
              Back
            </Button>
          </div>
          <Table
            columns={columns}
            dataSource={notificationList}
            pagination={pagination}
            loading={notificationsData.isLoading}
          />
        </div>
      </Card>
    </div>
  );
};

export default NotificationsHistory;
