import { Button, Card, Col, Row, Spin, Table } from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import type { ColumnsType } from "antd/es/table";

import { useLazyGetPredictionForTournamentsQuery } from "../../../services/tournaments";
import { StarFilled } from "@ant-design/icons";

const PredictionDetailsForTournament = () => {
  const navigate = useNavigate();
  const { id, view, username } = useParams();
  const [predictionsList, setPredictionsList] = useState<any[] | undefined>();
  const [getDetails, getDetailsData] =
    useLazyGetPredictionForTournamentsQuery();

  interface DataType {
    homeTeam: string;
    awayTeam: string;
    homeTeam_goals: number | undefined;
    awayTeam_goals: number | undefined;
    key: string;
    isFavourite: boolean | undefined;
  }

  const columns: ColumnsType<DataType> = [
    {
      title: "#",
      key: "rank",
      dataIndex: "rank",
      align: "center",

      render: (text, record, index) => index + 1,
    },
    {
      title: "Teams",
      key: "rank",
      dataIndex: "rank",
      align: "center",

      render: (_, record) => (
        <div>
          <span style={{ fontWeight: 500 }}>
            {`${record?.homeTeam} / ${record?.awayTeam}` || "-"}
          </span>
        </div>
      ),
    },

    {
      title: "Predictions",
      dataIndex: "points",
      key: "points",
      align: "center",

      render: (_, record) => (
        <div>
          <span style={{ fontWeight: 600 }}>
            {`${record?.homeTeam_goals} : ${record?.awayTeam_goals}` || "-"}
          </span>
          {record?.isFavourite ? (
            <StarFilled
              style={{ color: "#ECAC35", fontSize: 16, marginLeft: 8 }}
            />
          ) : (
            ""
          )}
        </div>
      ),
    },
  ];

  const getPredictionDetails = async () => {
    try {
      const res = await getDetails({
        tournamentId: id || "",
        categoryId: view || "",
        username: username || "",
      }).unwrap();

      if (res?.statusCode === 200) {
        setPredictionsList(res?.data);
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    getPredictionDetails();
  }, []);

  return (
    <div style={{ padding: 50, gap: 20, display: "grid" }}>
      <Spin spinning={getDetailsData.isLoading}>
        <Card>
          <div
            style={{
              alignItems: "center",
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            <h2>Prediction details</h2>
            <Button
              style={{
                background: "#ECAC35",
                width: 100,
                height: 40,
                color: "black",
              }}
              type="primary"
              // htmlType="submit"
              onClick={() => {
                navigate(`/managetournaments/tournamentdetails/${id}`, {
                  replace: true,
                });
              }}
            >
              Back
            </Button>
          </div>

          <div>
            <Row gutter={16}></Row>

            <Row gutter={16}>
              <Col xs={20} md={8}>
                <h4>Username</h4>

                <h5>{username || ""}</h5>
              </Col>
              <Col xs={20} md={8}>
                <h4>Category Name</h4>
                {predictionsList && predictionsList.length > 0 ? (
                  <h5>{predictionsList[0]?.tokenCategory?.name}</h5>
                ) : (
                  <h5>No category name available</h5>
                )}
              </Col>
            </Row>
          </div>
          <div style={{ marginTop: 20 }}>
            <Table
              className="tableSelector"
              style={{ fontSize: 10 }}
              columns={columns}
              dataSource={predictionsList}
              loading={getDetailsData.isLoading}
            />
          </div>
        </Card>
      </Spin>
    </div>
  );
};

export default PredictionDetailsForTournament;
