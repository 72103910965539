import { useState } from "react";
import { Button, Card, Tabs, TabsProps } from "antd";

import UsersReport from "./UsersReport";
import {
  PlayerPredictionTable,
  ReportsPredictionTable,
} from "../../components/tables";
import { useNavigate, useParams } from "react-router-dom";

const AllReports = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("1");
  const { id } = useParams();

  const onChange = (key: string) => {
    setActiveTab(key);
  };

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: `Predictions`,
      children: <ReportsPredictionTable id={id} />,
    },
    {
      key: "2",
      label: `Earnings`,
      children: <UsersReport id={id} />,
    },
  ];
  return (
    <div style={{ padding: 40, marginTop: 10 }}>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: 20,
        }}
      >
        <Button
          style={{
            background: "#ECAC35",
            height: 45,
            width: 95,
            color: "black",
          }}
          type="primary"
          onClick={() => navigate("/reports")}
        >
          Back
        </Button>
      </div>
      <Card>
        <Tabs
          activeKey={activeTab}
          defaultActiveKey="1"
          items={items}
          onChange={onChange}
        />
      </Card>
    </div>
  );
};

export default AllReports;
