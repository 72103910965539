import { Layout, Col, Row, Spin, Card } from "antd";
import "./dashboard.css";
import {
  DollarCircleOutlined,
  TeamOutlined,
  PayCircleOutlined,
  TrophyOutlined,
} from "@ant-design/icons";
import { DashboardCard } from "../../components";

import UpcomingTournamentsTable from "../../components/tables/UpcomingTournamentsTable";
import useTranslation from "../../hooks/Translation";
import { RevanueChart, UsersChart } from "../../components/charts";
import { useLazyGetDashboardQuery } from "../../services/users";
import { useEffect, useState } from "react";
import { TokensInPlayTable } from "../../components/tables";

const Dashboard = () => {
  const { Content } = Layout;
  const translation = useTranslation();
  const [dashboardDetails, dashboardDetailsData] = useLazyGetDashboardQuery();
  const [details, setDetails] = useState<DashboardTypes>();
  const [selectedFilter, setSelectedFilter] = useState("YEAR");
  const [selectedFilterUser, setSelectedFilterUser] = useState("MONTH");
  const [tokensInPLayTable, setTokensInPLayTable] = useState([]);
  const [names, setNames] = useState<string[]>();
  const [name, setName] = useState<string | undefined>();
  const [revanueNames, setRevanueNames] = useState<string[]>();
  const [inPlayingTokens, setInPlayingTokens] = useState(0);

  const [graphValues, setGraphValues] = useState<string[]>([]);
  const [revanueGraphValues, setRevanueGraphValues] = useState<string[]>([]);

  const handleFilterChange = (value: any) => {
    setSelectedFilter(value);
  };
  const handleFilterChangeUser = (value: any) => {
    setSelectedFilterUser(value);
  };

  let number = details?.data?.totalEarnings;
  let roundedNumber = number?.toFixed(2);

  const STATS = [
    {
      id: 1,
      name: "Total Earnings",
      icon: <DollarCircleOutlined style={{ color: "green" }} />,
      number: roundedNumber || 0,
      prefix: "$",
    },
    {
      id: 2,
      name: "Total Tournaments",
      icon: <TrophyOutlined style={{ color: "rgb(255, 120, 45)" }} />,
      number: details?.data?.totalTournaments || 0,
      prefix: "#",
    },
    {
      id: 3,
      name: "Total Users/Players",
      icon: <TeamOutlined style={{ color: "rgb(255, 170, 44)" }} />,
      number: details?.data?.totalUsers || 0,
      prefix: "#",
    },
  ];

  const graphData = {
    labels: revanueNames,
    datasets: [
      {
        label: "Revenue",
        data: revanueGraphValues,
        borderColor: "#ECAC35",
        backgroundColor: "#C69320",
      },
    ],
  };

  const graphCustomerData = {
    labels: names,
    datasets: [
      {
        label: "Users/Players",
        data: graphValues,
        borderColor: "#ECAC35",
        backgroundColor: "#C69320",
      },
    ],
  };

  //

  const showDataHandler = async () => {
    try {
      const payload = await dashboardDetails({
        type: selectedFilterUser,
        type2: selectedFilter,
      }).unwrap();

      const details = payload?.data;
      if (payload?.statusCode === 200) {
        setDetails(payload?.data);
        setTokensInPLayTable(payload?.data?.tokensInPlayData);

        let playingTokens = 0;

        for (const obj of payload?.data?.tokensInPlayData) {
          if (obj.totalTokens) {
            setInPlayingTokens((playingTokens += obj.totalTokens));
          }
        }

        if (name === "left") {
          const revanueTotals = details?.revenue?.map(
            (item: { total: number }) => item.total
          );
          const revanueNames = details?.revenue?.map(
            (item: { name: string }) => item.name
          );
          setRevanueNames([...(revanueNames || [])]);
          setRevanueGraphValues([...(revanueTotals || [])]);
        } else if (name === "right") {
          const userTotals = details?.users?.map(
            (item: { total: number }) => item.total
          );
          const userNames = details?.users?.map(
            (item: { name: string }) => item.name
          );
          setNames([...(userNames || [])]);
          setGraphValues([...(userTotals || [])]);
        } else {
          const userTotals = details?.users?.map(
            (item: { total: number }) => item.total
          );
          const userNames = details?.users?.map(
            (item: { name: string }) => item.name
          );
          setNames([...(userNames || [])]);
          setGraphValues([...(userTotals || [])]);

          const revanueTotals = details?.revenue?.map(
            (item: { total: number }) => item.total
          );
          const revanueNames = details?.revenue?.map(
            (item: { name: string }) => item.name
          );

          setRevanueNames([...(revanueNames || [])]);
          setRevanueGraphValues([...(revanueTotals || [])]);
        }
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  function handleScroll() {
    window.scroll({
      top: document.body.offsetHeight,
      left: 0,
      behavior: "smooth",
    });
  }

  useEffect(() => {
    showDataHandler();
  }, [selectedFilter]);
  useEffect(() => {
    showDataHandler();
  }, [selectedFilterUser]);

  return (
    <Content
      style={{
        margin: "0px 10px",
        padding: 35,
        minHeight: 280,
      }}
    >
      <Spin spinning={dashboardDetailsData.isLoading}>
        <div style={{ fontSize: 18 }}>
          <h1>{translation.Globals.dashboard}</h1>
        </div>

        <div className="top_bar">
          {STATS.map((item, index) => (
            <DashboardCard data={item} key={index} />
          ))}
          <Card
            style={{ cursor: "pointer" }}
            onClick={handleScroll}
            className="stat_card"
          >
            <div
              style={{
                alignItems: "center",
                margin: "auto",
              }}
            >
              <div className="card_icon">
                <div>
                  {<PayCircleOutlined style={{ color: "rgb(0, 173, 163)" }} />}
                </div>
                <div>
                  <div className="card_heading1">Tokens In Play</div>
                  <div className="card_heading2">
                    # {`${inPlayingTokens || 0}`}
                  </div>
                </div>
              </div>
            </div>
          </Card>
        </div>

        <div
          style={{
            margin: "20px 0px",
          }}
        >
          <Row gutter={16}>
            <Col xs={24} md={12}>
              <RevanueChart
                graphData={graphData}
                onFilterChange={handleFilterChange}
                setName={setName}
                details={details}
              />
            </Col>
            <Col xs={24} md={12}>
              <UsersChart
                graphCustomerData={graphCustomerData}
                onFilterChange={handleFilterChangeUser}
                setName={setName}
              />
            </Col>
          </Row>
        </div>
        <div style={{ fontSize: 17 }}>
          <h1>Upcoming Tournaments</h1>
        </div>
        <UpcomingTournamentsTable />
        <div style={{ fontSize: 17 }}>
          <h1>Tokens In Play</h1>
        </div>
        <TokensInPlayTable tableData={tokensInPLayTable} />
      </Spin>
    </Content>
  );
};

export default Dashboard;
