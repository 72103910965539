import { Button, Card, Col, Form, Input, Row, Upload } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { UploadOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { ContentHeader } from "../../../components";
import { UploadMedia } from "../../../utils/mediaUpload";
import { errorToast, successToast } from "../../../helpers";
import { RcFile } from "antd/es/upload";

import {
  LazyLoadImage,
  ScrollPosition,
  trackWindowScroll,
} from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import {
  useLazyGetUserDataQuery,
  usePutUpdateUserMutation,
} from "../../../services/users";

const UsersForm = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { id } = useParams();
  const [getUser, getUserData] = useLazyGetUserDataQuery();

  const [values, setValues] = useState({
    firstName: "",
    lastName: "",
    userName: "",
    email: "",
    address: "",
  });
  const [profileImage, setProfileImage] = useState("");
  const [editUser, editUserData] = usePutUpdateUserMutation();
  const [phoneCode, setPhoneCode] = useState("+91");
  const [phoneNo, setphoneNo] = useState("");

  const handleChangePhone = (phone: any, country: any) => {
    setPhoneCode(country?.dialCode);
    setphoneNo(phone?.replace(country.dialCode, ""));
  };

  const onFinish = async (values: any) => {
    try {
      let body = {
        firstName: values.firstName,
        lastName: values.lastName,
        userName: values.userName,
        email: values.email,
        countryCode: (phoneCode.includes("+") ? "" : "+") + phoneCode,
        phone: phoneNo,
        image: profileImage,
        address: values.address,
      };
      if (id) {
        const res = await editUser({ body, userId: id }).unwrap();
        if (res?.statusCode === 200) {
          navigate("/manageusers", { replace: true });
          successToast("User Updated Successfully");
        }
      }
    } catch (error: any) {
      errorToast(error?.data?.message);
    }
  };

  const getUserDetails = async () => {
    try {
      const res = await getUser(id).unwrap();

      if (res?.statusCode === 200) {
        form.setFieldsValue({
          email: res?.data?.email,
          phone: res?.data?.phone,
          firstName: res?.data?.firstName,
          lastName: res?.data?.lastName,
          userName: res?.data?.userName,
          address: res?.data?.address,
          // tokens: parseInt(res?.data?.tokens),
        });

        setphoneNo(res?.data?.phone || "");
        setPhoneCode(res?.data?.countryCode || "");
        setProfileImage(res?.data?.image || "");
        setValues({
          ...values,
          firstName: res?.data?.firstName || "",
          lastName: res?.data?.lastName || "",
          userName: res?.data?.userName || "",
          email: res?.data?.email || "",
          address: res?.data?.address || "",
        });
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUserDetails();
  }, []);

  const handleImageUpload = async (file: RcFile) => {
    if (file?.type.includes("image")) {
      try {
        const res = await UploadMedia(file);

        if (res?.statusCode === 200) {
          setProfileImage(res?.data);
        }
      } catch (error: any) {
        console.log(error);
      }
    } else {
      errorToast("Only images are allowed");
    }

    return false; // Prevent Ant Design from automatically uploading the file
  };

  return (
    <div style={{ padding: 50 }}>
      <ContentHeader
        buttonText="Back"
        buttonNavigateTo="manageUsers"
        heading="Edit User"
      />
      <Card loading={getUserData.isLoading}>
        <div>
          <Form
            name="basic"
            layout="vertical"
            form={form}
            // labelCol={{ span: 8 }}
            // wrapperCol={{ span: 16 }}
            style={{
              maxWidth: "80%",
              marginTop: 10,
            }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            //   onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Row gutter={16}>
              <Col xs={24} md={12}>
                <Form.Item name="image">
                  <Upload
                    beforeUpload={handleImageUpload}
                    showUploadList={false}
                    className="upload_imgD"
                    accept="image/*"
                  >
                    <div>
                      <h4>Upload Profile Image</h4>
                      <Button icon={<UploadOutlined />}>Click to upload</Button>
                    </div>
                    {profileImage ? (
                      // <img
                      //   style={{
                      //     height: 110,
                      //     width: 110,
                      //     borderRadius: "50%",
                      //     marginTop: 20,
                      //   }}
                      //   src={profileImage}
                      //   alt="profile"
                      // />
                      <LazyLoadImage
                        alt="profile"
                        style={{
                          height: 110,
                          width: 110,
                          borderRadius: "50%",
                          marginTop: 20,
                        }}
                        src={profileImage || ""}
                        effect="blur"
                        // scrollPosition={ScrollPosition}
                      />
                    ) : (
                      <></>
                    )}
                  </Upload>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} md={12}>
                <Form.Item
                  name="firstName"
                  rules={[
                    {
                      required: true,
                      message: "Please input your first name!",
                      whitespace: true,
                    },
                    {
                      min: 3,
                      message: "Name must be at least 3 characters",
                    },
                  ]}
                >
                  <div>
                    <h4>First Name</h4>
                    <Input
                      value={values.firstName}
                      onChange={(e) =>
                        setValues({ ...values, firstName: e.target.value })
                      }
                      placeholder="First Name"
                      style={{ height: 55 }}
                      maxLength={40}
                    />
                  </div>
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item name="lastName">
                  <div>
                    <h4>Last Name</h4>
                    <Input
                      value={values.lastName}
                      onChange={(e) =>
                        setValues({ ...values, lastName: e.target.value })
                      }
                      placeholder="Last Name"
                      style={{ height: 55 }}
                      maxLength={25}
                    />
                  </div>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} md={12}>
                <Form.Item name="userName">
                  <div>
                    <h4>Username</h4>
                    <Input
                      disabled
                      value={values.userName}
                      onChange={(e) =>
                        setValues({ ...values, userName: e.target.value })
                      }
                      style={{ height: 55, color: "black", opacity: 1 }}
                      placeholder="User Name"
                      maxLength={50}
                    />
                  </div>
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Please input email!",
                    },
                    {
                      type: "email",
                      message: "The input is not valid email!",
                    },
                  ]}
                >
                  <div>
                    <h4>Email</h4>
                    <Input
                      value={values.email}
                      onChange={(e) =>
                        setValues({ ...values, email: e.target.value })
                      }
                      style={{ height: 55 }}
                      placeholder="Email"
                      maxLength={40}
                    />
                  </div>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col xs={24} md={12}>
                <Form.Item
                  name="phone"
                  rules={[
                    {
                      required: true,
                      message: "Please input your phone number!",
                    },
                  ]}
                >
                  <div>
                    <h4>Phone Number</h4>
                    <div className="phn_cs">
                      <PhoneInput
                        value={phoneCode + phoneNo}
                        enableSearch={true}
                        placeholder="Phone number"
                        containerClass={"cntn_telCls"}
                        inputClass={"cstm_cls"}
                        buttonClass={"custm_btn"}
                        onChange={(phone, country) =>
                          handleChangePhone(phone, country)
                        }
                      />
                    </div>
                  </div>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}></Row>

            <Form.Item wrapperCol={{}}>
              <div style={{ marginTop: 20 }}>
                <Button
                  style={{
                    background: "#ECAC35",
                    width: 150,
                    height: 50,
                    margin: "auto",
                    color: "black",
                    fontWeight: 500,
                  }}
                  type="primary"
                  htmlType="submit"
                  loading={editUserData.isLoading}
                >
                  Submit
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      </Card>
    </div>
  );
};

export default UsersForm;
