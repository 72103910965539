import { Button, Card, Col, Row, Spin, Tabs, TabsProps } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import {
  AllPredictions,
  MatchDetailsTable,
  RankingTable,
} from "../../../components/tables";
import { useEffect, useState } from "react";
import {
  useLazyGetAllPredictionsQuery,
  useLazyGetRankingDetailsByIdQuery,
  useLazyGetTournamentDetailsByIdQuery,
} from "../../../services/tournaments";
import moment from "moment";

const TournamentDetails = () => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalCount, setTotalCount] = useState<number | undefined>();
  const [selectedCategory, setSelectedCategory] = useState<string>();
  const [predictionCategory, setPredictionCategory] = useState<string>();
  const [query, setQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [totalPredictionCount, setTotalPredictionCount] = useState<
    number | undefined
  >();
  const [currentPredictionPage, setCurrentPredictionPage] = useState<number>(1);

  const navigate = useNavigate();
  const { id } = useParams();
  const [getDetails, getDetailsData] = useLazyGetTournamentDetailsByIdQuery();
  const [getRankings, getRankingsData] = useLazyGetRankingDetailsByIdQuery();
  const [getPredictions, getPredictionsData] = useLazyGetAllPredictionsQuery();

  const [tournamentDetails, setTournamentDetails] =
    useState<TournamentDetailsTypes>();
  const [tournamentRankings, setTournamentRankings] =
    useState<TournamentRankingsTypes>();
  const [activeTab, setActiveTab] = useState("1");
  const [predictions, setPredictions] = useState<any[] | undefined>();

  const onChange = (key: string) => {
    setActiveTab(key);
  };

  const getTournamentDetails = async (page: number) => {
    try {
      let body = {
        tournamentId: id || "",
        size: 10,
        page: page,
        categoryId: selectedCategory || "",
        query: query || "",
      };
      setLoading(true);
      const res = await getDetails(body).unwrap();
      setLoading(false);

      if (res?.statusCode === 200) {
        setTournamentDetails(res?.data);
      }
    } catch (error: any) {
      setLoading(false);

      console.log(error);
    }
  };

  const getTournamentRanking = async (page: number) => {
    try {
      let body = {
        tournamentId: id || "",
        size: 10,
        page: page,
        categoryId: selectedCategory || "",
        query: query || "",
      };
      setLoading(true);
      const res = await getRankings(body).unwrap();
      setLoading(false);

      if (res?.statusCode === 200) {
        setTournamentRankings(res?.data?.data);
        setTotalCount(res?.data?.count);
      }
    } catch (error: any) {
      setLoading(false);

      console.log(error);
    }
  };

  const getPredictionDetails = async (page: number) => {
    try {
      let body = {
        tournamentId: id || "",
        categoryId: predictionCategory || "",
        page: page,
        size: 10,
      };
      setLoading(true);
      const res = await getPredictions(body).unwrap();
      setLoading(false);

      if (res?.statusCode === 200) {
        setPredictions(res?.data?.data);
        setTotalPredictionCount(res?.data?.count);
      }
    } catch (error: any) {
      setLoading(false);

      console.log(error);
    }
  };

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: `Ranking Details`,
      children: (
        <RankingTable
          tournamentId={id || ""}
          loading={getRankingsData?.isLoading}
          setQuery={setQuery}
          tableData={tournamentDetails}
          rankingData={tournamentRankings}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          totalCount={totalCount}
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
          getTournamentRanking={getTournamentRanking}
        />
      ),
    },
    {
      key: "2",
      label: `Tournament Fixtures`,
      children: <MatchDetailsTable tableData={tournamentDetails?.fixtures} />,
    },
    {
      key: "3",
      label: `Predictions`,
      children: (
        <AllPredictions
          tableData={tournamentDetails}
          predictionCategory={predictionCategory}
          setPredictionCategory={setPredictionCategory}
          getPredictionDetails={getPredictionDetails}
          setCurrentPage={setCurrentPredictionPage}
          currentPage={currentPredictionPage}
          totalCount={totalPredictionCount}
          predictions={predictions}
          loading={getPredictionsData?.isLoading}
          tournamentId={id || ""}
        />
      ),
    },
  ];

  // const handlePageChange = (page: number) => {
  //   setCurrentPage(page);
  //   getUserDetails(page);

  // };

  // const pagination = {
  //   pageSize: 10,
  //   current: currentPage,
  //   total: totalCount,
  //   onChange: handlePageChange,
  // };

  let smallestDate: number | Date | null = null;

  tournamentDetails?.fixtures.forEach((obj: any) => {
    const currentDate = new Date(obj.fixture.date);

    if (!smallestDate || currentDate < smallestDate) {
      smallestDate = currentDate;
    }
  });

  useEffect(() => {
    getTournamentDetails(1);
  }, []);

  useEffect(() => {
    getTournamentRanking(1);
  }, [query, selectedCategory]);

  useEffect(() => {
    getPredictionDetails(1);
  }, [predictionCategory]);

  return (
    <Spin spinning={loading}>
      <div style={{ padding: 50, gap: 20, display: "grid" }}>
        <Card>
          <div
            style={{
              alignItems: "center",
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            <h2>Tournament details</h2>
            <Button
              style={{
                background: "#ECAC35",
                width: 100,
                height: 40,
                color: "black",
              }}
              type="primary"
              // htmlType="submit"
              onClick={() => {
                navigate("/managetournaments", { replace: true });
              }}
            >
              Back
            </Button>
          </div>

          <div>
            <Row gutter={16}>
              <Col xs={24} md={12}>
                <h4>Tournament image</h4>
                <div>
                  <img
                    style={{
                      height: 100,
                      width: 100,
                      borderRadius: "50%",
                      marginTop: 10,
                    }}
                    src={
                      tournamentDetails?.image || "/static/images/dummyTP.png"
                    }
                    alt=""
                  />
                </div>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col xs={20} md={8}>
                <h4>Tournament Name</h4>
                <h5>{tournamentDetails?.name}</h5>
              </Col>
              <Col xs={20} md={8}>
                <h4>Date</h4>
                <h5>
                  {smallestDate
                    ? moment(smallestDate).format("YYYY-MM-DD")
                    : "-"}
                </h5>
              </Col>
              <Col xs={20} md={8}>
                <h4>Total Fixtures</h4>
                <h5>{tournamentDetails?.fixtures.length}</h5>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col xs={20} md={8}>
                <h4>Commission</h4>
                <h5>{tournamentDetails?.commission}%</h5>
              </Col>
              <Col xs={20} md={8}>
                <h4>{`Cut-off Time (In minutes)`}</h4>
                <h5>{tournamentDetails?.cutOffTime}</h5>
              </Col>
            </Row>
          </div>
          {/* <div style={{ marginTop: 20 }}>
          <Button
            icon={<ShareAltOutlined />}
            style={{
              background: "#ECAC35",
              width: 140,
              height: 35,
              color: "black",
            }}
            type="primary"
          >
            Invite Friends
          </Button>
        </div> */}
        </Card>

        <div>
          <Tabs
            activeKey={activeTab}
            defaultActiveKey="1"
            items={items}
            onChange={onChange}
          />
        </div>
      </div>
    </Spin>
  );
};

export default TournamentDetails;
