import { Card, Col, Input, Row, Button, Space, Form } from "antd";

import { DownloadOutlined, SearchOutlined } from "@ant-design/icons";
import Table, { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { useLazyGetTournamentsReportsQuery } from "../../services/tournaments";

type PropTypes = {
  id: string | undefined;
};

const UsersReport = ({ id }: PropTypes) => {
  const [query, setQuery] = useState("");
  const [reportData, setReportData] = useState([]);
  const [getReportsList, getReportsListData] =
    useLazyGetTournamentsReportsQuery();
  interface DataType {
    tournament_id: any;
    key: string;
    points: string;
    score: string;
    nunberOfTokens: string;
    winners: string;
    rank: string;
    name: string;
    username: string;
  }

  const getTournamentReportsList = async (page: number) => {
    try {
      const res = await getReportsList({
        userId: id || "",
        page: page,
        size: 10,
        query: query || "",
      }).unwrap();

      if (res?.statusCode === 200) {
        setReportData(res?.data?.data);
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (query === "") {
      getTournamentReportsList(1);
    }
  }, [query]);

  const columns: ColumnsType<DataType> = [
    {
      title: "User Name",
      dataIndex: "userName",
      key: "userName",
      align: "center",
    },

    {
      title: "Tournament Name",
      dataIndex: "username",
      key: "username",
      align: "center",
      render: (_, record) => (
        <div>
          <span>{`${record?.tournament_id?.name || "N/A"}`}</span>
        </div>
      ),
    },

    {
      title: "Earned Tokens",
      dataIndex: "tokens",
      key: "tokens",
      align: "center",
    },

    {
      title: "Points",
      dataIndex: "points",
      key: "points",
      align: "center",
    },
  ];

  return (
    <div style={{}}>
      <Card>
        <div className="srch">
          <Row
            style={{ justifyContent: "space-between", padding: "15px 0px" }}
            gutter={16}
          >
            <Col xs={24} md={7}>
              <Form
                style={{ display: "flex", gap: 20 }}
                onFinish={() => getTournamentReportsList(1)}
              >
                <Input
                  placeholder="Search"
                  prefix={<SearchOutlined style={{ fontSize: "24px" }} />}
                  style={{ height: 50, borderRadius: 10 }}
                  onChange={(e) => setQuery(e.target.value)}
                />
                <Button
                  style={{
                    background: "#ECAC35",
                    width: 90,
                    height: 43,
                    margin: "auto",
                    color: "black",
                  }}
                  type="default"
                  htmlType="submit"
                >
                  Search
                </Button>
              </Form>
            </Col>
            <Col
              style={{
                margin: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              {/* <Button
                icon={<DownloadOutlined />}
                style={{
                  background: "#ECAC35",
                  height: 35,
                  color: "black",
                }}
                type="primary"
              >
                Export CSV
              </Button> */}
            </Col>
          </Row>

          <Table
            columns={columns}
            dataSource={reportData}
            loading={getReportsListData.isLoading}
            rowKey="_id"
          />
        </div>
      </Card>
    </div>
  );
};

export default UsersReport;
