import { Button, Card, Col, Form, Input, Row, Spin } from "antd";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { ContentHeader } from "../../components";
import useAuth from "../../hooks/useAuth";
import Upload, { RcFile } from "antd/es/upload";
import { UploadMedia } from "../../utils/mediaUpload";
import { UploadOutlined } from "@ant-design/icons";
import { useAppDispatch } from "../../hooks/store";
import { setCredentials } from "../../reducers/authSlice";
import {
  useLazyGetProfileQuery,
  usePutUpdateProfileMutation,
} from "../../services/auth";
import { STORAGE_KEYS, errorToast, getData, successToast } from "../../helpers";

const Profile = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const userData = useAuth();
  const dispatch = useAppDispatch();
  const [firstName, setFirstName] = useState("");
  const [updateProfile, updateProfileData] = usePutUpdateProfileMutation();
  const [getProfile, getProfileData] = useLazyGetProfileQuery();
  const [profileImage, setProfileImage] = useState("");
  const [countryName, setCountryName] = useState("in");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [userName, setUserName] = useState("");
  const [address, setAddress] = useState("");
  const [phoneCode, setPhoneCode] = useState("+91");
  const [phoneNo, setphoneNo] = useState("");
  const onFinish = async (values: any) => {
    let body = {
      username: userName,
      name: name,
      email: email,
      address: address,
      image: profileImage,
      countryCode: phoneCode,
      phone: phoneNo,
      latitude: 0,
      longitude: 0,
    };

    try {
      const res = await updateProfile(body).unwrap();
      if (res?.statusCode === 200) {
        const getRes = await getProfile({}).unwrap();
        if (getRes?.statusCode === 200) {
          const token = getData(STORAGE_KEYS.token);
          successToast("Profile updated successfully");
          dispatch(
            setCredentials({
              user: getRes?.data || null,
              token: token ? JSON.parse(`${token}`) : null,
            })
          );
        }

        navigate("/dashboard", { replace: true });
      }
    } catch (error: any) {
      errorToast(error?.data?.message || error?.data?.error);
    }
  };

  const handleChangePhone = (phone: any, country: any) => {
    setPhoneCode(country?.dialCode);
    setphoneNo(phone?.replace(country.dialCode, ""));
    setCountryName(country?.countryCode);
  };

  useEffect(() => {
    if (userData) {
      form.setFieldsValue({
        email: userData?.email,
        name: userData?.name,
        address: userData?.address,
        phone: userData?.phone,
        username: userData?.username,
      });
      setName(userData?.name || "");
      setphoneNo(userData?.phone || "");
      setPhoneCode(userData?.countryCode || "+91");
      setEmail(userData?.email || "");
      setAddress(userData?.address || "");
      setProfileImage(userData?.image || "");
      setUserName(userData?.username || "");
    }
  }, [userData, form]);

  const handleImageUpload = async (file: RcFile) => {
    if (file?.type.includes("image")) {
      try {
        const res = await UploadMedia(file);

        if (res?.statusCode === 200) {
          setProfileImage(res?.data);
        }
      } catch (error: any) {
        console.log(error);
      }
    } else {
      errorToast("Only images are allowed");
    }

    return false; // Prevent Ant Design from automatically uploading the file
  };

  const handleNameChange = (e: any) => {
    const limit = 15;

    setName(e.target.value.slice(0, limit));
  };

  const handleUsernameChange = (e: any) => {
    setUserName(e.target.value.slice(0, 15));
  };

  return (
    <div style={{ padding: 40 }}>
      <ContentHeader heading="User Profile" />
      <Card>
        <div>
          <Form
            name="basic"
            layout="vertical"
            form={form}
            // labelCol={{ span: 8 }}
            // wrapperCol={{ span: 16 }}
            style={{
              maxWidth: "80%",
              marginTop: 10,
            }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            //   onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Row gutter={16}>
              <Col xs={24} md={12}>
                <Form.Item name="image">
                  <Upload
                    beforeUpload={handleImageUpload}
                    showUploadList={false}
                    className="upload_imgD"
                    accept="image/*"
                  >
                    <div>
                      <h4>Upload Profile Image</h4>
                      <Button icon={<UploadOutlined />}>Click to upload</Button>
                    </div>
                    {profileImage ? (
                      <img
                        style={{
                          height: 110,
                          width: 110,
                          borderRadius: "50%",
                          marginTop: 20,
                        }}
                        src={profileImage}
                        alt="profile"
                      />
                    ) : (
                      <></>
                    )}
                  </Upload>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} md={12}>
                <Form.Item
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "Please input your name!",
                      whitespace: true,
                    },
                    {
                      min: 2,
                      message: "Name must be at least 2 characters long",
                    },
                  ]}
                >
                  <div>
                    <h4>Full Name</h4>
                    <Input
                      value={name}
                      placeholder="Full Name"
                      style={{ height: 55 }}
                      onChange={(e) => {
                        setName(e.target.value);
                        handleNameChange(e);
                      }}
                    />
                  </div>
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  name="username"
                  rules={[
                    {
                      required: true,
                      message: "Please input your User Name!",
                    },
                    {
                      min: 3,
                      message: "User Name must be at least 3 characters",
                    },
                  ]}
                >
                  <div>
                    <h4>User Name</h4>
                    <Input
                      value={userName}
                      placeholder="User Name"
                      style={{ height: 55 }}
                      onChange={(e) => {
                        setUserName(e.target.value);
                        handleUsernameChange(e);
                      }}
                    />
                  </div>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} md={12}>
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Please input your E-mail!",
                    },
                    {
                      type: "email",
                      message: "The input is not valid E-mail!",
                    },
                  ]}
                >
                  <div>
                    <h4>Email</h4>
                    <Input
                      value={email}
                      style={{ height: 55 }}
                      placeholder="E-mail"
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      disabled
                    />
                  </div>
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  name="phone"
                  rules={[
                    {
                      required: true,
                      message: "Please input your phone number!",
                    },
                  ]}
                >
                  <div>
                    <h4>Phone Number</h4>
                    <div className="phn_cs">
                      <PhoneInput
                        value={phoneCode + phoneNo}
                        enableSearch={true}
                        placeholder="Phone number"
                        containerClass={"cntn_telCls"}
                        inputClass={"cstm_cls"}
                        buttonClass={"custm_btn"}
                        onChange={(phone, country) =>
                          handleChangePhone(phone, country)
                        }
                      />
                    </div>
                  </div>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col xs={24} md={12}>
                <Form.Item
                  name="address"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Address!",
                      whitespace: true,
                    },
                    {
                      min: 3,
                      message: "Address must be at least 3 characters",
                    },
                  ]}
                >
                  <div>
                    <h4>Address</h4>
                    <Input
                      value={address}
                      placeholder="Address"
                      style={{ height: 55 }}
                      onChange={(e) => {
                        setAddress(e.target.value);
                      }}
                    />
                  </div>
                </Form.Item>
              </Col>
            </Row>

            <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
              <div style={{ display: "flex" }}>
                <Button
                  style={{
                    background: "#ECAC35",
                    width: 200,
                    height: 50,
                    margin: "auto",
                    color: "black",
                  }}
                  type="primary"
                  htmlType="submit"
                  // onClick={() => {
                  //   navigate("/manageUsers", { replace: true });
                  // }}
                >
                  Save
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      </Card>
    </div>
  );
};

export default Profile;
