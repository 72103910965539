import { Card } from "antd";
import Table, { ColumnsType } from "antd/es/table";

const TokensInPlayTable = (tableData: any) => {
  interface DataType {
    key: string;
    index: number;
    totalUsers: string;
    name: string;
    endDate: string;
    totalTokens: string;
  }

  const columns: ColumnsType<DataType> = [
    // {
    //   title: "#",
    //   key: "rank",
    //   dataIndex: "rank",
    //   align: "center",
    //   width: "10%",
    //   render: (text, record, index) => index + 1,
    // },
    // {
    //   title: "Image",
    //   dataIndex: "image",
    //   key: "image",
    //   align: "center",
    //   render: (_, { image }: any) => (
    //     <div>
    //       <img
    //         style={{ height: 70, width: 70, borderRadius: 100 }}
    //         src={image || "/static/images/dummyTP.png"}
    //         alt=""
    //       />
    //     </div>
    //   ),
    // },

    {
      title: "Tournament Name",
      dataIndex: "name",
      key: "name",
      align: "center",
      width: "35%",

      render: (_, record) => (
        <div>
          <span>{record?.name}</span>
        </div>
      ),
    },
    {
      title: "Total Users/Players",
      dataIndex: "totalUsers",
      key: "totalUsers",
      align: "center",
      width: "30%",

      render: (_, record: any) => {
        return (
          <div>
            <span>{record?.totalUsers}</span>
          </div>
        );
      },
    },

    {
      title: "Total Tokens",
      dataIndex: "totalTokens",
      key: "totalTokens",
      align: "center",
      width: "35%",

      render: (_, record) => (
        <div>
          <span>{record?.totalTokens || "-"}</span>
        </div>
      ),
    },
  ];

  return (
    <div style={{}}>
      <Card>
        <div style={{ marginTop: 10 }}>
          <Table
            columns={columns}
            dataSource={tableData?.tableData}
            rowKey="_id"
          />
        </div>
      </Card>
    </div>
  );
};

export default TokensInPlayTable;
