import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  TimePicker,
} from "antd";
import { useNavigate } from "react-router-dom";
import "react-phone-input-2/lib/style.css";
import UploadImage from "../../../components/UploadImage&ShowModal";
import TextArea from "antd/es/input/TextArea";

const BannerForm = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();

  // const [profileImage, setProfileImage] = useState(null);

  const onFinish = (values: any) => {
    navigate("/manageUsers", { replace: true });
  };

  // const dateOnChange: DatePickerProps["onChange"] = (date, dateString) => {
  //   console.log(date, dateString);
  // };
  const url = window.location.pathname;
  const path = url.includes("edit");

  const handleChange = (value: any) => {
    form.setFieldsValue({
      CompetitionName: value,
      time: value,
    });
    form.validateFields(["CompetitionName"]);
  };

  const handleTimeChange = (value: any) => {
    form.setFieldsValue({
      time: value,
    });
    form.validateFields(["time"]);
  };

  const handleDateChange = (value: any) => {
    form.setFieldsValue({
      date: value,
    });
    form.validateFields(["date"]);
  };

  return (
    <div style={{ padding: 50 }}>
      <Card>
        <div
          style={{
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <h2>{path ? "Edit " : "Add "} Banner</h2>
          <Button
            style={{
              background: "#ECAC35",
              width: 100,
              height: 40,
              color: "black",
            }}
            type="primary"
            // htmlType="submit"
            onClick={() => {
              navigate("/managebanners", { replace: true });
            }}
          >
            Back
          </Button>
        </div>

        <div>
          <Form
            name="basic"
            layout="vertical"
            form={form}
            // labelCol={{ span: 8 }}
            // wrapperCol={{ span: 16 }}
            style={{
              maxWidth: "80%",
              marginTop: 50,
            }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            //   onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Row gutter={16}>
              <Col xs={24} md={12}>
                <UploadImage image="" />
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} md={12}>
                <Form.Item
                  name="title"
                  rules={[
                    {
                      required: true,
                      message: "Please input title of banner!",
                      whitespace: true,
                    },
                    {
                      min: 5,
                      message: "Title must be at least 5 characters",
                    },
                  ]}
                >
                  <div>
                    <h4>Title</h4>
                    <Input placeholder="Title" style={{ height: 55 }} />
                  </div>
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  // label="Username"
                  name="CompetitionName"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Competition Name!",
                    },
                  ]}
                >
                  <div>
                    <h4>Competition Name</h4>
                    <Select
                      // defaultValue={type}
                      style={{ height: 75, width: "100%" }}
                      onChange={handleChange}
                      placeholder="Competition Name"
                      options={[
                        { value: "Competition 1", label: "Competition 1" },
                        { value: "Competition 2", label: "Competition 2" },
                        { value: "Competition 3", label: "Competition 3" },
                        { value: "Competition 4", label: "Competition 4" },
                      ]}
                    />
                  </div>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} md={12}>
                <Form.Item
                  name="date"
                  rules={[
                    {
                      required: true,
                      message: "Please input match date!",
                    },
                  ]}
                >
                  <div>
                    <h4>Date</h4>

                    <DatePicker
                      style={{ width: "100%", height: 55 }}
                      onChange={handleDateChange}
                    />
                  </div>
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  name="time"
                  rules={[
                    {
                      required: true,
                      message: "Please input match timing!",
                    },
                  ]}
                >
                  <div>
                    <h4>Select Time</h4>
                    <div className="">
                      <TimePicker
                        style={{ width: "100%", height: 55 }}
                        format="HH:mm"
                        onChange={handleTimeChange}
                      />
                    </div>
                  </div>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col xs={24} md={12}>
                <Form.Item
                  // label="Username"
                  name="matchVenue"
                  rules={[
                    {
                      required: true,
                      message: "Please input match venue!",
                    },
                  ]}
                >
                  <div>
                    <h4>Match Venue</h4>
                    <Input placeholder="Match Venue" style={{ height: 55 }} />
                  </div>
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  name="bettingDetails"
                  rules={[
                    {
                      required: true,
                      message: "Please input Betting details!",
                      whitespace: true,
                    },
                    {
                      min: 3,
                      message: "Betting details must be at least 20 characters",
                    },
                  ]}
                >
                  <div>
                    <h4>Betting Details</h4>
                    <Input
                      placeholder="Betting Details"
                      style={{ height: 55 }}
                    />
                  </div>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col xs={24} md={12}>
                <Form.Item
                  name="location"
                  rules={[
                    {
                      required: true,
                      message: "Please input Prediction Details!",
                      whitespace: true,
                    },
                    {
                      min: 20,
                      message: "Location must be at least 20 characters",
                    },
                  ]}
                >
                  <div>
                    <h4>Prediction Details</h4>
                    <Input
                      placeholder="Prediction Details"
                      style={{ height: 55 }}
                    />
                  </div>
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  // label="Username"
                  name="winningDetails"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Winning details!",
                    },
                    {
                      min: 20,
                      message: "Winning details must be at least 20 characters",
                    },
                  ]}
                >
                  <div>
                    <h4>Winning details</h4>
                    <Input
                      placeholder="Winning details"
                      style={{ height: 55 }}
                    />
                  </div>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} md={12}>
                <Form.Item
                  name="CompetitionDescription"
                  rules={[
                    {
                      required: true,
                      message: "Please input Competition description!",
                      whitespace: true,
                    },
                    {
                      min: 20,
                      message: "Description must be at least 20 characters",
                    },
                  ]}
                >
                  <div>
                    <h4>Competition Description</h4>
                    <TextArea rows={8} placeholder="Description" />
                  </div>
                </Form.Item>
              </Col>
            </Row>

            <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
              <div style={{ display: "flex" }}>
                <Button
                  style={{
                    background: "#ECAC35",
                    width: 200,
                    height: 50,
                    margin: "auto",
                    color: "black",
                  }}
                  type="primary"
                  htmlType="submit"
                  // onClick={() => {
                  //   navigate("/manageUsers", { replace: true });
                  // }}
                >
                  Save
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      </Card>
    </div>
  );
};

export default BannerForm;
