import { Button, Card, Col, Row, Modal, Space, Checkbox } from "antd";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Table, { ColumnsType } from "antd/es/table";

import { useEffect, useState } from "react";
import moment from "moment";

type PropTypes = {
  data: any[] | undefined;
};

const SelectedMatchesTable = ({ data }: PropTypes) => {
  interface DataType {
    fixture: any;
    teams: any;
    key: string;
    teamA: string;
    teamB: string;
    date: string;
    time: string;
  }

  const columns: ColumnsType<DataType> = [
    {
      title: "#",
      key: "rowNumber",
      align: "center",
      width: "20%",
      render: (text, record, index) => index + 1,
    },

    {
      title: "Team A",
      dataIndex: "id",
      key: "id",
      align: "center",
      render: (_, record) => (
        <div>
          <span>{`${record?.teams?.away?.name || "N/A"}`}</span>
        </div>
      ),
    },
    {
      title: "Team B",
      dataIndex: "teamB",
      key: "teamB",
      align: "center",
      render: (_, record) => (
        <div>
          <span>{`${record?.teams?.home?.name || "N/A"}`}</span>
        </div>
      ),
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      align: "center",
      render: (_, record) => (
        <div>
          <span>{`${
            moment(record?.fixture?.date).format("YYYY-MM-DD") || "N/A"
          }`}</span>
        </div>
      ),
    },
    {
      title: "Time",
      dataIndex: "time",
      key: "time",
      align: "center",
      render: (_, record) => (
        <div>
          <span>{`${
            moment(record?.fixture?.date).format("HH:mm") || "-"
          }`}</span>
        </div>
      ),
    },
  ];

  return (
    <div style={{ padding: 0 }}>
      <div>
        <Table
          columns={columns}
          dataSource={data}
          rowKey="_id"
          className="tableSelector"
        />
      </div>
    </div>
  );
};

export default SelectedMatchesTable;
