import { Card, Col, Input, Row, Button, Space, Modal, Switch } from "antd";
import { ContentHeader } from "../../components";
import { DeleteOutlined, EditFilled, SearchOutlined } from "@ant-design/icons";
import Table, { ColumnsType } from "antd/es/table";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  useDeleteBannersMutation,
  useLazyGetBannersQuery,
  usePutBlockBannerCategoryMutation,
} from "../../services/banners";
import { errorToast, successToast } from "../../helpers";
import Form from "antd/es/form/Form";
import useAuth from "../../hooks/useAuth";
import moment from "moment";

const SeasonalCompetition = () => {
  const userData = useAuth();

  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState<number>(1);

  const [deleteBanner, deleteBannerData] = useDeleteBannersMutation();
  const [totalCount, setTotalCount] = useState<number>(0);
  const [blockCategory, blockCategoryData] =
    usePutBlockBannerCategoryMutation();
  const [query, setQuery] = useState("");
  const [bannerListState, setBannerListState] = useState([]);
  const [getBannersList, getBannersListData] = useLazyGetBannersQuery();
  const [hidePermission, setHidePermission] = useState<any>({
    isAdd: true,
    isDelete: true,
  });
  interface DataType {
    _id: any;
    key: string;
    matchDate: string;
    tokenFee: string;
    winners: string;
    title: string;
    name: any;
  }
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    getCompetitionList(page);
  };

  const { confirm } = Modal;

  const handleDelete = async (id: string) => {
    try {
      const res = await deleteBanner({ bannerId: id }).unwrap();

      if (res?.statusCode === 200) {
        getCompetitionList(1);

        successToast("Banner Deleted Successfully");
      }
    } catch (error: any) {
      console.log(error);
      errorToast(error?.data?.message);
    }
  };

  const showDeleteConfirm = (record: any) => {
    confirm({
      title: "Hold on",
      content: "Are you sure delete this Banner?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        handleDelete(record?._id);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };
  const handleBlock = async (isActive: boolean, _id: string) => {
    let body = {
      isActive: isActive,
    };
    try {
      const res = await blockCategory({ body, id: _id }).unwrap();
      if (res?.statusCode === 200) {
        getCompetitionList(1);
        successToast(
          isActive
            ? "Banner Activated Successfully!"
            : "Banner Deactivated Successfully!"
        );
      }
    } catch (error: any) {
      console.log(error);
      errorToast(error?.data?.message);
    }
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      align: "center",
      render: (_, { image }: any) => (
        <div>
          <img
            style={{ height: 70, width: 150, borderRadius: 10 }}
            src={image || "/static/images/dummyTP.png"}
            alt=""
          />
        </div>
      ),
    },
    {
      title: "Title",
      key: "title",
      dataIndex: "title",
      align: "center",
      width: "10%",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      align: "center",
      render: (_, { type }: any) => (
        <div>
          <span>{`${
            type
              .split("_")
              .map(
                (word: string) => word.charAt(0) + word.slice(1).toLowerCase()
              )
              .join(" ") || "N/A"
          }`}</span>
        </div>
      ),
    },

    {
      title: "Valid To",
      // dataIndex: "matchDate",
      key: "matchDate",
      align: "center",
      render: (record) => (
        <div>
          {record.startDate ? (
            <span>
              {`${moment(
                `${record?.startDate}T${record?.startTime}:00.037Z`
              ).format("YYYY-MM-DD")} to ${moment(
                `${record?.endDate}T${record?.endTime}:00.037Z`
              ).format("YYYY-MM-DD")}` || "-"}
            </span>
          ) : (
            "-"
          )}
        </div>
      ),
    },
    {
      title: "Token Fee",
      dataIndex: "tokenFee",
      key: "tokenFee",
      align: "center",
      render: (_, { tokenFee }: any) => (
        <div>
          <span>{`${tokenFee || "-"}`}</span>
        </div>
      ),
    },

    {
      title: "Action",
      key: "action",
      align: "center",
      render: (_, record) => (
        <Space size="middle">
          {hidePermission?.isAdd ? (
            <Button
              onClick={(e) => {
                e.preventDefault();
                navigate(`/seasonalcompetitionform/${record?._id}`);
              }}
            >
              <EditFilled style={{ color: "#C69320" }} />
            </Button>
          ) : null}
          {hidePermission?.isDelete ? (
            <Button
              onClick={(e) => {
                e.preventDefault();
                showDeleteConfirm(record);
              }}
            >
              <DeleteOutlined style={{ color: "#d4380d" }} />
            </Button>
          ) : null}
        </Space>
      ),
    },
    {
      title: " Status",
      dataIndex: "status",
      key: "status",
      align: "center",
      render: (_, { isActive, _id }: any) => (
        <Switch
          size="small"
          defaultChecked={isActive}
          onChange={() => handleBlock(!isActive, _id)}
        />
      ),
    },
  ];

  const getCompetitionList = async (page: number) => {
    try {
      const res = await getBannersList({
        page: page,
        size: 10,
        query: query || "",
      }).unwrap();

      if (res?.statusCode === 200) {
        setTotalCount(res?.data?.count);
        setBannerListState(res?.data?.data);
      }
    } catch (error: any) {
      console.log(error);
    }
  };
  useEffect(() => {
    getCompetitionList(1);
  }, [query]);

  const pagination = {
    pageSize: 10,
    current: currentPage,
    total: totalCount,
    onChange: handlePageChange,
  };

  const checkPermission = () => {
    const permissions = userData?.permissions;
    if (permissions?.length) {
      let idx = -1;
      idx = permissions?.findIndex((ele: any) => ele?.label === "Manage Users");
      if (idx != -1) {
        if (!permissions[idx]?.isAdd) {
          let data = {
            isAdd: false,
            isDelete: hidePermission?.isDelete,
          };
          setHidePermission(data);
        }
        if (!permissions[idx]?.isDelete) {
          let data = {
            isAdd: hidePermission?.isAdd,
            isDelete: false,
          };
          setHidePermission(data);
        }
      }
    }
  };

  useEffect(() => {
    if (userData && userData?.permissions?.length) {
      checkPermission();
    }
  }, [userData]);

  return (
    <div style={{ padding: 40 }}>
      <ContentHeader
        heading="Manage Seasonal Competition"
        buttonNavigateTo="seasonalcompetitionform/add"
        buttonText="Add"
      />
      <Card>
        <div className="srch">
          <Row style={{ paddingTop: 10, paddingBottom: 10 }} gutter={16}>
            <Col xs={24} md={7}>
              <Form
                style={{ display: "flex", gap: 20 }}
                onFinish={() => getCompetitionList(1)}
              >
                <Input
                  placeholder="Search"
                  prefix={<SearchOutlined style={{ fontSize: "24px" }} />}
                  style={{ height: 50, borderRadius: 10 }}
                  onChange={(e) => setQuery(e.target.value)}
                  maxLength={20}
                />
                <Button
                  style={{
                    background: "#ECAC35",
                    width: 90,
                    height: 43,
                    margin: "auto",
                    color: "black",
                  }}
                  type="default"
                  htmlType="submit"
                >
                  Search
                </Button>
              </Form>
            </Col>
          </Row>

          <Table
            columns={columns}
            dataSource={bannerListState}
            rowKey="_id"
            loading={
              getBannersListData.isLoading ||
              deleteBannerData.isLoading ||
              blockCategoryData.isLoading
            }
            pagination={pagination}
          />
        </div>
      </Card>
    </div>
  );
};

export default SeasonalCompetition;
