import { Dispatch, SetStateAction, useEffect } from "react";
import { Dropdown, Layout, MenuProps, Modal, Spin } from "antd";
import {
  BellOutlined,
  CaretDownOutlined,
  UserOutlined,
  LogoutOutlined,
  KeyOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { setCredentials } from "../reducers/authSlice";
import { STORAGE_KEYS, getData } from "../helpers";
import { useAppDispatch } from "../hooks/store";
import { removeItem } from "../helpers/constants/storage";
import { useLazyGetProfileQuery } from "../services/auth";
import useAuth from "../hooks/useAuth";

const HeaderBar = ({
  collapsed,
  setCollapsed,
}: {
  collapsed: boolean;
  setCollapsed: Dispatch<SetStateAction<boolean>>;
}) => {
  const [getProfile, getProfileData] = useLazyGetProfileQuery();
  // const { language, changeLanguage } = useLanguageContext();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const userData = useAuth();

  const { confirm } = Modal;

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: <span>Profile</span>,
      icon: <UserOutlined />,
      onClick: () => navigate("/profile"),
    },
    {
      key: "2",
      label: <span>Change Password</span>,
      icon: <KeyOutlined />,
      onClick: () => navigate("/changepassword"),
    },
    {
      key: "3",
      label: <span>Logout</span>,
      icon: <LogoutOutlined />,
      onClick: () => {
        confirmLogout();
      },
    },
  ];

  const confirmLogout = () => {
    confirm({
      title: "Alert",
      content: "Are you sure you want to Logout?",
      okText: "Yes",
      okType: "primary",
      cancelText: "No",
      centered: true,
      onOk() {
        console.log("OK");
        setTimeout(() => {
          dispatch(
            setCredentials({
              user: null,
              token: null,
            })
          );
          removeItem(STORAGE_KEYS.token);
          navigate("/", { replace: true });
        }, 500);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const getProfileDetails = async (token: string) => {
    const res = await getProfile({}).unwrap();
    dispatch(
      setCredentials({
        user: res?.data || null,
        token: token || null,
      })
    );
  };

  useEffect(() => {
    const token = getData(STORAGE_KEYS.token);
    const userData = getData(STORAGE_KEYS.userData);

    if (token) {
      dispatch(
        setCredentials({
          token: JSON.parse(`${token}`),
          user: JSON.parse(`${userData}`),
        })
      );
    }
    getProfileDetails(JSON.parse(`${token}`));
  }, []);

  const { Header } = Layout;

  return (
    <Header style={{ padding: 0, height: 45 }}>
      <Spin spinning={getProfileData.isLoading}>
        <div
          style={{
            background: "white",
            display: "flex",
            justifyContent: "space-between",
            height: "75px",
            padding: "10px 50px",
          }}
        >
          <div
            style={{ cursor: "pointer" }}
            onClick={() => setCollapsed(!collapsed)}
          ></div>

          <div
            style={{
              display: "flex",
              // alignItems: "center",
              // width: 350,
              justifyContent: "flex-end",
              gap: "5%",
              cursor: "pointer",
            }}
          >
            {/* <ChangeLanguageDropdown /> */}
            {/* <div onClick={() => navigate("/notifications")}>
              <BellOutlined style={{ fontSize: 25 }} />
            </div> */}

            <Dropdown menu={{ items }}>
              <div
                style={{
                  display: "flex",
                  cursor: "pointer",
                }}
              >
                <img
                  style={{
                    height: "40px",
                    margin: "auto",
                    marginRight: "14px",
                    width: "40px",
                    borderRadius: "100%",
                  }}
                  src={userData?.image || "/static/images/dummy.png"}
                  // src={"/static/images/dummy.png"}
                  alt=""
                />
                <div
                  onClick={(e) => e.preventDefault()}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <div>
                    <div style={{ height: 20 }}>
                      {userData?.name || "User Data"}
                      <CaretDownOutlined style={{ paddingLeft: "7px" }} />
                    </div>
                    <div>{userData?.role?.toLowerCase() || "Admin"}</div>
                  </div>
                </div>
              </div>
            </Dropdown>
          </div>
        </div>
      </Spin>
    </Header>
  );
};

export default HeaderBar;
