import {
  Col,
  Input,
  Row,
  Button,
  Space,
  Modal,
  Switch,
  Form,
  Card,
} from "antd";
import {
  DeleteOutlined,
  EditFilled,
  EyeOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import Table, { ColumnsType } from "antd/es/table";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  useDeleteCategoryMutation,
  useLazyGetAllTournamentsQuery,
  usePutBlockCategoryMutation,
} from "../../services/tournaments";
import { errorToast, successToast } from "../../helpers";
import { ContentHeader } from "../../components";
import useAuth from "../../hooks/useAuth";
import moment from "moment";

const ManageTournaments = () => {
  const [deleteCategory, deleteCategoryData] = useDeleteCategoryMutation();
  const [inActiveCategory, inActiveCategoryData] =
    usePutBlockCategoryMutation();

  const [query, setQuery] = useState("");
  const [getTournamentList, getTournamentData] =
    useLazyGetAllTournamentsQuery();
  const [totalCount, setTotalCount] = useState<number>(0);

  const [tournamentData, setTournamentsData] = useState<any[]>();
  const [currentPage, setCurrentPage] = useState<number>(1);

  const navigate = useNavigate();
  const [hidePermission, setHidePermission] = useState<any>({
    isAdd: true,
    isDelete: true,
  });

  const userData = useAuth();
  interface DataType {
    _id: any;
    key: string;
    date: string;
    title: string;
    name: string;
    status: boolean;
    fixtures: any[];
    isFixed: boolean;
  }

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    getTournamentDetailsList(page);
  };

  const checkPermission = () => {
    const permissions = userData?.permissions;
    if (permissions?.length) {
      let idx = -1;
      idx = permissions?.findIndex(
        (ele: any) => ele?.label === "Manage Tournaments"
      );
      if (idx != -1) {
        if (!permissions[idx]?.isAdd) {
          let data = {
            isAdd: false,
            isDelete: hidePermission?.isDelete,
          };
          setHidePermission(data);
        }
        if (!permissions[idx]?.isDelete) {
          let data = {
            isAdd: hidePermission?.isAdd,
            isDelete: false,
          };
          setHidePermission(data);
        }
      }
    }
  };

  useEffect(() => {
    if (userData && userData?.permissions?.length) {
      checkPermission();
    }
  }, [userData]);

  const { confirm } = Modal;

  const handleDeactivated = async (isActive: boolean, _id: string) => {
    let body = {
      isActive: isActive,
    };
    try {
      const res = await inActiveCategory({ body, tournamentId: _id }).unwrap();
      if (res?.statusCode === 200) {
        successToast(
          isActive
            ? "Tournament Activated Successfully!"
            : "Tournament Deactivated Successfully!"
        );
      }
    } catch (error: any) {
      console.log(error);
      errorToast(error?.data?.message);
    }
  };

  const handleDelete = async (id: string) => {
    try {
      const res = await deleteCategory({ categoryId: id }).unwrap();

      if (res?.statusCode === 200) {
        successToast("Tournament Deleted Successfully");
        getTournamentDetailsList(1);
      }
    } catch (error: any) {
      console.log(error);
      errorToast(error?.data?.message);
    }
  };

  const showDeleteConfirm = (record: any) => {
    confirm({
      title: "Hold on",
      content: "Are you sure delete this tournament ?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        handleDelete(record?._id);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      width: "10%",
      align: "center",
      render: (_, { image }: any) => (
        <div>
          <img
            style={{ height: 70, width: 70, borderRadius: 100 }}
            src={image || "/static/images/dummyTP.png"}
            alt=""
          />
        </div>
      ),
    },
    {
      title: "Tournament Name",
      dataIndex: "name",
      key: "name",
      align: "center",
    },

    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      align: "center",
      render: (_, record) => {
        const smallestDate: Date | null = record?.fixtures.reduce(
          (smallest, obj) => {
            const currentDate = new Date(obj.fixture.date);
            return !smallest || currentDate < smallest ? currentDate : smallest;
          },
          null
        );

        if (smallestDate) {
          const localDate = moment(smallestDate).format("YYYY-MM-DD");
          return localDate;
        } else {
          return "-";
        }
      },
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      render: (_, record) => (
        <Space size="middle">
          <Button
            onClick={(e) => {
              e.preventDefault();
              navigate(`/managetournaments/tournamentdetails/${record?._id}`);
            }}
          >
            <EyeOutlined style={{ color: "#52c41a" }} />
          </Button>
          {hidePermission?.isAdd ? (
            <Button
              onClick={(e) => {
                e.preventDefault();
                navigate(`/managetournamentscategories/${record?._id}`);
              }}
            >
              <EditFilled style={{ color: "#C69320" }} />
            </Button>
          ) : null}
          {hidePermission?.isDelete || record?.isFixed ? (
            <Button
              onClick={(e) => {
                e.preventDefault();
                showDeleteConfirm(record);
              }}
            >
              <DeleteOutlined style={{ color: "#d4380d" }} />
            </Button>
          ) : null}
        </Space>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      align: "center",
      render: (_, { isActive, _id }: any) => (
        <Switch
          size="small"
          defaultChecked={isActive}
          onChange={() => handleDeactivated(!isActive, _id)}
        />
      ),
    },
  ];

  const getTournamentDetailsList = async (page: number) => {
    try {
      const res = await getTournamentList({
        page: page,
        size: 10,
        query: query || "",
      }).unwrap();

      if (res?.statusCode === 200) {
        setTournamentsData(res?.data?.data);
        setTotalCount(res?.data?.count);
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  const pagination = {
    pageSize: 10,
    current: currentPage,
    total: totalCount,
    onChange: handlePageChange,
  };

  useEffect(() => {
    if (query === "") {
      getTournamentDetailsList(1);
    }
  }, [query]);
  return (
    <div style={{ padding: 40 }}>
      <ContentHeader
        heading="Manage Tournaments"
        buttonNavigateTo="managetournamentscategories/add"
        buttonText={hidePermission?.isAdd ? "Add Tournament" : ""}
      />
      <Card>
        <div style={{}}>
          <div className="srch">
            <Row style={{ paddingTop: 10, paddingBottom: 10 }} gutter={16}>
              <Col xs={24} md={7}>
                <Form
                  style={{ display: "flex", gap: 20 }}
                  onFinish={() => getTournamentDetailsList(1)}
                >
                  <Input
                    placeholder="Search Tournament"
                    prefix={<SearchOutlined style={{ fontSize: "24px" }} />}
                    style={{ height: 45, borderRadius: 10 }}
                    onChange={(e) => setQuery(e.target.value)}
                  />
                  <Button
                    style={{
                      background: "#ECAC35",
                      width: 90,
                      height: 40,
                      margin: "auto",
                      color: "black",
                    }}
                    type="default"
                    htmlType="submit"
                  >
                    Search
                  </Button>
                </Form>
              </Col>
            </Row>
            <Table
              pagination={pagination}
              columns={columns}
              dataSource={tournamentData || []}
              rowKey="_id"
              loading={
                getTournamentData.isLoading ||
                deleteCategoryData.isLoading ||
                inActiveCategoryData.isLoading
              }
            />
          </div>
        </div>
      </Card>
    </div>
  );
};

export default ManageTournaments;
