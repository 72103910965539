import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

interface Fixture {
  name: string;
  description: string;
  fixtures: {
    fixture_id: string;
    fixture: {
      id: string;
      referee: string;
      timezone: string;
      date: string;
      timestamp: string;
      periods: {
        first: string;
        second: string;
      };
      venue: {
        id: string;
        name: string;
        city: string;
      };
      status: {
        long: string;
        short: string;
        elapsed: string;
      };
    };
    league: {
      id: string;
      name: string;
      country: string;
      logo: string;
      flag: string;
      season: string;
      round: string;
    };
    teams: {
      home: {
        id: string;
        name: string;
        logo: string;
        winner: boolean;
      };
      away: {
        id: string;
        name: string;
        logo: string;
        winner: boolean;
      };
    };
    goals: {
      home: string;
      away: string;
    };
    score: {
      halftime: {
        home: string;
        away: string;
      };
      fulltime: {
        home: string;
        away: string;
      };
      extratime: {
        home: string;
        away: string;
      };
      penalty: {
        home: string;
        away: string;
      };
    };
  };
}

interface SelectionState {
  details: {
    image: string;
    name: string;
    description: string;
    rules: string;
    tokenCategories: any[];
    fixtures: any[] | undefined;
    cutoffTime: number;
    commission: number;
  };
}

const initialState: SelectionState = {
  details: {
    name: "",
    image: "",
    rules: "",
    description: "",
    commission: 0,
    cutoffTime: 0,
    tokenCategories: [],
    fixtures: [],
  },
};

const matchDataSlice = createSlice({
  name: "selection",
  initialState,
  reducers: {
    updateSelection: (state, action: PayloadAction<SelectionState>) => {
      state.details = action.payload.details;
      state.details.fixtures = action.payload.details.fixtures;
    },
  },
});

export const { updateSelection } = matchDataSlice.actions;
export const getMatchData = (state: RootState) => state.matchData.details;

export default matchDataSlice.reducer;
