import { Button, Card, Col, Form, Input, Row, Spin } from "antd";
import { useEffect, useState } from "react";
import { ContentHeader } from "../../components";
import EditText from "../../components/textEditor";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {
  useLazyGetCmsQuery,
  usePutUpdateCmsMutation,
} from "../../services/auth";
import { errorToast, successToast } from "../../helpers";

const ManageCms = () => {
  const [cmsDetails, cmsDetailsData] = useLazyGetCmsQuery();
  const [updateCms, updateCmsData] = usePutUpdateCmsMutation();
  const [values, setValues] = useState({
    hundredX: "",
    thousandX: "",
  });

  const [cmsId, setCmsId] = useState("");
  const [email, setEmail] = useState("");
  const [aboutUs, setAboutUs] = useState("");
  const [privacyPolicy, setPrivacyPolicy] = useState<string | null>(null);
  const [terms, setTerms] = useState("");
  const [cancellation, setCancellation] = useState("");

  const [phoneCode, setPhoneCode] = useState("+91");
  const [phoneNo, setphoneNo] = useState("");

  const handleChangePhone = (phone: any, country: any) => {
    setPhoneCode(country?.dialCode);
    setphoneNo(phone?.replace(country.dialCode, ""));
  };

  const onFinish = async () => {
    let body = {
      aboutUs: aboutUs,
      cancellation: cancellation,
      email: email,
      phone: phoneNo,
      privacy: privacyPolicy || "",
      terms: terms,
      countryCode: (phoneCode.includes("+") ? "" : "+") + phoneCode,
      thousandXPoints: values?.thousandX,
      hunderedXPoints: values?.hundredX,
    };
    try {
      const res = await updateCms({ body, cms_id: cmsId }).unwrap();

      if (res?.statusCode === 200) {
        successToast("CMS Updated Successfully");
      }
    } catch (error: any) {
      console.log(error, "error");
      errorToast(error?.message);
    }
  };

  const getCmsDetail = async () => {
    try {
      const res = await cmsDetails({}).unwrap();

      if (res?.statusCode === 200) {
        setCmsId(res?.data?._id);
        setAboutUs(res?.data?.aboutUs || "");
        setCancellation(res?.data?.cancellation || "");
        setPrivacyPolicy(res?.data?.privacy || "");
        setTerms(res?.data?.terms || "");
        setEmail(res?.data?.email);
        setphoneNo(res?.data?.phone);
        setValues({
          ...values,
          hundredX: res?.data?.hunderedXPoints || "",

          thousandX: res?.data?.thousandXPoints || "",
        });

        setPhoneCode(res?.data?.countryCode);
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCmsDetail();
  }, []);

  return (
    <div style={{ padding: 40 }}>
      <ContentHeader heading="Manage CMS" />
      <div style={{ marginBottom: 18 }}>
        <span style={{ fontSize: 17, fontWeight: 600 }}>
          Content Management System
        </span>
      </div>

      <Card>
        <Spin spinning={cmsDetailsData.isLoading}>
          <Row gutter={16}>
            <Col xs={24} md={12}>
              <h3>Privacy Policy</h3>
              {privacyPolicy ? (
                <EditText
                  content={privacyPolicy}
                  setContent={setPrivacyPolicy}
                />
              ) : null}
            </Col>
            <Col xs={24} md={12}>
              <h3>Terms and Conditions</h3>
              {terms ? (
                <EditText content={terms} setContent={setTerms} />
              ) : null}
            </Col>
          </Row>

          <Row gutter={16}>
            <Col xs={24} md={12}>
              <h3>About us</h3>
              {aboutUs ? (
                <EditText content={aboutUs} setContent={setAboutUs} />
              ) : null}
            </Col>
          </Row>

          <h3>Contact Us</h3>
          <Form
            onFinish={onFinish}
            name="basic"
            style={{
              maxWidth: "80%",
            }}
            initialValues={{ remember: true }}
            autoComplete="off"
          >
            <Row gutter={16}>
              <Col xs={24} md={12}>
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Please input your E-mail!",
                    },
                    {
                      type: "email",
                      message: "Enter a valid email address!",
                    },
                  ]}
                >
                  <div>
                    <h4>Email</h4>
                    <Input
                      style={{ height: 55 }}
                      placeholder="E-mail"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                    />
                  </div>
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <div>
                  <h4>Phone Number</h4>
                  <div className="phn_cs">
                    <PhoneInput
                      value={phoneCode + phoneNo}
                      enableSearch={true}
                      placeholder="Phone number"
                      containerClass={"cntn_telCls"}
                      inputClass={"cstm_cls"}
                      buttonClass={"custm_btn"}
                      onChange={(phone, country) =>
                        handleChangePhone(phone, country)
                      }
                    />
                  </div>
                </div>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} md={12}>
                <Form.Item
                  name="hundred"
                  rules={[
                    {
                      pattern: /^[0-9]*$/,
                      message: "Please enter only numeric values",
                    },
                  ]}
                >
                  <div>
                    <h4>100x Points</h4>
                    <Input
                      value={values.hundredX}
                      onChange={(e) =>
                        setValues({ ...values, hundredX: e.target.value })
                      }
                      type="tel"
                      placeholder="100x Points"
                      style={{ height: 55 }}
                      maxLength={5}
                    />
                  </div>
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  name="thousand"
                  rules={[
                    {
                      pattern: /^[0-9]*$/,
                      message: "Please enter only numeric values",
                    },
                  ]}
                >
                  <div>
                    <h4>1000x Points</h4>
                    <Input
                      value={values.thousandX}
                      onChange={(e) =>
                        setValues({ ...values, thousandX: e.target.value })
                      }
                      type="tel"
                      placeholder="1000x Points"
                      style={{ height: 55 }}
                      maxLength={5}
                    />
                  </div>
                </Form.Item>
              </Col>
            </Row>
          </Form>

          <Row gutter={4}>
            <Col xs={16} md={20}>
              <Button
                style={{
                  background: "#ECAC35",
                  width: 200,
                  height: 50,
                  margin: "auto",
                  marginTop: 20,
                  color: "black",
                }}
                type="primary"
                onClick={onFinish}
                loading={updateCmsData.isLoading}
              >
                Save
              </Button>
            </Col>
          </Row>
        </Spin>
      </Card>
    </div>
  );
};

export default ManageCms;
