import { Route, Routes } from "react-router-dom";
import {
  Dashboard,
  ManageBanners,
  ManageUsers,
  Notifications,
  Settings,
  ManageCms,
  ManageFaq,
  ManageSubAdmins,
  Profile,
  SubAdminDetails,
  SubAdminForm,
  UsersForm,
  UserDetails,
  ManageTournaments,
  Analytics,
  ManageLeaderboard,
  CustomerSupport,
  Reports,
  ManageNews,
  ManageTokensCategories,
  LeaderBoardDetails,
  BannerForm,
  AddTokenCategories,
  AddTournamentCategory,
  TournamentDetails,
  SeasonalCompetition,
  SeasonalCompetitionForm,
  ChangePassword,
  LeagueDetails,
  AddTokens,
  AddTournamentDetails,
  AddTeams,
  ManageTeams,
  AllReports,
  ManageReceivers,
  PredictionDetails,
  PredictionDetailsForTournament,
} from "../pages";
import { ForgetPassword, Login, ResetPassword } from "../pages/auth";
import MainLayout from "../layout";
import NotificationsHistory from "../pages/notifications/details";

const RoutesNavigation = () => {
  return (
    <Routes>
      <Route path="/" element={<Login />}></Route>
      <Route path="/forgotpassword" element={<ForgetPassword />}></Route>
      <Route path="/resetpassword/:id" element={<ResetPassword />}></Route>
      <Route path="/changepassword" element={<ChangePassword />}></Route>

      <Route
        path="/dashboard"
        element={
          <MainLayout>
            <Dashboard />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/manageusers"
        element={
          <MainLayout>
            <ManageUsers />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/addtokens"
        element={
          <MainLayout>
            <AddTokens />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/manageusers/edit/:id"
        element={
          <MainLayout>
            <UsersForm />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/manageusers/userdetail/:id/:view"
        element={
          <MainLayout>
            <UserDetails />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/managesubadmins"
        element={
          <MainLayout>
            <ManageSubAdmins />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/profile"
        element={
          <MainLayout>
            <Profile />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/managecms"
        element={
          <MainLayout>
            <ManageCms />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/settings"
        element={
          <MainLayout>
            <Settings />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/notifications"
        element={
          <MainLayout>
            <Notifications />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/notifications/history"
        element={
          <MainLayout>
            <NotificationsHistory />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/managebanners"
        element={
          <MainLayout>
            <ManageBanners />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/managefaq"
        element={
          <MainLayout>
            <ManageFaq />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/managetournaments"
        element={
          <MainLayout>
            <ManageTournaments />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/analytics"
        element={
          <MainLayout>
            <Analytics />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/manageleaderboard"
        element={
          <MainLayout>
            <ManageLeaderboard />
          </MainLayout>
        }
      ></Route>
      <Route
        path={`/managesubadmins/:id`}
        element={
          <MainLayout>
            <SubAdminForm />
          </MainLayout>
        }
      ></Route>
      <Route
        path={`/managesubadmins/add`}
        element={
          <MainLayout>
            <SubAdminForm />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/managesubadmins/subadmindetail/:id"
        element={
          <MainLayout>
            <SubAdminDetails />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/customersupport"
        element={
          <MainLayout>
            <CustomerSupport />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/reports"
        element={
          <MainLayout>
            <Reports />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/manageleaderboard/leaderboarddetails/:id"
        element={
          <MainLayout>
            <LeaderBoardDetails />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/managetokenscategories"
        element={
          <MainLayout>
            <ManageTokensCategories />
          </MainLayout>
        }
      ></Route>
      <Route
        path={`/banners/:view`}
        element={
          <MainLayout>
            <BannerForm />
          </MainLayout>
        }
      ></Route>
      <Route
        path={`/managetokenscategories/:view`}
        element={
          <MainLayout>
            <AddTokenCategories />
          </MainLayout>
        }
      ></Route>
      <Route
        path={`/managetournamentscategories/:view`}
        element={
          <MainLayout>
            <AddTournamentCategory />
          </MainLayout>
        }
      ></Route>
      <Route
        path={`/managetournamentscategories/addTournamentDetails/:id/:view`}
        element={
          <MainLayout>
            <AddTournamentDetails />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/managetournaments/tournamentdetails/:id"
        element={
          <MainLayout>
            <TournamentDetails />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/managetournaments/leaguedetails/:id/:year"
        element={
          <MainLayout>
            <LeagueDetails />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/seasonalcompetition"
        element={
          <MainLayout>
            <SeasonalCompetition />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/seasonalcompetitionform/:view"
        element={
          <MainLayout>
            <SeasonalCompetitionForm />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/favoriteteams"
        element={
          <MainLayout>
            <ManageTeams />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/favoriteteams/:view"
        element={
          <MainLayout>
            <AddTeams />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/reports/:id"
        element={
          <MainLayout>
            <AllReports />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/addtokens/managereceivers"
        element={
          <MainLayout>
            <ManageReceivers />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/userdetail/viewprediction/:id/:view"
        element={
          <MainLayout>
            <PredictionDetails />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/managetournaments/tournamentdetails/:id/:view/:username"
        element={
          <MainLayout>
            <PredictionDetailsForTournament />
          </MainLayout>
        }
      ></Route>
    </Routes>
  );
};

export default RoutesNavigation;
