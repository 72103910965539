import { toast } from "react-toastify";

export function successToast(key: string) {
  toast.dismiss();
  toast.success(key);
}
export function errorToast(key: string) {
  toast.dismiss();
  toast.error(key);
}
export function warnToast(key: string) {
  toast.dismiss();
  toast.warn(key);
}
