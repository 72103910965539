import { useState } from "react";
import { Card, Tabs, TabsProps } from "antd";
import { ContentHeader } from "../../components";

const CustomerSupport = () => {
  const [activeTab, setActiveTab] = useState("1");

  const onChange = (key: string) => {
    setActiveTab(key);
  };

  return (
    <div style={{ padding: 40 }}>
      <ContentHeader heading="Customer Support" />
      <Card></Card>
    </div>
  );
};

export default CustomerSupport;
