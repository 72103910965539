import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Modal, Row, Space, Table } from "antd";
import { useEffect, useState } from "react";
import type { ColumnsType } from "antd/es/table";
import {
  useDeleteRoleMutation,
  usePostAddRoleMutation,
  useLazyGetRoleQuery,
  usePostUpdateRoleMutation,
} from "../../services/subAdmin";
import { errorToast, successToast } from "../../helpers";
import { isString } from "../../utils/validations";

interface DataType {
  _id: string;
  name: string;
  // roleEnabled: boolean;
}

const { confirm } = Modal;

const AddRole = () => {
  const [form] = Form.useForm();

  const [role, setRole] = useState("");
  const [editRole, setEditRole] = useState<any>();
  const [roleData, setRoleData] = useState<DataType[]>([]);
  const [totalCount, setTotalCount] = useState<number>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);

  const [postAddRoleMethod] = usePostAddRoleMutation();
  const [postGetRoleMethod] = useLazyGetRoleQuery();
  const [deleteRoleMethod] = useDeleteRoleMutation();
  const [updateRoleMethod] = usePostUpdateRoleMutation();

  const handleDelete = async (id: string) => {
    try {
      setLoading(true);
      const res = await deleteRoleMethod({ role_id: id }).unwrap();
      setLoading(false);
      if (res?.statusCode === 200) {
        successToast("Role deleted successfully");
        getRoleData(currentPage);
      }
    } catch (error: any) {
      setLoading(false);
      console.log(error);
      errorToast(error?.data?.message);
    }
  };

  const showDeleteConfirm = (record: any) => {
    confirm({
      title: "Hold on",
      content: "Are you sure you want to this delete this role?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        handleDelete(record?._id);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "Role",
      dataIndex: "name",
      key: "name",
      align: "center",
      width: "30%",
      render: (name: string) => (
        <div>
          <span>{name}</span>
        </div>
      ),
    },

    {
      title: "Action",
      key: "action",
      align: "center",
      render: (record) => (
        <Space size="middle">
          <Button
            onClick={(e) => {
              e.preventDefault();
              setEditRole(record);
              setRole(record.name);
            }}
          >
            <EditOutlined color="#08c" />
          </Button>
          <Button
            onClick={(e) => {
              e.preventDefault();
              showDeleteConfirm(record);
            }}
          >
            <DeleteOutlined style={{ color: "#d4380d" }} />
          </Button>
        </Space>
      ),
    },
  ];

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    getRoleData(page);
  };

  const pagination = {
    pageSize: 10,
    current: currentPage,
    total: totalCount,
    onChange: handlePageChange,
  };

  const getRoleData = async (page: any) => {
    try {
      setLoading(true);
      let body = {
        page: page,
        limit: 10,
      };
      const res = await postGetRoleMethod(body).unwrap();

      setLoading(false);
      if (res?.statusCode === 200) {
        setRoleData(res?.data?.data);
        setTotalCount(res?.data?.totalAccessRole);
      }
    } catch (error: any) {
      setLoading(false);
      console.log(error);
    }
  };

  const onFinish = async (value: string) => {
    try {
      setLoading(true);
      if (value === "Add") {
        let body = {
          name: role,
        };
        const res = await postAddRoleMethod(body).unwrap();
        setLoading(false);
        if (res?.statusCode === 200) {
          successToast(res?.message || "");
          getRoleData(1);
          setRole("");
        }
      } else {
        let body = {
          name: role,
          role_id: editRole?._id,
        };
        const res = await updateRoleMethod({
          body,
          role_id: editRole?._id,
        }).unwrap();
        setLoading(false);
        if (res?.statusCode === 200) {
          successToast(res?.message || "");
          getRoleData(1);
          setEditRole(null);
          setRole("");
        }
      }
    } catch (error: any) {
      setLoading(false);
      errorToast(error?.data?.message);
    }
  };

  useEffect(() => {
    getRoleData(1);
  }, []);

  return (
    <div>
      <Form
        name="basic"
        form={form}
        initialValues={{ remember: true }}
        autoComplete="off"
      >
        <Row gutter={16}>
          <Col xs={24} md={16}>
            <Form.Item
              name="addRole"
              rules={[
                {
                  required: true,
                  message: "Please input sub-admin role!",
                  whitespace: true,
                },
                {
                  min: 3,
                  message: "Role name must be at least 3 characters",
                },
              ]}
            >
              <div>
                <h4>{editRole ? "Edit Role" : "Add Role"}</h4>
                <Input
                  value={role}
                  onChange={(e) => {
                    if (e.target.value === " " || e.target.value === ".") {
                    } else if (isString(e.target.value)) {
                      setRole(e.target.value);
                    }
                  }}
                  placeholder="Add Role"
                  style={{ height: 55 }}
                  maxLength={25}
                />
              </div>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col xs={24} md={12}>
            <Form.Item>
              {editRole ? (
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Button
                    style={{
                      background: "#ECAC35",
                      width: 200,
                      height: 50,
                      margin: "auto",
                      color: "black",
                    }}
                    type="primary"
                    onClick={() => onFinish("Edit")}
                  >
                    Edit
                  </Button>
                  <Button
                    style={{
                      background: "#ECAC35",
                      width: 200,
                      height: 50,
                      margin: "auto",
                      color: "black",
                    }}
                    type="primary"
                    onClick={() => {
                      setRole("");
                      setEditRole(null);
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              ) : (
                <Button
                  style={{
                    background: "#ECAC35",
                    width: 200,
                    height: 50,
                    margin: "auto",
                    color: "black",
                  }}
                  type="primary"
                  disabled={role === ""}
                  onClick={() => onFinish("Add")}
                >
                  Add
                </Button>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row
          style={{
            maxWidth: "80%",
          }}
          gutter={12}
        >
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Table
              style={{ marginTop: 20 }}
              columns={columns}
              dataSource={roleData}
              rowKey={"_id"}
              pagination={pagination}
              loading={loading}
            />
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default AddRole;
